import React, {Component} from "react";
import {
  Box,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Notification,
  notificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";

class AccountConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    await this.exitFullAccount();
    await this.activationAccount();
  }

  exitFullAccount = async () => {
    await this.props.setUserProfile(null);
  }
  activationAccount = async () => {
    if (this.props.user) {
      return
    }

    const search = (this.props.history?.location?.search || "").replace(/\?/g, '');
    let parse = {};
    search.split("&").map((t) => {
      return parse[t.split("=")?.[0]] = t.split("=")?.[1];
    });

    if (!parse.code) {
      Notification({
        type: notificationTypes.error,
        message: "Верификационный код не найден"
      });
      this.props.history.replace("/");
      return
    }

    const resVerification = await agent.get(`/accounts/email/verification?code=${ parse.code }`).then((res) => {
      return true
    }).catch((err) => {
      return false
    });
    if (!resVerification) {
      Notification({
        message: "Верификационный код не найден",
        type: notificationTypes.error
      });
      this.props.history.replace("/");
      return
    }

    agent.defaults.headers['Authorization'] = '';
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    Notification({
      message: "Верификация успешно пройдена, авторизуйтесь"
    });
    this.props.history.replace("/authorization")
  }

  render () {
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Box className={classes.box}>
          <Box className={classes.icon}>
            <img src={require("../../../assets/image/logo/logo-text-shadow.svg").default}/>
          </Box>
          <Typography variant="h1" className={classes.text}>
            Подтверждение аккаунта
          </Typography>
        </Box>
      </Box>
    );
  }
}

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "420px",
    margin: "auto",
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: "32px"
  },
  icon: {
    position: "relative",
    width: 124,
    height: 124,
    marginBottom: 20,

    "& img": {
      position: "absolute",
      top: "0", left: "50%",
      transform: "translateX(-50%)",
      width: 160
    }
  }
};
AccountConfirm = withStyles(styles)(AccountConfirm);

export default AccountConfirm
