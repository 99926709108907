import React, {useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import s from './banner.module.css'
import {Container} from "@mui/material";
import bannerImg from '../../../../../assets/image/welcome/slide1.png';
import bannerImgMobile from '../../../../../assets/image/welcome/slide1mobile.jpg';
import arrowRight from '../../../../../assets/image/welcome/arrow-right.svg';
import arrowLeft from '../../../../../assets/image/welcome/arrow-left.svg';

const Banner = () => {
    const [swiper, setSwiper] = useState(null);

    const onChangeSlide = (type) => {
        if(type === 'next') {
            swiper.slideNext();
        }else {
            swiper.slidePrev();
        }
    }
    return (
        <div className={s.banner}>
           <Container maxWidth='xl'>
               <div className={s.wrapper}>
                   <div className={s.bannerSwiperPrev} onClick={() => onChangeSlide('prev')}>
                       <img src={arrowLeft} alt="left"/>
                   </div>
                   <div className={s.bannerSwiperNext} onClick={() => onChangeSlide('next')}>
                       <img src={arrowRight} alt="right"/>
                   </div>
                   <Swiper
                       className={s.bannerSwiper}
                       modules={[Navigation]}
                       spaceBetween={50}
                       slidesPerView={1}
                       navigation
                       onSwiper={(swiper) => setSwiper(swiper)}
                       speed={1000}
                   >

                       <SwiperSlide className={s.bannerSlider}>
                           <img src={bannerImg} alt="banner" className="visible--pc"/>
                           <img src={bannerImgMobile} alt="banner" className="visible--mobile"/>
                       </SwiperSlide>
                   </Swiper>
               </div>
           </Container>
        </div>
    );
};

export default Banner;
