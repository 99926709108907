import React, {Component} from "react";
import {
  Grid,
  Button,
  Typography
} from "@mui/material";
import {
  HistoryContent
} from "./components";
import agent from "../../../../agent/agent";
import {Link} from "react-router-dom";
import {withStyles} from "@mui/styles";
import {DialogWarningEndRate} from "../../../../components";
import {Notification, notificationTypes} from "../../../../common/Notification";
import {Centrifuge} from "centrifuge";

class MidjourneView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
      midjourney: {},
      midjourneyId: props?.match?.params?.id,
      midjourneyLoading: true
    };
    this.socket = null;
    this.timeOutGetMidjourney = null;
    this.refDialogWarningEndRate = React.createRef();
  }

  componentDidMount = async () => {
    await this.getMidjourney();
  }
  componentDidUpdate = async (prevProps) => {
    if (this.props?.match?.params?.id !== prevProps?.match?.params?.id) {
      await this.setState({
        midjourneyId: this.props?.match?.params?.id
      });
      await this.getMidjourney();
    }
  }
  componentWillUnmount = () => {
    clearTimeout(this.timeOutGetMidjourney);
  }

  getMidjourney = async () => {
    const { midjourneyId } = this.state;
    const res = await agent.get(`/history/midjourney/${ midjourneyId }`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });

    const lastStatus = [...(res || [])].pop().status;
    const history = (res || []).map((item, index, list) => {
      const childrens = list.filter((t) => t.parent_id === item.id);
      const imageIndexs = childrens.map((t) => Boolean(t.process === "variation") ? t.image_index : null).filter((t) => !!t);
      const imageUpscale = childrens.map((t) => Boolean(t.process === "upscale") ? t.image_index : null).filter((t) => !!t);

      return {
        ...item,
        imageIndexs,
        imageUpscale
      }
    })

    await this.setState({
      history: history,
      midjourneyLoading: Boolean(lastStatus !== "success")
    });

    if (['on_queue', 'processing'].includes(lastStatus)) {
      await this.startWatchWS();
      return
    }

    await new Promise(r => setTimeout(r, 600));
    const scrollBody = document.getElementById("scrollBody");
    scrollBody.scroll(0, 9999);
  }

  changeUpscale = async (index, elementId) => {
    const { midjourneyId } = this.state;
    const res = await agent.post(`/midjourney/tasks/${ elementId || midjourneyId }/upscale`, {
      image_index: index
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    })
    if (res?.error) {
      this.setState({isBackdrop: false});
      if (res?.error?.data?.code === 4318) {
        this.refDialogWarningEndRate.current.open();
        return
      }
      Notification({
        message: res?.error?.data?.message || "Ошибка сервера",
        type: notificationTypes.error
      })
      return
    }
    await this.getMidjourney();
  }
  changeVariation = async (index, elementId) => {
    const { midjourneyId } = this.state;
    const res = await agent.post(`/midjourney/tasks/${ elementId || midjourneyId }/variation`, {
      image_index: index
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    })
    if (res?.error) {
      this.setState({isBackdrop: false});
      if (res?.error?.data?.code === 4318) {
        this.refDialogWarningEndRate.current.open();
        return
      }
      Notification({
        message: res?.error?.data?.message || "Ошибка сервера",
        type: notificationTypes.error
      })
      return
    }
    await this.getMidjourney();
  }

  startWatchWS = async () => {
    const socket = new Centrifuge([process.env.REACT_APP_HOST_WS, 'ws'].join("/"), {
      token: localStorage.getItem('access_token')
    });

    socket.on('connected', (event) => {console.log('connected event:', event)});
    socket.on('connecting', (event) => {console.log('connecting event:', event)});
    socket.on('message', async (data) => {
      if ((data?.data?.event || '').includes('midjourney:') && Boolean((this.state.history || []).find((t) => t.id === data?.data?.data?.task_id))) {
        await this.getMidjourney();
        await this.closeWatchWS();
      }
    });
    socket.connect();

    this.socket = socket;
  }
  closeWatchWS = async () => {
    this.socket.disconnect();
  }

  render () {
    const {
      history,
      midjourney,
      midjourneyLoading
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <>

        <HistoryContent
          data={midjourney}
          history={history}
          isMidjourneyLoading={midjourneyLoading}
          onChangeUpscale={this.changeUpscale}
          onChangeVariation={this.changeVariation}
        />

        <Grid container mt="20px">
          <Grid item xs={12} md="auto">
            <Button variant="contained" component={Link} to="/midjourney/create" className={classes.buttonNew}>
              Сгенерировать новое изображение
            </Button>
          </Grid>
        </Grid>
        <DialogWarningEndRate ref={this.refDialogWarningEndRate}/>
      </>
    );
  }
}

const styles = {
  buttonNew: {
    "@media(max-width: 859px)": {
      fontSize: 14,
      width: "100%"
    }
  }
};
MidjourneView = withStyles(styles)(MidjourneView);

export default MidjourneView
