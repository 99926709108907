import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Divider,
	Typography,
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
class DialogNews extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false
		}
	}

	componentDidMount = () => {
		this.checkLastNews();
	}
	componentDidUpdate = (prevProps) => {
		if (this.props.news?.id !== prevProps?.news?.id) {
			this.checkLastNews();
		}
	}

	checkLastNews = () => {
		if ((this.props.news || []).length <= 0) {
			return
		}

		const lastViewedNews = localStorage.getItem('last-viewed-news');
		const lastFindIndex = this.props.news.findIndex((t) => t.id === lastViewedNews);
		if (lastFindIndex === 0 && !!lastViewedNews) {
			return;
		}

		this.setState({
			isOpen: true
		});
	}

	close = () => {
		const newsId = this.props.news?.[0]?.id;

		localStorage.setItem('last-viewed-news', newsId);
		this.setState({ isOpen: false });
	}

	render () {
		const {
			isOpen
		} = this.state;
		const {
			news,
			classes
		} = this.props;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={isOpen}
				fullWidth
				classes={{
					root: classes.dialogRoot
				}}
				maxWidth="lg"
			>
				<DialogTitle>
					<Typography variant="h3" textAlign="left">
						Что нового
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						{news.map((item, index) => (
							<>
								{Boolean(index > 0) && <Grid item xs={12}><Divider/></Grid>}
								<Grid item xs={12}>
									<NewsContent item={item}/>
								</Grid>
							</>
						))}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Box py={2} mb={-1}>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Button variant="contained" onClick={this.close}>
									Закрыть
								</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
}
const NewsContent = React.memo((props) => {
	const { item } = props;
	const classes = useStyles();
	return (
		<Box className={classes.newsContent}>
			<Typography
				dangerouslySetInnerHTML={{ __html: item.title }}
				className={classes.newsContentTitle}
			/>
			<Typography
				dangerouslySetInnerHTML={{ __html: item.news }}
				className={classes.newsContentMessage}
			/>
		</Box>
	)
})
const useStyles = makeStyles(() => ({
	newsContent: {},
	newsContentTitle: {
		marginBottom: 15,

		color: "#2E8BFF",
		fontSize: "26px",
		fontWeight: "500",
		lineHeight: "116%",
		letterSpacing: "-0.52px",
	},
	newsContentMessage: {
		color: "#254164",
		fontSize: "18px",
		fontWeight: "400",
		lineHeight: "130%",
		letterSpacing: "-0.42px",

		"& img": {
			maxWidth: "100%",
			borderRadius: 8
		},
		"& i": {
			fontStyle: 'italic'
		},
		"& s": {
			fontWeight: '500'
		},
	},
}));

const styles = {
	dialogRoot: {
		"& .MuiPaper-root": {
			maxWidth: 800
		}
	}
};
DialogNews = withStyles(styles)(DialogNews);

export default DialogNews
