import React from 'react';
import {Container} from "@mui/material";
import s from './registration.module.css';
import {useHistory} from "react-router-dom";

const Registration = () => {
  const history = useHistory();
  const [form, setForm] = React.useState({
    email: "",
    password: "",
  });

  const handleChangeForm = ({ target }) => {
    const { name, value } = target;
    let newForm = {...form};
    newForm[name] = value;
    setForm(newForm)
  }
  const handleSubmitForm = () => {
    let urlSearch = [];
    Object.keys(form).map((formKey) => {
      const formValue = form[formKey];
      if (formValue) {
        urlSearch.push(`${formKey}=${formValue}`);
      }
    });
    history.push(`/registration?${urlSearch.join("&")}`)
  }

    return (
        <div className={s.registrationRoot}>
            <Container maxWidth='xl'>
                <div className={s.registration}>
                    <div className={s.coverWhite}></div>
                    <div className={`${s.registrationCover} ${s.coverGreen}`}></div>
                    <div className={`${s.registrationCover} ${s.coverBlue}`}></div>
                    <div className={`${s.registrationCover} ${s.coverPink}`}></div>
                    <h3>Регистрация за минуту!</h3>
                    <div className={s.formInputs}>
                        <div className={s.inputGroup}>
                            <span>Ваш логин/почта</span>
                            <input value={form.email} name="email" type="text" placeholder='Ваш логин/почта' onChange={handleChangeForm}/>
                        </div>

                        <div className={s.inputGroup}>
                            <span>Придумайте пароль</span>
                            <input value={form.password} name="password" type="text" placeholder='Ваш пароль от 8 символов' onChange={handleChangeForm}/>
                        </div>
                    </div>
                    <button className='welcome-btn' onClick={handleSubmitForm}>Зарегистрироваться</button>
                </div>
            </Container>
        </div>
    );
};

export default Registration;
