import React from "react";
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  IconButton,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  withStyles,
  makeStyles
} from "@mui/styles";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  SmartDisplay as SmartDisplayIcon
} from "@mui/icons-material";
import {
  Swiper,
  SwiperSlide
} from "swiper/react";

const casesList = [
  {
    title: "Нарисовать картину \"Океанский сон\"",
    image: require('../../../../../assets/image/cases/Океанский сон.jpg'),
    message: `<span>Запрос</span>Океанский сон: нарисуйте картину, где подводный мир оживает и рыбы плавают среди облаков и звезд. Пейзаж должен быть спокойным и сонным, с голубыми и фиолетовыми оттенками`,
    video: "https://disk.ugmk-telecom.ru/index.php/s/lYX6d9pa8xnZ4L0/download?path=%2F&files=%D0%9E%D0%BA%D0%B5%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%81%D0%BE%D0%BD.mp4"
  },

  {
    title: "Создать описание товара/продукта",
    image: require('../../../../../assets/image/cases/1.Создать описание продукта.png'),
    message: `<span>Название товара/продукта</span>Футбольные бутсы nike mercurial<br/><br/><span>Ключевые слова</span>Бутсы футбольные для игры на натуральных и искусственных покрытиях. Легкие, удобные. цвет белый`,
    video: ""
  },
  {
    title: "Создать рекламное объявление",
    image: require('../../../../../assets/image/cases/2. Создать текстовое рекламное объявление.png'),
    message: `
        <span>Что будем рекламировать</span>Репетитор по иностранным языкам<br/><br/>
        <span>Особенности и преимущества</span>Английский, немецкий, французский и китайский языки. Опыт работы 5 лет. Диплом о высшем образовании.<br/><br/>
        <span>Целевая аудитория</span>для школьников, их родителей, для студентов и вообще для всех, кто хочет изучать новые языки
    `,
    video: ""
  },
  {
    title: "Написать статью для сайта/блога/журнала",
    image: require('../../../../../assets/image/cases/3. Написать статью.png'),
    message: `
        <span>Заголовок статьи</span>Правила поведения на дороге<br/><br/>
        <span>Описание статьи</span>Обучающая статья, в основном для детей. Цель статьи рассказать основные правила поведения на дороге, основные правила дорожного движения для пешеходов. Привить культуру и взаимоуважение на дороге<br/><br/>
        <span>Если нужно использовать для статьи стиль - укажите его</span>Научный, познавательный
    `,
    video: ""
  },
  {
    title: "Сделать Рерайтинг текста",
    image: require('../../../../../assets/image/cases/4. Рерайтинг текста.png'),
    message: `
      <span>Текст для рерайтинга/перефразирования</span>В современных энергетических системах важность релейной защиты возрастает, особенно в связи с быстрым ростом мощности энергосистем, объединением их в единые электрически связанные системы в пределах нескольких областей, всей страны, и даже нескольких государств. Релейная защита автоматически устраняет повреждения и ненормальные режимы в электрической части энергосистем и является важнейшей автоматикой, которая гарантирует их надёжную и стабильную работу. Рост нагрузок, увеличение протяжённости ЛЭП, усиление требований к устойчивости энергосистем усложняют условия эксплуатации релейной защиты, и повышает требования к ее быстродействию, чувствительности и надёжности. Поэтому идет постоянный процесс разработки и совершенствования технологий релейной защиты, направленной на создание более совершенных защит, которые отвечают требованиям современной энергетики.<br/><br/>
      <span>Цель рерайтинга</span>Сделать текст уникальным, сделать текст более простым
    `,
    video: ""
  },
  {
    title: "Придумать идеи",
    image: require('../../../../../assets/image/cases/5. Придумать идеи.png'),
    message: `
      <span>Для чего нужна идея</span>Для раскрутки футбольного клуба в социальных сетях и в направлении медийности<br/><br/>
      <span>Количество идей</span>3
    `,
    video: ""
  },
  {
    title: "Улучшить текст",
    image: require('../../../../../assets/image/cases/6. Улучшить текст.png'),
    message: `
      <span>Текст для улучшения</span>В современных энергетических системах важность релейной защиты возрастает, особенно в связи с быстрым ростом мощности энергосистем, объединением их в единые электрически связанные системы в пределах нескольких областей, всей страны, и даже нескольких государств.
Релейная защита автоматически устраняет повреждения и ненормальные режимы в электрической части энергосистем и является важнейшей автоматикой, которая гарантирует их надёжную и стабильную работу.
Рост нагрузок, увеличение протяжённости ЛЭП, усиление требований к устойчивости энергосистем усложняют условия эксплуатации релейной защиты, и повышает требования к ее быстродействию, чувствительности и надёжности. Поэтому идет постоянный процесс разработки и совершенствования технологий релейной защиты, направленной на создание более совершенных защит, которые отвечают требованиям современной энергетики.
Что нужно улучшить: повысить уникальность
    `,
    video: ""
  },
  {
    title: "Подготовить вопросы и ответы",
    image: require('../../../../../assets/image/cases/7. Написать вопросы и ответы.png'),
    message: `
      <span>Цель подготовки вопросов</span>Для собеседования специалиста по тестированию<br/><br/>
      <span>Количество вопросов</span>5
    `,
    video: ""
  },

  {
    title: "Разработать варианты дизайна дашборда",
    image: require('../../../../../assets/image/cases/Придумать дизайн дашборда и запрос на его создание в Midjourney.jpg'),
    message: `
      <span>Показатели для отображения</span>Динамика продаж <br/><br/>
      <span>Цветовая гамма лэндинга</span>Синий, белый
    `,
    video: "https://disk.ugmk-telecom.ru/index.php/s/j4bIMTVzklNH23c/download"
  },
  {
    title: "Придумать дизайн слайда для презентации PowerPoint",
    image: require('../../../../../assets/image/cases/Придумать дизайн слайда для презентации PowerPoint.jpg'),
    message: `
      <span>Заголовок слайда</span>Антарктида, тайны и загадки южного полюса<br/><br/>
      <span>Текст для визуализации на слайде 1</span>Антарктида – самый малоизученный, и пожалуй самый загадочный континент на нашей планете. С момента своего открытия, тайны Антарктиды не дают спокойно спать исследователям и ученым различных областей науки. Все что касается этого материка, окутано загадками, от климата до самого факта открытия этого безжизненного континента. И чем больше эта земля исследуется, тем больше тайны Антарктиды завораживают умы ученых.<br/><br/>
      <span>Рекомендуемые цвета</span>Синий, белый
    `,
    video: "https://disk.ugmk-telecom.ru/index.php/s/lYX6d9pa8xnZ4L0/download?path=%2F&files=AI%20Synergy%20-%20Google%20Chrome%202023-10-19%2010-06-35.mp4"
  },
  {
    title: "Придумать дизайн лэндинга",
    image: require('../../../../../assets/image/cases/Придумать дизайн лэндинга.jpg'),
    message: `
      <span>Тема лэндинга</span>Сайт по продаже велотоваров<br/><br/>
      <span>Целевая аудиторию лэндинга</span>Мужчины и женщины от 18-55<br/><br/>
      <span>Цветовая гамма лэндинга</span>Оранжевый, белый.
    `,
    video: "https://disk.ugmk-telecom.ru/index.php/s/lYX6d9pa8xnZ4L0/download?path=%2F&files=%D0%A1%D0%BE%D0%B7%D0%B4%D0%B0%D1%82%D1%8C_%D0%B4%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD_%D0%BB%D1%8D%D0%BD%D0%B4%D0%B8%D0%BD%D0%B3%D0%B0.mp4"
  },
];
class Cases extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      casesList,
      itemPopUpVideo: null,
      activeSlide: 0
    };
    this.refSwiper = React.createRef();
  }

  changeSlide = (event) => {
    this.setState({
      activeSlide: event?.activeIndex
    });
  }
  prevSlide = () => {
    this.refSwiper.current.swiper.slidePrev()
  }
  nextSlide = () => {
    this.refSwiper.current.swiper.slideNext()
  }

  openItemPopUpVideo = (item) => {
    this.setState({ itemPopUpVideo: item });
  }
  closeItemPopUpVideo = () => {
    this.setState({ itemPopUpVideo: null });
  }

  _title = () => {
    const { casesList, activeSlide } = this.state;

    const title = (casesList || [])?.[activeSlide]?.title || "";
    return title
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      casesList,
      itemPopUpVideo
    } = this.state;

    return (
      <Box id="cases">
        <Container maxWidth='xl'>
          <Typography className={classes.title}>Кейсы применения</Typography>
          <Box className={classes.section}>
            <Box className={classes.sectionHead}>
              <Typography className="--title">{this._title()}</Typography>
              <Grid container spacing={1} className="--navigation" wrap="nowrap">
                <Grid item>
                  <IconButton onClick={this.prevSlide}>
                    <ArrowBackIcon/>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={this.nextSlide}>
                    <ArrowForwardIcon/>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.sectionBody}>
              <Swiper
                ref={this.refSwiper}
                spaceBetween={50}
                slidesPerView={1}
                speed={1000}
                autoHeight={true}
                onSlideChange={this.changeSlide}
              >
                {casesList.map((item, index) => (
                  <SwiperSlide key={`SwiperSlide-${index}`}>
                    <SliderElement
                      item={item}
                      onPlay={this.openItemPopUpVideo.bind(this, item)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        </Container>

        {Boolean(!!itemPopUpVideo) && (
          <PopUpVideo
            item={itemPopUpVideo}
            onClose={this.closeItemPopUpVideo}
          />
        )}
      </Box>
    )
  }
}
const SliderElement = React.memo((props) => {
  const {
    item,
    onPlay
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.sliderItem}>
      <Box className={classes.sliderItemLeft}>
        <Typography className="--title">Пример запроса</Typography>
        <Typography
          className="--message"
          dangerouslySetInnerHTML={{
            __html: item.message
          }}
        />
        {Boolean(item.video) && (
          <Button className="--button-video" onClick={onPlay}>
            Посмотреть видеоинструкцию
            <SmartDisplayIcon sx={{fontSize: 20}}/>
          </Button>
        )}
      </Box>
      <Box className={classes.sliderItemRight}>
        <img src={item.image}/>
      </Box>
    </Box>
  )
});
const PopUpVideo = React.memo((props) => {
  const {
    item,
    onClose
  } = props;
  const classes = useStyles();
  const refVideo = React.createRef();

  const onLoadStart = () => {
    refVideo.current.playbackRate = 3;
  }

  return (
    <Dialog
      open={Boolean(item)}
      fullWidth
      maxWidth="lg"
      className={classes.videoDialog}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h3" textAlign="left">{ item?.title }</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.videoSection}>
          <video
            ref={refVideo}
            controls={true}
            autoPlay={true}
            onLoadStart={onLoadStart}
          >
            <source src={item?.video} type="video/mp4"/>
          </video>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box px={2} pb={1}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={onClose}>
                Закрыть
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  )
});

const styles = {
  title: {
    marginBottom: 42,

    color: '#254164',
    textAlign: 'center',
    fontSize: '50px',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '-1px',
  },

  section: {
    padding: "30px",
    boxSizing: "border-box",
    borderRadius: "26px",
    backgroundColor: "rgba(46, 139, 255, 0.05)"
  },
  sectionHead: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,

    "& .--title": {
      color: '#2E8BFF',
      fontSize: '26px',
      fontWeight: '500',
      lineHeight: '116%',
      letterSpacing: '-0.52px',
    },
    "& .--navigation": {
      width: "auto",
      marginLeft: "auto"
    },
    "& .MuiIconButton-root": {
      background: "rgba(255, 255, 255, 0.5)",
      color: "#99ACC4",

      "&:hover": {
        background: "linear-gradient(90deg, #2E8BFF 0%, #AC42FF 100%)",
        color: "white"
      }
    }
  },
  sectionBody: {
    "& .swiper": {
      margin: '-30px',
      padding: '30px',
      boxSizing: 'border-box',
    }
  },

  "@media(max-width: 1199px)": {
    title: {
      marginBottom: 32
    },
    sectionHead: {

    }
  },
  "@media(max-width: 1023px)": {
    section: {
      padding: 15,
      borderRadius: 10
    },
    sectionHead: {
      "& .--title": {
        fontSize: 18
      },
      "& .MuiIconButton-root": {
        width: '32px',
        height: '32px',
        padding: '5px',
        boxSizing: 'border-box',
      },
      "& .MuiIconButton-root svg": {
        maxWidth: "100%",
        maxHeight: "100%"
      },
    },
    sectionBody: {
      "& .swiper": {
        margin: '-15px',
        padding: '15px',
        boxSizing: 'border-box',
      }
    },
  },
  "@media(max-width: 640px)": {
    title: {
      fontSize: 32,
      marginBottom: 20
    }
  },
};
const useStyles = makeStyles(() => ({
  sliderItem: {
    display: "flex",
    alignItems: "flex-start"
  },
  sliderItemLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    padding: "26px",
    boxSizing: "border-box",
    maxWidth: "420px",
    marginRight: "8px",
    borderRadius: "16px",
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: 'rgba(255, 255, 255, 0.10)',
    boxShadow: '-1px 5px 12px 0px rgba(15, 68, 138, 0.05), -3px 21px 22px 0px rgba(15, 68, 138, 0.04), -7px 48px 29px 0px rgba(15, 68, 138, 0.03), -13px 85px 35px 0px rgba(15, 68, 138, 0.01), -20px 134px 38px 0px rgba(15, 68, 138, 0.00)',
    backdropFilter: 'blur(80px)',

    "& .--title": {
      marginBottom: "24px",

      color: '#99ACC3',
      fontSize: '16px',
      lineHeight: '116%',
      letterSpacing: '-0.32px',
    },
    "& .--message": {
      color: '#2E8BFF',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '130%',
      letterSpacing: '-0.42px',

      "& span": {
        display: "block",
        fontSize: '18px',
      }
    },
    "& .--button-video": {
      marginTop: 20,
      borderRadius: '100px',
      border: '0px solid rgba(0, 0, 0, 0.10)',
      background: 'linear-gradient(90deg, #2E8BFF 0%, #AC42FF 100%)',
      boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',

      padding: '4px 15px',
      fontSize: '14px',
      color: 'white',
},
    "& .--button-video svg": {
      marginLeft: 8
    },
  },
  sliderItemRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    borderRadius: '16px',
    padding: "4px",
    boxSizing: "border-box",
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: 'rgba(255, 255, 255, 0.85)',
    boxShadow: '-1px 5px 12px 0px rgba(15, 68, 138, 0.05), -3px 21px 22px 0px rgba(15, 68, 138, 0.04), -7px 48px 29px 0px rgba(15, 68, 138, 0.03), -13px 85px 35px 0px rgba(15, 68, 138, 0.01), -20px 134px 38px 0px rgba(15, 68, 138, 0.00)',
    backdropFilter: 'blur(80px)',
    position: "relative",
    overflow: "hidden",

    "& img": {
      maxWidth: "100%",
      maxHeight: "640px",
      borderRadius: "16px"
    }
  },

  videoDialog: {
    "& .MuiPaper-root": {
      borderRadius: '16px',
      border: '0.5px solid rgba(0, 0, 0, 0.10)',
      boxShadow: '-1px 5px 12px 0px rgba(15, 68, 138, 0.05), -3px 21px 22px 0px rgba(15, 68, 138, 0.04), -7px 48px 29px 0px rgba(15, 68, 138, 0.03), -13px 85px 35px 0px rgba(15, 68, 138, 0.01), -20px 134px 38px 0px rgba(15, 68, 138, 0.00)',
    }
  },
  videoSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    maxHeight: 640,

    borderRadius: '16px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: 'rgba(255, 255, 255, 0.85)',

    "& video": {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    }
  },

  "@media(max-width: 1199px)": {
    sliderItemLeft: {
      maxWidth: 320,
      padding: 15
    }
  },
  "@media(max-width: 1023px)": {
    sliderItem: {
      flexDirection: "column"
    },
    sliderItemLeft: {
      maxWidth: "initial",
      marginRight: "0",
      marginBottom: 12,

      '& .--title': {
        marginBottom: 12,
        fontSize: 12
      },
      "& .--message": {
        fontSize: 13,
        "& span": {
          fontSize: 15,
        }
      }
    },
    sliderItemRight: {
      "& img": {
        maxHeight: "initial"
      }
    }
  }
}))

Cases = withStyles(styles)(Cases);
export default Cases
