import React, {Component} from "react";
import {
  Box,
  Container,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class UserAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
  }

  render () {
    const {
      classes
    } = this.props;

    return (
      <Box sx={{padding: "24px 0", overflow: "auto", height: "100lvh"}}>
        <Container maxWidth="lg">
          <Box className={classes.section}>
            <Typography variant="h1" mb={2}>Пользовательское соглашение</Typography>
            <Typography variant="h6">
              <div data-contents="true">
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="6h6ot-0-0">
                  <div data-offset-key="6h6ot-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="6h6ot-0-0"><span data-text="true">Российская Федерация, г. Екатеринбург</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="2qoeq-0-0">
                  <div data-offset-key="2qoeq-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="2qoeq-0-0"><span data-text="true">«14» июня 2023&nbsp;г.</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="793g5-0-0">
                  <div data-offset-key="793g5-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="793g5-0-0"><span data-text="true">Общество с&nbsp;ограниченной ответственностью «Очень Хорошие Сервисы», именуемое в&nbsp;дальнейшем «Компания», предлагает пользователю интернет-ресурса </span></span><span
                    data-offset-key="793g5-0-1" style={{fontWeight:'bold'}}><span data-text="true">«AISynergy»</span></span><span
                    data-offset-key="793g5-0-2"><span data-text="true"> (далее по&nbsp;тексту&nbsp;— Пользователь) акцептировать настоящую Публичную оферту, адресованную неопределенному кругу физических лиц и&nbsp;представляющую собой Пользовательское соглашение (далее&nbsp;— Соглашение) по&nbsp;использованию интернет-ресурса </span></span><span
                    data-offset-key="793g5-0-3" style={{fontWeight:'bold'}}><span data-text="true">«AISynergy»</span></span><span
                    data-offset-key="793g5-0-4"><span data-text="true">, расположенного по&nbsp;адресу </span></span><span
                    data-offset-key="793g5-0-5" style={{fontWeight:'bold'}}><span
                    data-text="true">https://aisynergy.ru</span></span><span data-offset-key="793g5-0-6"><span
                    data-text="true">, (далее&nbsp;— «Интернет-ресурс») на&nbsp;нижеследующих условиях.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="2v47o-0-0">
                  <div data-offset-key="2v47o-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="2v47o-0-0" style={{fontWeight:'bold'}}><span
                    data-text="true">1. Предмет соглашения</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="8g051-0-0">
                  <div data-offset-key="8g051-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="8g051-0-0"><span data-text="true">1.1. Настоящее соглашение регулирует порядок сбора и&nbsp;использования персональных данных пользователей Интернет-ресурса в&nbsp;случае их&nbsp;регистрации на&nbsp;Интернет-ресурсе, а&nbsp;также&nbsp;— порядок пользования платными и&nbsp;бесплатными услугами Интернет-ресурса.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="8rfic-0-0">
                  <div data-offset-key="8rfic-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="8rfic-0-0"><span data-text="true">1.2. Сбор персональных данных производится только после присоединения Пользователя к&nbsp;настоящему Соглашению. Пользователь предоставляет персональные данные исключительно по&nbsp;собственной воле.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="3lami-0-0">
                  <div data-offset-key="3lami-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="3lami-0-0"><span data-text="true">1.3. Соблюдение конфиденциальности относительно персональных данных обеспечивается с&nbsp;момента, когда эти данные были представлены лицом, к&nbsp;которому эти данные относятся.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="54nof-0-0">
                  <div data-offset-key="54nof-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="54nof-0-0"><span data-text="true">1.4. Персональные данные Пользователя не&nbsp;могут быть использованы в&nbsp;целях, противоречащих целям оказания услуг на&nbsp;Интернет-ресурсе. Не&nbsp;допускается использование персональных данных в&nbsp;целях причинения имущественного&nbsp;и (или) морального вреда, ограничения реализации прав и&nbsp;свобод, гарантированных законами Российской Федерации.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="ah5q9-0-0">
                  <div data-offset-key="ah5q9-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="ah5q9-0-0" style={{fontWeight:'bold'}}><span data-text="true">2. Понятие и&nbsp;состав персональных данных</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="75e7p-0-0">
                  <div data-offset-key="75e7p-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="75e7p-0-0"><span data-text="true">2.1. Под персональными данными Пользователя понимается информация о&nbsp;физическом или юридическом лице, необходимая для предоставления более подробной информации об&nbsp;услугах, а&nbsp;также для оказания данных услуг.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="cqn26-0-0">
                  <div data-offset-key="cqn26-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="cqn26-0-0"><span data-text="true">2.2. Состав персональных данных:</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="9c9ee-0-0">
                  <div data-offset-key="9c9ee-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="9c9ee-0-0"><span data-text="true">1) для физических лиц: фамилия, имя, номер стационарного (мобильного) телефона, адрес электронной почты.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="4e4ug-0-0">
                  <div data-offset-key="4e4ug-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="4e4ug-0-0"><span data-text="true">2) для юридических лиц: наименование, юридический адрес, номер стационарного (мобильного) телефона, адрес электронной почты.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="2ur9f-0-0">
                  <div data-offset-key="2ur9f-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="2ur9f-0-0" style={{fontWeight:'bold'}}><span
                    data-text="true">3. Права и&nbsp;обязанности Компании</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="19f3u-0-0">
                  <div data-offset-key="19f3u-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="19f3u-0-0"><span data-text="true">3.1. Компания обязуется:</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="bqo8l-0-0">
                  <div data-offset-key="bqo8l-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="bqo8l-0-0"><span
                    data-text="true">1) собирать персональные данные в&nbsp;объеме, не&nbsp;превышающем необходимый для оказания услуг на&nbsp;Интернет-ресурсе;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="b36ps-0-0">
                  <div data-offset-key="b36ps-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="b36ps-0-0"><span data-text="true">2) не&nbsp;передавать персональные данные третьим лицам без согласия Пользователя, за&nbsp;исключением случаев, предусмотренных законодательством;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="9kfs9-0-0">
                  <div data-offset-key="9kfs9-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="9kfs9-0-0"><span data-text="true">3) не&nbsp;публиковать полученные персональные данные, кроме тех, в&nbsp;отношении которых Пользователем явно выражено согласие на&nbsp;их&nbsp;публикацию;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="59lse-0-0">
                  <div data-offset-key="59lse-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="59lse-0-0"><span
                    data-text="true">4) обеспечить защиту персональных данных от&nbsp;неправомерного использования или утраты в&nbsp;порядке, установленном законодательством.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="1scpl-0-0">
                  <div data-offset-key="1scpl-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="1scpl-0-0" style={{fontWeight:'bold'}}><span
                    data-text="true">4. Права и&nbsp;обязанности Пользователя</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="db8u9-0-0">
                  <div data-offset-key="db8u9-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="db8u9-0-0"><span data-text="true">4.1. Пользователь обязуется:</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="ai7rq-0-0">
                  <div data-offset-key="ai7rq-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="ai7rq-0-0"><span data-text="true">1) передавать Компании только достоверные персональные данные;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="bp93l-0-0">
                  <div data-offset-key="bp93l-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="bp93l-0-0"><span data-text="true">2) не&nbsp;использовать и&nbsp;не&nbsp;передавать персональные данные третьих лиц без их&nbsp;согласия. При размещении одним Пользователем персональных данных нескольких пользователей предполагается наличие у&nbsp;Пользователя необходимых полномочий;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="b55ft-0-0">
                  <div data-offset-key="b55ft-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="b55ft-0-0"><span
                    data-text="true">3) извещать об&nbsp;изменении персональных данных в&nbsp;случаях, когда это может повлиять на&nbsp;оказание услуг Компанией;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="1qfse-0-0">
                  <div data-offset-key="1qfse-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="1qfse-0-0"><span data-text="true">4) не&nbsp;использовать ненадежные, легко подбираемые пароли для доступа к&nbsp;своей учетной записи на&nbsp;Интернет-ресурсе;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="c3a82-0-0">
                  <div data-offset-key="c3a82-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="c3a82-0-0"><span
                    data-text="true">5) не&nbsp;передавать пароль для доступа к&nbsp;своей учетной записи третьим лицам.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="4pus5-0-0">
                  <div data-offset-key="4pus5-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="4pus5-0-0"><span data-text="true">4.2. Пользователь вправе:</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="4v9n1-0-0">
                  <div data-offset-key="4v9n1-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="4v9n1-0-0"><span data-text="true">1) требовать исключения или исправления своих персональных данных;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="2mm7o-0-0">
                  <div data-offset-key="2mm7o-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="2mm7o-0-0"><span data-text="true">2) иметь свободный доступ к&nbsp;своим персональным данным;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="f0u31-0-0">
                  <div data-offset-key="f0u31-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="f0u31-0-0"><span data-text="true">3) отказаться от&nbsp;передачи персональных данных Компании. При этом Пользователь извещен о&nbsp;том, что не&nbsp;сможет иметь доступа к&nbsp;некоторым платным услугам Интернет-ресурса.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="evn8q-0-0">
                  <div data-offset-key="evn8q-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="evn8q-0-0" style={{fontWeight:'bold'}}><span data-text="true">5. Защита персональных данных</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="c0tkp-0-0">
                  <div data-offset-key="c0tkp-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="c0tkp-0-0"><span data-text="true">5.1. Для защиты персональных данных Пользователя Компания:</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l" data-offset-key="j8e8-0-0">
                  <div data-offset-key="j8e8-0-0" className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
                    <span data-offset-key="j8e8-0-0"><span data-text="true">1) определяет специально уполномоченных сотрудников и&nbsp;порядок доступа к&nbsp;персональным данным;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="3ph3r-0-0">
                  <div data-offset-key="3ph3r-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="3ph3r-0-0"><span data-text="true">2) не&nbsp;допускает передачу персональных данных третьим лицам без согласия Пользователя.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="3m9ni-0-0">
                  <div data-offset-key="3m9ni-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="3m9ni-0-0" style={{fontWeight:'bold'}}><span data-text="true">6. Ответственность</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="fpde3-0-0">
                  <div data-offset-key="fpde3-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="fpde3-0-0"><span
                    data-text="true">6.1. Компания не&nbsp;несет ответственности за&nbsp;несанкционированное распространение персональных данных, в&nbsp;случаях:</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="1ujbv-0-0">
                  <div data-offset-key="1ujbv-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="1ujbv-0-0"><span data-text="true">1) если это явилось следствием неосмотрительности Пользователя, использовавшего легкий для подбора пароль к&nbsp;учетной записи или сделавшего доступным использование пароля третьими лицами;</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="7m459-0-0">
                  <div data-offset-key="7m459-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="7m459-0-0"><span
                    data-text="true">2) если Компания предприняла все зависящие от&nbsp;нее меры для обеспечения защиты персональных данных, однако несанкционированный доступ к&nbsp;персональным данным произошел в&nbsp;результате противоправных действий третьих лиц (взлом, атака) или уязвимостей в&nbsp;программном обеспечении.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="380ik-0-0">
                  <div data-offset-key="380ik-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="380ik-0-0"><span data-text="true">6.2. Во&nbsp;всех остальных случаях ответственность сторон наступает в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="e25u1-0-0">
                  <div data-offset-key="e25u1-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="e25u1-0-0" style={{fontWeight:'bold'}}><span data-text="true">Настоящий документ является публичной офертой.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l" data-offset-key="lh1v-0-0">
                  <div data-offset-key="lh1v-0-0" className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
                  <span data-offset-key="lh1v-0-0" style={{fontWeight:'bold'}}><span
                    data-text="true">В&nbsp;соответствии с&nbsp;пунктом 2&nbsp;статьи 437 Гражданского Кодекса Российской Федерации (ГК&nbsp;РФ) принятие и&nbsp;соглашение со&nbsp;всеми изложенными выше условиями (акцепт оферты) совершается Пользователем путем осуществлений действий, указанных в&nbsp;настоящей Публичной оферте.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="57cml-0-0">
                  <div data-offset-key="57cml-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="57cml-0-0" style={{fontWeight:'bold'}}><span
                    data-text="true">В&nbsp;случае несогласия с&nbsp;условиями Публичной оферты, Пользователь обязуется прекратить пользование услугами Интернет-ресурса.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="6e0oq-0-0">
                  <div data-offset-key="6e0oq-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="6e0oq-0-0" style={{color:'#1f1f1f',textDecorationColor:'#1f1f1f',fontWeight:'bold'}}><span
                    data-text="true">Регистрируясь на&nbsp;интернет-ресурсе  https://aisynergy.ru, </span></span><span
                    data-offset-key="6e0oq-0-1" style={{fontWeight:'bold'}}><span data-text="true">вы&nbsp;в&nbsp;полной мере соглашаетесь с&nbsp;изложенными выше положениями, в&nbsp;целом и&nbsp;безоговорочно принимая условия настоящей Публичной оферты.</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="af7p7-0-0">
                  <div data-offset-key="af7p7-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="af7p7-0-0" style={{fontWeight:'bold'}}><span data-text="true">Реквизиты Компании</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="bg2ko-0-0">
                  <div data-offset-key="bg2ko-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="bg2ko-0-0"><span
                    data-text="true">Наименование: ООО «Очень Хорошие Сервисы»</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="2v3go-0-0">
                  <div data-offset-key="2v3go-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="2v3go-0-0"><span data-text="true">Юридический адрес: 620075, Свердловская область, г. Екатеринбург, улица Первомайская, 15, офис 301</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="63c3j-0-0">
                  <div data-offset-key="63c3j-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="63c3j-0-0"><span data-text="true">Банковские реквизиты:</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="28ifg-0-0">
                  <div data-offset-key="28ifg-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="28ifg-0-0"><span data-text="true">ИНН/КПП 6670497950\667001001</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="8k7i3-0-0">
                  <div data-offset-key="8k7i3-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="8k7i3-0-0"><span data-text="true">р/с: 40702810302500085578</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="agido-0-0">
                  <div data-offset-key="agido-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="agido-0-0"><span data-text="true">Банк: Филиал «Точка» ПАО Банка «Финансовая Корпорация Открытие»</span></span>
                  </div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="2j7u4-0-0">
                  <div data-offset-key="2j7u4-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="2j7u4-0-0"><span data-text="true">к/с: 30101810845250000999</span></span></div>
                </div>
                <div className="_17fgIIn___block normal" data-block="true" data-editor="equ0l"
                     data-offset-key="8ikqg-0-0">
                  <div data-offset-key="8ikqg-0-0"
                       className="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span
                    data-offset-key="8ikqg-0-0"><span data-text="true">БИК 044525999</span></span></div>
                </div>
              </div>
            </Typography>
          </Box>
        </Container>
      </Box>
    );
  }
}

const styles = {
  section: {
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: "24px 20px",
    boxSizing: "border-box"
  }
};
UserAgreement = withStyles(styles)(UserAgreement);

export default UserAgreement
