import React from "react";
import {
  Box,
  Grid,
  Tooltip,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  Help as HelpIcon
} from "@mui/icons-material";
import {
  makeStyles
} from "@mui/styles";
import {
  HeadElement
} from "./ElementInput";

const ElementInput = (props) => {
  const {
    value,
    settings,
    onChange,

    ...other
  } = props;
  const classes = useStyles();

  const handleChange = (event, value) => {
    onChange({
      target: {
        name: props.slug,
        value: value ||""
      }
    })
  }

  return (
    <Box>
      <HeadElement
        clue={settings?.clue}
        label={settings?.label}
        helpMessage={settings?.help_message}
      />

      <Autocomplete
        value={value}
        name={props.slug}
        options={settings?.options}
        fullWidth
        disablePortal
        renderInput={(params) => <TextField {...other} {...params} placeholder={settings.placeholder} />}
        onChange={handleChange}
      />

    </Box>
  )
}

const useStyles = makeStyles(() => ({
  helpMessageTooltip: {
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'left',
  }
}));

export default React.memo(ElementInput)
