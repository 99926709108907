import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	ScenariosForm
} from "../../../../../../components";

class DialogEditTask extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			task: null,
			taskIndex: null,
			onSubmit: null,
			isOpen: false,
		}
	}

	open = ({ task, taskIndex, onSubmit }) => {
		this.setState({
			task,
			taskIndex,
			onSubmit,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			task: null,
			onSubmit: null,
			taskIndex: null,
			isOpen: false
		})
	}

	onSubmit = (scenarioId, formSubmit) => {
		this.state.onSubmit(formSubmit);
	}

	render () {
		const {
			task,
			taskIndex,

			isOpen
		} = this.state;
		const {
			classes
		} = this.props;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="md"
				onClose={this.close}
			>
				<DialogTitle textAlign="left">
					<Typography variant="h1" sx={{ color: '#2C4058' }}>
						Редактирование задачи №{ taskIndex + 1 }
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Box className={classes.scenariosForm}>
						<ScenariosForm
							initTask={task}
							labelButtonSubmit="Изменить"

							onSubmit={this.onSubmit}
						/>
					</Box>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		)
	}
}

const styles = {
	scenariosForm: {
		"& .scenarios-form-root": {
			padding: "0",
			border: "none",
			borderRadius: "initial",
			boxShadow: "initial",
		},
		"& .MuiTabs-root, & .MuiDivider-root, & .scenarios-form-select-scenario, & .select-number-options": {
			display: "none"
		},
		"& .scenarios-form-spacing-component": {
			marginTop: 32
		}
	}
};
DialogEditTask = withStyles(styles)(DialogEditTask);

export default DialogEditTask
