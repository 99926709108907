import React from "react";
import {
  Box,
  Backdrop,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";

const ScenariosLoading = (props) => {
  const {
    open
  } = props;
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, (60/100*1000));
    return () => {
      setProgress(0);
      clearInterval(timer);
    };
  }, []);
  React.useEffect(() => {
    setProgress(0);
  }, [open])

  if (!open) {
    return null
  }
  return (
    <Backdrop open={true}>
      <Box className={classes.root}>
        <img src={require("../../assets/image/logo/logo-small.png")}/>
        <CircularProgress variant="determinate" value={progress} thickness={0} color="primary"/>
      </Box>
    </Backdrop>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "100%",
    width: 240,
    height: 240,
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

    "& img": {
      maxWidth: "65%",
      pointerEvents: "none"
    },
    "& .MuiCircularProgress-root": {
      width: "100%!important",
      height: "100%!important",
      position: "absolute",
    },
    "& .MuiCircularProgress-root svg circle": {
      strokeWidth: "1.5px"
    },
  },
  textPercent: {
    fontSize: 24,
    lineHeight: "32px",
    marginTop: "-10px",
    color: "#126DDE"
  },
}));

export default React.memo(ScenariosLoading)
