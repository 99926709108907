import React from "react";
import {
  Box
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import Header from "../../pages/auth/Welcome/components/Header/Header";

class Landing extends React.PureComponent {
  render () {
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Header/>
        { this.props.children }
      </Box>
    )
  }
}

const styles = {
  root: {
    background: "#F2F6F9"
  }
};
Landing = withStyles(styles)(Landing);

export default Landing
