import React from "react";
import {
  Box,
  Grid,
  Divider,
  Popover,
  Container,
  Typography,
  IconButton,

  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  Psychology as PsychologyIcon,
  PlaylistAdd as PlaylistAddIcon,
  RequestPage as RequestPageIcon,
  AddPhotoAlternate as AddPhotoAlternateIcon
} from "@mui/icons-material";
import {
  Link, useRouteMatch
} from "react-router-dom";
import {
  palette
} from "../../../../theme/common";
import clsx from "clsx";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const Header = (props) => {
  const {
    profile,
    onLogout
  } = props;
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Box className={classes.root}>
        <a href="https://aisynergy.ru" className={classes.logo}>
          <img src={require("../../../../assets/image/logo/logo-round.svg").default}/>
        </a>

        <Grid container spacing={3} className={classes.hideMobile} sx={{marginLeft: "auto", flex: "auto"}}>
          <Grid item>
            <ElementMenu
              Icon={PlaylistAddIcon}
              title="Работа с текстом"
              path="/task/create"
              activePath={['/task/create', '/task/:id']}
            />
          </Grid>
          <Grid item>
            <ElementMenu
              Icon={AddPhotoAlternateIcon}
              title="Работа с изображениями"
              path="/midjourney/create"
              activePath={['/midjourney/create', '/midjourney/:id']}
            />
          </Grid>
          <Grid item>
            <ElementMenu
              Icon={PsychologyIcon}
              title="Чат с ИИ"
              path="/chat-ai"
              activePath={['/chat-ai']}
            />
          </Grid>
          <Grid item>
            <ElementMenu
              Icon={RequestPageIcon}
              title="Тарифы"
              path="/tariff"
              activePath={['/tariff']}
            />
          </Grid>
        </Grid>

        <Divider orientation="vertical" className={classes.divider}/>

        <Profile profile={profile}/>

        <Grid container className={clsx([classes.profileMenu, classes.hideMobile])} sx={{flex: 0}} spacing="28px" wrap="nowrap">
          <Grid item>
            <Link to="/history">
              <Typography>История</Typography>
            </Link>
          </Grid>
        </Grid>

        <Box className={clsx([classes.buttonExit, classes.hideMobile])} onClick={onLogout}>
          <Typography>Выйти</Typography>
          <ExitToAppIcon/>
        </Box>

        <Box className={classes.dropdownMobileMenu}>
          <DropdownMobileMenu
            onLogout={onLogout}
          />
        </Box>
      </Box>
    </Container>
  )
}
const DropdownMobileMenu = React.memo((props) => {
  const {
    onLogout
  } = props;
  const classes = useStyles();
  const mainMenuItems = [
    {label: "Работа с текстом", path: "/task/create", Icon: PlaylistAddIcon},
    {label: "Работа с изображениями", path: "/midjourney/create", Icon: AddPhotoAlternateIcon},
    {label: "Чат с ИИ", path: "/chat-ai", Icon: PsychologyIcon},
    {label: "Тарифы", path: "/tariff", Icon: RequestPageIcon},
  ];
  const additionalMenuItems = [
    {label: "История", path: "/history"},
    {label: "Сохранённое", path: "/saved"}
  ];

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <div>
          <IconButton color="primary" variant="contained" {...bindTrigger(popupState)}>
            <MenuIcon/>
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{
              root: classes.dropdownMobileMenuRoot
            }}
          >
            <>

              <MenuList>
                {mainMenuItems.map((item) => (
                  <MenuItem to={item.path} component={Link}>
                    <ListItemIcon>
                      <item.Icon sx={{fontSize: 20}} color="primary"/>
                    </ListItemIcon>
                    <ListItemText>{ item.label }</ListItemText>
                  </MenuItem>
                ))}

              </MenuList>
              <MenuList>
                {additionalMenuItems.map((item) => (
                  <MenuItem to={item.path} component={Link}>
                    <ListItemText>{ item.label }</ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
              <MenuList>
                <MenuItem onClick={onLogout}>
                  <ListItemText>Выход из системы</ListItemText>
                </MenuItem>
              </MenuList>

            </>
          </Popover>
        </div>
      )}
</PopupState>
  )
});
const ElementMenu = React.memo((props) => {
  const {
    Icon,
    title,
    path,
    activePath,
  } = props;
  const classes = useStyles();
  const pathname = useRouteMatch()?.path || "";
  const isActive = (activePath || []).includes(pathname);

  return (
    <Link to={path}>
      <Box className={clsx({
        [classes.elementMenu]: true,
        "active": isActive
      })}>
        <Icon/>
        <span>{ title }</span>
      </Box>
    </Link>
  )
});
const Profile = React.memo((props) => {
  const { profile } = props;
  const classes = useStyles();

  return (
    <Link to="/profile" className={classes.profile}>
      <Box className={classes.profileIcon}></Box>
      <Typography className={classes.profileName}>
        { [profile?.first_name, profile?.last_name].filter((t) => !!t).join(" ") || `Пользователь №${profile?.user_id}` }
      </Typography>
    </Link>
  )
});

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    borderRadius: "100px",
    height: 72,
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "rgba(255, 255, 255, 0.70)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    backdropFilter: "blur(40px)",
    padding: "8px",
    paddingRight: "24px",
    boxSizing: "border-box",

    "@media(max-width: 1199px)": {
      padding: "3px",
      height: 64,
      alignItems: "center"
    }
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media(max-width: 1199px)": {
      width: 56,
      height: 56,
    }
  },
  hideMobile: {
    "@media(max-width: 1199px)": {
      display: "none!important"
    }
  },
  divider: {
    margin: "-8px 0",
    marginRight: "32px",
    height: "72px",
    width: "1px",
    backgroundColor: "black",
    opacity: 0.1
  },

  elementMenu: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: 72,
    margin: "-8px 0",

    color: palette.primary.text,
    fontFamily: "Suisse Intl",
    fontSize: 15,
    fontWeight: "500",
    letterSpacing: "-0.3px",

    "& span": {
      marginLeft: 8
    },
    "&:after": {
      position: "absolute",
      left: 0, right: 0,
      bottom: 0,
      height: 4,
      borderRadius: "100px",
      background: palette.primary.main
    },
    "&.active:after": {
      content: "''"
    }
  },

  profile: {
    display: "flex",
    alignItems: "center",
    marginRight: "28px",
    cursor: "pointer",

    "@media(max-width: 1199px)": {
      marginLeft: "auto"
    }
  },
  profileIcon: {
    width: 32,
    height: 32,
    border: "1px solid rgba(0,0,0,0.15)",
    borderRadius: "100%",
    backgroundColor: "white",
    marginRight: "10px"
  },
  profileName: {
    maxWidth: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",

    flex: 1,
    color: "#2C4058",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "-0.24px",
  },

  profileMenu: {
    "& a": {
      display: "flex",
      alignItems: "center",
      height: 70,
      margin: "-8px 0"
    },
    "& a p": {
      display: "flex",
      alignItems: "center",
      color: "#2C4058",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "-0.24px",
    },
    "@media(max-width: 1199px)": {
      marginLeft: "auto"
    }
  },

  buttonExit: {
    display: "flex",
    alignItems: "center",
    marginLeft: "28px",
    cursor: "pointer",
    userSelect: "none",

    "& p": {
      fontSize: "12px",
      lineHeight: "100%",
      color: "#99ACC3",
      letterSpacing: "-0.24px"
    },
    "& svg": {
      color: "#99ACC3",
      fontSize: "20px",
      marginLeft: "18px"
    },
    "&:hover": {
      "& p": {
        color: palette.primary.text
      },
      "& svg": {
        color: palette.primary.text
      },
    }
  },

  dropdownMobileMenu: {
    display: "none",

    width: 38,
    height: 38,
    marginRight: 8,

    "@media(max-width: 1199px)": {
      display: "flex"
    },
    "& .MuiButtonBase-root": {
      width: 38,
      height: 38,
      borderRadius: "77px",
      border: "0.5px solid rgba(0, 0, 0, 0.10)",
      background: "rgba(255, 255, 255, 0.60)",
      boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
      backdropFilter: "blur(40px)",
      padding: 7,

      "& svg": {
        width: "100%",
        height: "100%",
      }
    }
  },
  dropdownMobileMenuRoot: {
    "& .MuiPaper-root": {
      borderRadius: "16px",
      border: "0.5px solid rgba(0, 0, 0, 0.10)",
      background: "#FFF",
      boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    },
    "& .MuiButtonBase-root": {
      padding: "8px 15px",
      minHeight: "initial"
    },
    "& .MuiTypography-root": {
      fontSize: 12,
      lineHeight: "16px",
      color: palette.primary.main,
      fontWeight: 400
    }
  },
}));

export default React.memo(Header)
