import React from 'react';
import {
  compose
} from 'recompose';
import {
  connect
} from 'react-redux';
import {
  DialogNews,
  LoadingContent
} from "../components";
import AuthRoute from "./Auth";
import CabinetRoute from "./Cabinet";
import agent from "../agent/agent";
import {
  Notification,
  notificationTypes
} from "../common/Notification";
import {setUserProfile, updateNews} from "../states/global";
import directory, {
  initDirectory
} from "../states/directory";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {Centrifuge} from "centrifuge";
import {setWsMessage} from "../states/ws-messages";
import Cookies from 'js-cookie'

class Router extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true
		};
    this.socket = null;
    this.timeOutUpdateToken = null;
	}

  componentDidMount = async () => {
    await this.saveUtmSource();
    await this.checkReferral();
    await this.checkAuthProvider();

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      this.setState({ isLoading: false })
      return
    }

    const isValidToken = await this.checkTokenValid();
    if (!isValidToken) {
      const isSuccessUpdate = await this.updateAccessToken();
      if (!isSuccessUpdate) {
        this.setState({ isLoading: false })
        return
      }
    }

    await this.initDirectory();
    await this.checkAuth();
    await this.getNews();
  }
  componentDidUpdate = async (prevProps) => {
    const prevProfileId = prevProps?.profile?.user_id;
    const currentProfileId = this.props?.profile?.user_id;
    if (prevProfileId !== currentProfileId && !prevProfileId) {
      await this.initDirectory();
      await this.updateUserToken();
    }
  }

  saveUtmSource = async () => {
    const search = Cookies.get('current_url');
    if (!search) {
      return
    }
    if (!search.includes('utm_source=')) {
      return;
    }

    localStorage.setItem('utm-source-search', search);
    Cookies.remove('current_url');
  }
  checkAuthProvider = async () => {
    const { location } = this.props;
    const search = queryString.parse(location?.search, {
      arrayFormat: "bracket"
    });

    if (!['/auth/o/google/callback', '/auth/o/yandex/callback'].includes(location.pathname)) {
      return
    }

    let additionalSearch = [
      `code=${ search?.code }`,
      `state=${ search?.state }`
    ];
    const utmSourceSearch = localStorage.getItem('utm-source-search');
    if (!!utmSourceSearch) {
      additionalSearch.push(`adv_source=${(utmSourceSearch||'').replaceAll('&', '%3Dcpc%26')}`);
      localStorage.removeItem('utm-source-search');
    }

    const referral = localStorage.getItem('referral');
    if (!!referral) {
      additionalSearch.push(`referral=${referral}`);
      localStorage.removeItem('referral')
    }

    additionalSearch = additionalSearch.join('&');

    if (location.pathname === "/auth/o/google/callback") {
      const res = await agent.get(`/auth/o/google/callback?provider=google&${additionalSearch}`).then((res) => {
        return res.data
      }).catch((err) => {
        return {error: err.response}
      })
      if (res?.error) {
        Notification({
          message: res?.error?.data?.message || "Ошибка авторизации",
          type: notificationTypes.error
        })
        return
      }

      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      agent.defaults.headers['Authorization'] = `Bearer ${res.access_token}`;
    }
    if (location.pathname === "/auth/o/yandex/callback") {
      const res = await agent.get(`/auth/o/yandex/callback?provider=yandex&${additionalSearch}`).then((res) => {
        return res.data
      }).catch((err) => {
        return {error: err.response}
      });
      if (res?.error) {
        Notification({
          message: res?.error?.data?.message || "Ошибка авторизации",
          type: notificationTypes.error
        })
        return
      }

      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      agent.defaults.headers['Authorization'] = `Bearer ${res.access_token}`;
    }

    return null
  }
  checkReferral = async () => {
    let currentUrl = Cookies.get('current_url');
    if (!currentUrl) {
      return
    }
    currentUrl = currentUrl.split('?')?.[1] || '';
    const search = queryString.parse(currentUrl, {
      arrayFormat: "bracket"
    });
    if (!search?.referral) {
      return null
    }
    localStorage.setItem('referral', search?.referral);
    Cookies.remove('current_url');
  }

  initDirectory = async () => {
    if ((this.props.directory?.scenarios || []).length > 0) {
      return
    }
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return
    }
    await this.props.initDirectory();
  }

  checkUserConfirm = async () => {
    return true

    const res = await agent.get(`/accounts/current`).then((res) => {
      return res.data
    }).catch((err) => {
      return null
    });
    if (!res) {
      return true
    }
    if (res.email_verification) {
      return true
    }

    agent.defaults.headers['Authorization'] = '';
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    this.props.setUserProfile(null);
    this.props.history.push(`/registration/confirm`);
    this.setState({ isLoading: false });

    return false
  }
  updateUserToken = async () => {
    await this.updateAccessToken();
    setTimeout(async () => {
      await this.updateUserToken();
    }, (15 * 60 * 1000))
  }

  checkTokenValid = async () => {
    const accessToken = localStorage.getItem('access_token');
    return await agent.get(`/auth/token/valid?token=${ accessToken }`).then((res) => {
      return Boolean(res.data?.status === 'ok')
    }).catch(() => {
      return false
    });
  }
  checkAuth = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      this.setState({ isLoading: false })
      return
    }

    const profile = await agent.get('/accounts/profile').then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const isConfirmUser = await this.checkUserConfirm();
    if (!profile || !isConfirmUser) {
      this.setState({ isLoading: false })

      agent.defaults.headers['Authorization'] = '';
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      return
    }

    await this.props.setUserProfile(profile);
    this.setState({ isLoading: false });

    await this.startWatchWs();
  }
  updateAccessToken = async () => {
    const res = await agent.post(`/auth/token/refresh`, {
      refresh_token: localStorage.getItem("refresh_token")
    }).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!res) {
      return false
    }

    agent.defaults.headers['Authorization'] = `Bearer ${res.access_token}`;
    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('refresh_token', res.refresh_token);

    return true
  }

  getNews = async () => {
    const newsList = await agent.get('/news').then((res) => {
      return res.data?.news || []
    }).catch(() => {
      return []
    });
    if (newsList.length <= 0) {
      return
    }

    const lastViewedNews = localStorage.getItem('last-viewed-news');
    const indexLastViewedNews = newsList.findIndex((t) => t.id === lastViewedNews);
    if (indexLastViewedNews === 0) {
      return
    }

    let listViews = [...newsList];
    if (indexLastViewedNews > 0) {
      listViews = listViews.splice(0, indexLastViewedNews);
    }

    this.props.updateNews(listViews);
  }

  startWatchWs = async () => {
    const socket = new Centrifuge([process.env.REACT_APP_HOST_WS, 'ws'].join("/"), {
      token: localStorage.getItem('access_token')
    });
    const self = this;

    socket.on('connected', (event) => {console.log('connected event:', event)});
    socket.on('connecting', (event) => {console.log('connecting event:', event)});
    socket.on('message', async (data) => {
      self.props.setWsMessage(data.data);
    });
    socket.connect();

    this.socket = socket;
  }

  render () {
    const {
      isLoading
    } = this.state;
    const {
      profile
    } = this.props;

    if (isLoading) {
      return <LoadingContent/>
    }
    if (!profile?.user_id) {
      return (
        <>
          <AuthRoute/>
        </>
      )
    }
    return (
      <>
        <CabinetRoute/>
      </>
    )
	}
}

Router = withRouter(Router);

export default compose(
  connect(
    state => ({
      profile: state?.global?.userProfile,
      directory: state.directory,
      news: state.global.news
    }),
    dispatch => ({
      setUserProfile: (profile) => dispatch(setUserProfile(profile)),
      initDirectory: () => dispatch(initDirectory()),
      updateNews: (news) => dispatch(updateNews(news)),

      setWsMessage: (item) => dispatch(setWsMessage(item))
    }),
  ),
)(Router);
