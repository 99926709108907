import React from "react";
import {
	Box,
	Grid,
	Tooltip,
	IconButton,
	Typography,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon,
	GppBad as GppBadIcon,
	ArrowBack as ArrowBackIcon,
	Visibility as VisibilityIcon,
	CheckCircle as CheckCircleIcon,
	PauseCircle as PauseCircleIcon,
	HourglassBottom as HourglassBottomIcon
} from "@mui/icons-material";
import {
	Link
} from "react-router-dom";

const ContentEdit = (props) => {
	const {
		info,
		tableCompound,

		onOpenEditTask,
		onOpenDetailTask
	} = props;
	const classes = useStyles();

	const _getIconStatus = (status) => {
		let Icon = PauseCircleIcon;
		let tooltip = "Ожидает запуска";

		if (['on_queue', 'processing'].includes(status)) {
			Icon = HourglassBottomIcon;
			tooltip = "В процессе генерации"
		}
		if (['error', 'cancel'].includes(status)) {
			Icon = GppBadIcon;
			tooltip = "Возникла ошибка"
		}
		if (['success'].includes(status)) {
			Icon = CheckCircleIcon;
			tooltip = "Успешно"
		}

		return (
			<Tooltip arrow title={tooltip}>
				<Icon sx={{fontSize: 20}}/>
			</Tooltip>
		)
	}
	const _getTableText = (task, _item) => {
		if ((_item?.slug || '').includes('variant_')) {
			const index = Number.parseFloat((_item?.slug || '').replace('variant_', '')) - 1;
			if (!Boolean(task?.response?.[index])) {
				return null
			}
			return <CheckCircleIcon sx={{ fontSize: 18 }}/>
		}
		return (task?.widgets || []).find((t) => t.slug === _item.slug)?.answer
	}

	return (
		<Box className={classes.root}>

			<Typography to="/task-batch" className={classes.goBack} component={Link}>
				<ArrowBackIcon sx={{fontSize: 20, marginRight: 1}}/>
				Создать несколько текстов из файла Excel
			</Typography>

			<Typography variant="h1" sx={{color: '#2C4058', marginTop: 1, wordBreak: "break-all"}}>
				{ info?.filename }
			</Typography>

			<Box className={classes.tableInfoRoot}>
				<Table className={classes.tableInfo}>
					<TableHead>
						<TableRow>
							<TableCell/>
							{tableCompound.map((_item, index) => (
								<TableCell key={`tableCompound-head-${ index }`}>
									{ _item.title }
								</TableCell>
							))}
							<TableCell/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(info.tasks || []).map((task, taskIndex) => (
							<TableRow>
								<TableCell>{_getIconStatus(task.status)}</TableCell>
								{tableCompound.map((_item, index) => (
									<TableCell key={`tableCompound-body-${ task.id }-${ index }`}>
										{_getTableText(task, _item)}
									</TableCell>
								))}
								<TableCell>
									<Grid container wrap="nowrap" spacing={1}>
										<Grid item>
											<IconButton className={classes.tableIconButton} onClick={onOpenDetailTask.bind(this, task, taskIndex)}>
												<VisibilityIcon/>
											</IconButton>
										</Grid>
										<Grid item>
											<IconButton
												className={classes.tableIconButton}
												disabled={Boolean(task.status !== 'created')}
												onClick={onOpenEditTask.bind(this, task, taskIndex)}
											>
												<EditIcon/>
											</IconButton>
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>

		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		width: "100%",
		padding: "24px 20px",
		boxSizing: "border-box",
		background: "white",
		borderRadius: "16px",
		border: "1px solid rgba(0,0,0,0.1)"
	},
	goBack: {
		display: "flex",
		alignItems: "center",

		fontWeight: "500",
		fontSize: "14px",
		lineHeight: "16px",
		leadingTrim: "both",
		textEdge: "cap",
		letterSpacing: "-0.02em",
		color: "#126DDE",
	},

	tableInfoRoot: {
		overflow: "auto",
		"@media(max-width: 640px)": {
			width: "calc(100% + 40px)",
			overflow: "auto",
			margin: "0 -20px",
			padding: "0 20px",
		}
	},
	tableInfo: {
		marginTop: 16,
		borderSpacing: 0,

		border: '1px solid #E1EAF4',
		borderCollapse: 'collapse',

		"& th, td": {
			border: '1px solid #E1EAF4!important',
			borderCollapse: 'collapse',
		},

		"& .MuiTableCell-head": {
			background: "transparent",
			borderRadius: "0!important",
			padding: "8px",

			fontSize: 10,
			fontWeight: "500"
		},
		"& .MuiTableRow-root": {
			boxShadow: "none"
		},
		"& .MuiTableCell-body": {
			padding: "8px",
			borderRadius: "0!important",

			fontSize: 10,
			color: "#2C4058"
		},

		"@media(max-width: 640px)": {
			minWidth: 640
		}
	},
	tableIconButton: {
		width: 32,
		height: 32,
		padding: 0,
		border: "1px solid #E1EAF4",

		"& svg": {
			width: 18,
			height: 18
		}
	},
}))

export default React.memo(ContentEdit)
