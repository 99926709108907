import {combineReducers} from 'redux';
import global from '../states/global';
import directory from '../states/directory';
import wsMessages from '../states/ws-messages';

const rootReducer = combineReducers({
  global,
  directory,
  wsMessages
});

export default rootReducer
