import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Landing as LandingLayout,
  Cabinet as CabinetLayout,
  Authorization as AuthorizationLayout
} from "../layouts";
import {
  Welcome as WelcomePage,
  Registration as RegistrationPage,
  Authorization as AuthorizationPage,
  AccountConfirm as AccountConfirmPage,
  RegistrationConfirm as RegistrationConfirmPage,
  PrivacyPolicy as PrivacyPolicyPage,
  UserAgreement as UserAgreementPage,
  RestorePassword as RestorePasswordPage,
  AccountPasswordReset as AccountPasswordResetPage,
  PersonalData as PersonalDataPage,
} from "../pages";

const pages = [
  {
    path: '/',
    component: WelcomePage,
    layout: LandingLayout,
    rules: [],
    exact: true
  },
  {
    path: '/registration',
    component: RegistrationPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/registration/confirm',
    component: RegistrationConfirmPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/authorization',
    component: AuthorizationPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/account/confirm',
    component: AccountConfirmPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/restore-password',
    component: RestorePasswordPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/account/password/reset',
    component: AccountPasswordResetPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },

  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/user-agreement',
    component: UserAgreementPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/personal-data',
    component: PersonalDataPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
];

const AuthRoute = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export default AuthRoute;
