import React, {Component} from "react";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import {
  ScenariosForm,
  ScenariosLoading,
  DialogWarningEndRate
} from "../../../../components";
import {
  FormResult,
  DialogChangeText
} from "./components";
import {
  NoResult
} from "../TaskCreate/components";
import { Centrifuge } from 'centrifuge';
import agent from "../../../../agent/agent";
import {textParse} from "../../../../helpers/textParse";
import {Notification, notificationTypes} from "../../../../common/Notification";
import yaCounter from "../../../../helpers/yaCounter";
import {makeStyles} from "@mui/styles";

class TaskView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: {},
      taskAnswer: [],
      taskId: props?.match?.params?.id,

      isLoad: true,
      isBackdrop: false,
      isSuccessTask: false,
      isErrorTask: false,
      isCancelTask: false,
      isGenerateMidjourney: false
    };
    this.refDialogChangeText = React.createRef();
    this.refDialogWarningEndRate = React.createRef();
    this.socket = null;
  }

  componentDidMount = async () => {
    await this.getTask();
  }

  getTask = async () => {
    await this.setState({isLoad: true});
    const { taskId } = this.state;
    const res = await agent.get(`/history/scenarios/${ taskId }`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    const scenario = await agent.get(`/scenarios/${ res?.scenario_id }`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })
    const answer = (res?.responses || []).map((t) => {
      return {
        ...t,
        edit: textParse(t.edit || ''),
        orig: textParse(t.orig || ''),
      }
    });
    await this.setState({
      task: res,
      taskAnswer: answer,
      isLoad: false,
      isErrorTask: Boolean(res.status === 'error'),
      isSuccessTask: Boolean(res.status === 'success'),
      isCancelTask: Boolean(res.status === 'cancel'),
      isGenerateMidjourney: Boolean(scenario?.settings?.generate_midjourney)
    });

    if (['on_queue', 'processing'].includes(res.status)) {
      // await this.updateTask();
      await this.startWatchWS();
    }
  }
  updateTask = async () => {
    const { taskId } = this.state;
    const res = await agent.get(`/history/scenarios/${ taskId }`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    if (res.status !== 'success') {
      setTimeout(async () => {
        await this.updateTask();
      }, 1000);
      return
    }

    const answer = (res?.responses || []).map((t) => {
      return {
        ...t,
        edit: textParse(t.edit || ''),
        orig: textParse(t.orig || ''),
      }
    });
    this.setState({
      task: res,
      taskAnswer: answer,
      isErrorTask: Boolean(res.status === 'error'),
      isSuccessTask: Boolean(res.status === 'success'),
      isCancelTask: Boolean(res.status === 'cancel'),
    })
  }
  createTask = async (scenarioId, body, scenario) => {
    await this.setState({ isBackdrop: true });
    const res = await agent.post(`/scenarios/${ scenarioId }/execute/async`, body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });
      if (res?.error?.data?.code === 4318) {
        this.refDialogWarningEndRate.current.open();
        return
      }
      Notification({
        message: res?.error?.data?.message || "Ошибка сервера",
        type: notificationTypes.error
      })
      return
    }
    this.setState({
      isBackdrop: false
    });
    window.location.href = `${window.location.origin}/task/${ res?.id }`;
    yaCounter('create_text_task', {
      'scenario': `${scenario?.title} (ID: ${scenarioId})`
    });
    await this.props.updateSubscriptions();
  }

  startWatchWS = async () => {
    const socket = new Centrifuge([process.env.REACT_APP_HOST_WS, 'ws'].join("/"), {
      token: localStorage.getItem('access_token')
    });

    socket.on('connected', (event) => {console.log('connected event:', event)});
    socket.on('connecting', (event) => {console.log('connecting event:', event)});
    socket.on('message', async (data) => {
      if (data?.data?.event === 'openai:scenario' && data?.data?.data?.user_scenario_id === this.state.task?.id) {
        await this.getTask();
        await this.closeWatchWS();
      }
    });
    socket.connect();

    this.socket = socket;
  }
  closeWatchWS = async () => {
    this.socket.disconnect();
  }

  generateMidjourney = async (prompt) => {
    this.setState({ isBackdrop: true });

    const res = await agent.post('/midjourney/generate', {
      prompt
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      Notification({
        type: notificationTypes.error,
        message: res?.error?.data?.message || "Ошибка сервера"
      })
      return
    }

    const elementLink = document.createElement('a');
    elementLink.target = "_blank";
    elementLink.href = `/midjourney/${res?.id}`;
    this.setState({ isBackdrop: false });
    elementLink.click();
  }

  changeTextTask = async (activeIndexText, newText) => {
    if (!newText) {
      this.refDialogChangeText.current.open({
        index: activeIndexText,
        onSubmit: this.changeTextTask.bind(this)
      });
      return
    }
    this.setState({ isBackdrop: true });
    const res = await agent.put(`/history/scenarios/${ this.state.taskId }/responses/${ activeIndexText }`, {
      value: newText
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    })
    if (res?.error) {
      Notification({
        type: notificationTypes.error,
        message: "Возникла ошибка при редактировании, повторите попытку позднее"
      })
      return
    }
    this.refDialogChangeText.current.close();
    await this.getTask();
    this.setState({ isBackdrop: false });
  }

  render () {
    const {
      task,
      taskAnswer,
      isLoad,
      isBackdrop,
      isSuccessTask,
      isErrorTask,
      isCancelTask,
      isGenerateMidjourney
    } = this.state;

    if (isLoad) {
      return null
    }
    return (
      <Grid container spacing={2}  sx={{flex: 1}}>
        <Grid item xs={12} md={5} sx={{flex: 1, display: "flex", flexDirection: "column"}}>
          <ScenariosForm
            initTask={task}
            onSubmit={this.createTask}
          />
        </Grid>
        <Grid item xs={12} md={7} sx={{display: "flex"}}>
          <VisibleContent visible={Boolean(isSuccessTask)}>
            <FormResult
              task={task}
              answer={taskAnswer}
              isGenerateMidjourney={isGenerateMidjourney}

              onEditText={this.changeTextTask}
              onGenerateMidjourney={this.generateMidjourney}
            />
          </VisibleContent>
          <VisibleContent visible={Boolean(isErrorTask)}>
            <MessageInfoContent
              message="Произошла ошибка при выполнении запроса.<br/>Повторите попытку позднее."
              status="error"
            />
          </VisibleContent>
          <VisibleContent visible={Boolean(isCancelTask)}>
            <MessageInfoContent
              message="Ваша задача была отменена.<br/>Повторите попытку позднее."
              status="error"
            />
          </VisibleContent>
          <VisibleContent visible={!Boolean(isSuccessTask || isErrorTask || isCancelTask)}>
            <NoResult loading={true}/>
          </VisibleContent>
        </Grid>


        <ScenariosLoading open={isBackdrop || !Boolean(isSuccessTask || isErrorTask || isCancelTask)}/>
        <DialogChangeText
          ref={this.refDialogChangeText}
          answers={taskAnswer}
        />
        <DialogWarningEndRate
          ref={this.refDialogWarningEndRate}
        />
      </Grid>
    );
  }
}
const VisibleContent = React.memo(({ visible, children }) => {
  if (!visible) {
    return null
  }
  return children
});
const MessageInfoContent = React.memo(({ message, status }) => {
  const classes = useStyles();
  return (
    <Box className={classes.messageInfoContent}>
      <Typography
        className={classes.messageInfoContentMessage}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Box>
  )
})

const useStyles = makeStyles(() => ({
  messageInfoContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    flex: 1,
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    textAlign: "center",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: 24,
    boxSizing: "border-box"
  },
  messageInfoContentMessage: {
    color: "#2C4058",
    fontSize: "20px",
    lineHeight: "135%",
    letterSpacing: "-0.32px",
  }
}));

export default TaskView
