import React, {useState} from 'react';
import s from './answers.module.css';
import {Container} from "@mui/material";
import whitePlus from '../../../../../assets/image/welcome/plus-white.svg';
import whiteMinus from '../../../../../assets/image/welcome/minus-white.svg';

const info = [
    {id: 1, title: 'Что у вас за сервис?', text: 'Наш сервис предназначен для создания и улучшения текстов и изображений с использованием модели искусственного интеллекта, разработанной OpenAI, которая способна генерировать текстовые ответы на основе предоставленных ей запросов.'},
    {id: 2, title: 'Какие возможности предоставляет сервис для работы с текстом?', text: 'Сервис предоставляет широкий спектр возможностей для работы с текстом. Он может отвечать на вопросы, объяснять понятия, предоставлять информацию, выполнить задачу по запросу и многое другое. Он также может генерировать текстовые описания, писать статьи или помогать в составлении текстов. С нашим сервисом вы можете решать своих личных и рабочие задачи.'},
    {id: 3, title: 'Какую точность и качество ответов можно ожидать от данного сервиса?', text: 'Сервис основан на базе Open AI, который создан для предоставления качественных ответов, однако его точность может варьироваться в зависимости от сложности запроса и доступности информации. Он может предоставить полезные и информативные ответы, но иногда могут возникать неточности или неполные ответы. Контроль качества и обратная связь пользователей помогают улучшить работу нашего сервиса.'},
    {id: 4, title: 'Какие языковые или технические ограничения существуют при использовании вашего сервиса?', text: 'Сервис поддерживает большое количество языков и может обрабатывать текстовые запросы на разных языках. Однако, при использовании определенного языка могут существовать некоторые ограничения в качестве ответов или в доступности специфической информации на этом языке.'},
    {id: 5, title: 'Каким образом сервис обрабатывает и хранит пользовательские запросы и данные?', text: 'Пользовательские запросы обрабатываются на Open AI и могут быть сохранены временно для обеспечения непрерывности диалога. Однако OpenAI не использует эти данные для улучшения модели. Пользовательская информация обычно не хранится длительное время и подчиняется политике конфиденциальности.'},
    {id: 7, title: 'В чем преимущества автоматического написания статей?', text: 'Преимуществами автоматического написания статей сервисом являются быстрота обработки, высокая уникальность текста, оптимизация под SEO и экономия времени автора.'},
    {id: 8, title: 'Может ли сервис улучшить уже написанный мною текст?', text: 'Да, сервис может улучшить написанный вами текст, например, исправив грамматические ошибки, улучшив структуру предложений или оптимизировав текст под SEO.'},
    {id: 9, title: 'Возможно ли сократить текст с помощью сервиса?', text: 'Да, сервис может подобрать сокращения, убрать ненужное, но при этом сохранить основной смысл текста.'},
    {id: 10, title: 'Как создать рекламное объявление используя сервис?', text: 'Чтобы создать рекламное объявление с помощью AI, вам нужно лишь указать ключевые слова и общий смысл вашего объявления.'},
]

const Answers = () => {
    const [activeItemId, setActiveItemId] = useState(null);

    const onChangeActiveId = (id) => {
        if(activeItemId === id) {
            setActiveItemId(null);
        }else {
            setActiveItemId(id)
        }
    }

    return (
        <div  id='answers' className={s.answers}>
            <Container>
                <div className='welcome-title'>Вопросы и ответы</div>
                {info.map(item => {
                    return  (
                        <div key={item.id} className={s.item}>
                            <div className={s.itemWrapper}>
                                <h3>{item.title}</h3>
                                <div className={s.showMore} onClick={() => onChangeActiveId(item.id)}>
                                    <img src={activeItemId === item.id ? whiteMinus : whitePlus} alt="plus"/>
                                </div>
                            </div>
                            {activeItemId === item.id ? (
                                <div className={s.text}>
                                    {item.text}
                                </div>
                            ): null}
                        </div>
                    )
                })}
            </Container>
        </div>
    );
};

export default Answers;
