import React, {useContext} from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Backdrop,
	Container,
	Typography,
	CircularProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {Link} from "react-router-dom";
import {
	Notification,
	notificationTypes
} from "../../../../../common/Notification";
import {useSelector} from "react-redux";
import s from './pricing.module.css';
import agent from "../../../../../agent/agent";
import currency from "../../../../../constants/currency.json";
import moment from "moment";
import queryString from "query-string";
import _TariffCard from "./TariffCard";

const Pricing = () => {
	const [tariffs, setTariffs] = React.useState([]);
	const [isBackdrop, setBackdrop] = React.useState(false);
	const [isFullCards, setFullCards] = React.useState(false);
	const [isDialogSelectType, setDialogSelectType] = React.useState(null);

	React.useEffect(() => {
		(async () => {
			await getTariffs();
		})();
	}, []);

	const getTariffs = async () => {
		const res = await agent.get(`/tariffs?limit=20&offset=0&enabled=true`).then((res) => {
		  return (res.data?.tariffs || []).filter((t) => t.type !== 'promo')
		}).catch(() => {
		  return []
		});
		setTariffs(res);
		await checkSearchBuy(res);
	}
	const buyTariffs = async (tariff, type) => {
		if (!type) {
			setDialogSelectType(tariff);
			return
		}

		setBackdrop(true);
		setDialogSelectType(null);
		const redirectUrl = await agent.post(`/tariffs/${tariff.id}/subscribe`, {
			method: type
		}).then((res) => {
			return res.data?.redirect_url
		}).catch((err) => {
			return {error: err.response?.data?.message}
		});
		if (redirectUrl.error) {
			setBackdrop(false);
			Notification({
				message: redirectUrl.error || "Ошибка получения ссылки на оплату, обратитесь к администраторам.",
				type: notificationTypes.error
			})
			return
		}
		window.location.href = redirectUrl;
	}

	const checkSearchBuy = async (_items) => {
		const searchObject = queryString.parse(window.location.search || '');
		if (!searchObject?.buy) {
			return null
		}

		const tariff = _items.find((t) => t.id === searchObject?.buy);
		if (!tariff) {
			return null
		}

		await buyTariffs(tariff, '')
	}

	return (
		<div id='pricing' className={s.pricing}>
			<Container>
				<div className='welcome-title'>Тарифы</div>
				<div className={s.items}>
					{Boolean(false) && (
						<div className={s.item}>
							<div className={s.itemBackground}></div>
							<h3>Бесплатный</h3>
							<div className={s.itemText}>
								<div>Делайте до 5 текстовых запросов и создавайте до 25 страниц текста в течение 14 дней.</div>
								<div>Используйте более 20 сервисов по работе с текстами</div>
								<div>Выбирайте любые из 20+ тонов</div>
								<div>Выполняйте встроенную проверку на плагиат</div>
								<div>Создавайте до 2 изображений в течение 14 дней с помощью ИИ</div>
							</div>
							<Link
								to="/registration"
								className={`welcome-btn welcome-btn-sm ${s.btn}`}
							>
								Зарегистрироваться
							</Link>
						</div>
					)}
					{tariffs.map(item => <TariffCard key={`TariffCard-${item.id}`} data={item} onBuy={buyTariffs}/>)}
				</div>
			</Container>
			<Backdrop open={isBackdrop}>
				<CircularProgress/>
			</Backdrop>
			{Boolean(isDialogSelectType) && (
				<DialogSelectType
					onSelect={buyTariffs.bind(this, isDialogSelectType)}
					onClose={() => setDialogSelectType(null)}
				/>
			)}
		</div>
	);
};
const TariffCard = React.memo((props) => {
	const {
		data,
		onBuy
	} = props;
	const classes = useStyles();
	const price = data?.prices?.[0] || {};
	const profile = useSelector(context => context?.global?.userProfile);

	const _period = () => {
		const duration = (data?.promo_duration / 1000000000);
		const _val = moment().add(duration, 's');
    if ((_val.month() - moment().month() > 0) || duration === 2592000) {
			return 'За месяц'
		}
		if (_val.day() - moment().day() > 0 || duration === 86400) {
			return 'За день'
		}
		return _val.format("DD.MM.YYYY")
	}
	const _textPrice = () => {
		if (price?.original_cost && price?.original_cost > price.cost) {
			return `Стоимость <span>${price.original_cost} ${currency[price.currency]}</span> ${price.cost} ${currency[price.currency]}`
		}
		return `Стоимость ${price.cost} ${currency[price.currency]}`
	}

	return (
		<div className={s.item}>
			<div className={s.itemBackground}></div>
			<div className={s.itemTime}>{_period()}</div>
			<h3>{data.name}</h3>
			<div
				className={s.itemText}
				dangerouslySetInnerHTML={{
					__html: data.description
				}}
			/>

			{Boolean(profile) && (
				<button
					onClick={onBuy.bind(this, data, null)}
					className={`welcome-btn welcome-btn-sm ${s.btn}`}
					dangerouslySetInnerHTML={{__html: _textPrice()}}
				/>
			)}
			{Boolean(!profile) && (
				<Link
					to="/authorization"
					className={`welcome-btn welcome-btn-sm ${s.btn}`}
					dangerouslySetInnerHTML={{__html: _textPrice()}}
				/>
			)}
		</div>
	)
})
const DialogSelectType = React.memo((props) => {
	const classes = useStyles();
	return (
		<Dialog
			open={true}
			fullWidth
			maxWidth="xs"
			onClose={props.onClose}
		>
			<DialogTitle>
				<Typography variant="h3">Выбор способа оплаты</Typography>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={4} mt="-10px">
					<Grid item xs={12}>
						<Box className={classes.paymentCard} onClick={props.onSelect.bind(this, 'smart')}>
							<img src={require('../../../../../assets/image/payments/yookassa.svg').default} style={{maxHeight: 42}}/>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box className={classes.paymentCard} onClick={props.onSelect.bind(this, 'sbp')}>
							<img src={require('../../../../../assets/image/payments/sbp.svg').default} style={{maxHeight: 64}}/>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	)
})

const useStyles = makeStyles(() => ({
	paymentCard: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer"
	}
}));

export default Pricing;
export {
	TariffCard,
	DialogSelectType
}
