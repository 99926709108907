import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Typography
} from "@mui/material";
import {
	CKEditor
} from "../../../../../../components";

class DialogChangeText extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: "",
			index: -1,
			isOpen: false
		}
	}

	open = ({ index, onSubmit }) => {
		const response = (this.props?.answers || []).find((t) => t.index === index);
		const value = response?.edit || response?.orig || "";
		this.setState({
			value,
			index,
			onSubmit,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}

	onSubmit = () => {
		const { value, index, onSubmit } = this.state;
		onSubmit(index, value);
	}
	onChange = ({ target }) => {
		this.setState({
			value: target?.value || ""
		})
	}

	render () {
		const {
			value,
			isOpen
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="lg"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">Изменение текста</Typography>
				</DialogTitle>
				<DialogContent>
					<CKEditor
						value={value}
						hideControls={true}
						onChange={this.onChange}
					/>
				</DialogContent>
				<DialogActions>
					<Box px={2} pb={2}>
						<Grid container spacing={1} justifyContent="flex-end">
							<Grid item>
								<Button variant="outlined" onClick={this.close}>
									Отменить
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" onClick={this.onSubmit}>
									Сохранить
								</Button>
							</Grid>
						</Grid>
					</Box>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DialogChangeText