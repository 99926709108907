import React from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  TextField,
  Typography
} from "@mui/material";
import {
  Help as HelpIcon,
  ContentCopy as ContentCopyIcon,
  ContentPaste as ContentPasteIcon,
  QuestionMark as QuestionMarkIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {
  makeStyles
} from "@mui/styles";
import {
  copyText, pastText
} from "../../helpers/clipboard";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {palette} from "../../theme/common";

const ElementInput = (props) => {
  const {
    value,
    settings,
    onChange,

    ...other
  } = props;
  const classes = useStyles();
  const [isBigInput, setBigInput] = React.useState(false);

  const handleCopyValue = async () => {
    const isSuccess = await copyText(value);
    Notification({
      type: Boolean(isSuccess) ? notificationTypes.success : notificationTypes.error,
      message: Boolean(isSuccess) ? 'Текст успешно скопирован' : 'Ошибка копирования текста'
    })
  }
  const handlePastValue = async () => {
    const text = await pastText();
    if (!text) {
      return
    }
    onChange({
      target: {
        name: props.slug,
        value: text
      }
    });
  }

  return (
    <Box>
      <HeadElement
        clue={settings?.clue}
        label={settings?.label}
        helpMessage={settings?.help_message}
      />

      <TextField
        {...other}

        value={value}
        name={props.slug}
        placeholder={settings?.placeholder}
        fullWidth={true}
        multiline={Boolean(settings?.multiple)}
        rows={isBigInput ? null : 4}
        minRows={isBigInput ? 10 : null}
        onChange={onChange}
      />

      <FooterElement
        isFull={isBigInput}
        isMultiple={Boolean(settings?.multiple)}

        onCopy={handleCopyValue}
        onPast={handlePastValue}
        onChangeFull={(val) => setBigInput(val)}
      />
    </Box>
  )
}
const HeadElement = React.memo((props) => {
  const {
    clue,
    label,
    helpMessage,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.headElement}>
      <div className="--title">{ label }</div>
      {Boolean(!!clue) && (
        <div className="--clue"> { clue }</div>
      )}
      {Boolean(!!helpMessage) && (
        <Tooltip title={(<React.Fragment><span dangerouslySetInnerHTML={{__html: helpMessage}}/></React.Fragment>)} arrow>
          <Box className="--help">
            <QuestionMarkIcon/>
          </Box>
        </Tooltip>
      )}
    </Box>
  )
});
const FooterElement = React.memo((props) => {
  const {
    isFull,
    isMultiple,

    onCopy,
    onPast,
    onChangeFull
  } = props;
  const classes = useStyles();
  const KeyboardArrowIcon = Boolean(isFull) ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <Box className={classes.footerElements}>
      <div className="--left">
        <Grid container spacing="4px">
          <Grid item>
            <Button className={classes.buttonAction} onClick={onCopy}>
              <span>Копировать</span>
              <ContentCopyIcon/>
            </Button>
          </Grid>
          <Grid item>
            <Button className={classes.buttonAction} onClick={onPast}>
              <span>Вставить</span>
              <ContentPasteIcon/>
            </Button>
          </Grid>
        </Grid>
      </div>
      {Boolean(isMultiple) && (
        <div className="--right">
          <Button className={classes.buttonAction} onClick={onChangeFull.bind(this, !isFull)}>
            <span>{isFull ? 'Свернуть' : 'Развернуть'}</span>
            <KeyboardArrowIcon/>
          </Button>
        </div>
      )}
    </Box>
  )
});

const useStyles = makeStyles(() => ({
  buttonMore: {
    marginTop: "10px",
    marginLeft: "auto",
    padding: "4px 15px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400",
  },
  helpMessageTooltip: {
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'left',
  },

  headElement: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 8,

    "& .--title": {
      display: "contents",
      color: "#334A67",
      fontFamily: "Suisse Intl",
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "0.6px",
      textTransform: "uppercase",
    },
    "& .--clue": {
      display: "contents",
      marginLeft: 4,

      color: "#334A67",
      fontFamily: "Suisse Intl",
      fontSize: "8px",
      lineHeight: "8px",
      letterSpacing: "0.4px",
      textTransform: "uppercase",
    },
    "& .--help": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      width: 20,
      minWidth: 20,
      height: 20,
      minHeight: 20,
      borderRadius: "100%",
      background: palette.primary.main,
      marginLeft: "auto",

      "& svg": {
        fontSize: 14,
        color: "white"
      }
    },
  },
  footerElements: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    "& .--right": {
      marginLeft: 4
    },
    "@media(max-width: 639px)": {
      justifyContent: "flex-end"
    }
  },
  buttonAction: {
    marginTop: "4px",
    marginLeft: "auto",
    padding: "4px 10px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400",

    "& svg": {
      marginLeft: 4,
      fontSize: 16
    },
    "@media(max-width: 639px)": {
      width: 32,
      height: 32,
      minWidth: "initial",
      padding: 0,
      borderRadius: 100,
      "& svg": {
        fontSize: 18,
        marginLeft: 0
      },
      "& span": {
        display: "none"
      }
    }
  },
}));

export default React.memo(ElementInput)
export {
  HeadElement,
  FooterElement
}
