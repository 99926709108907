// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Saved from './Saved';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Saved);
