import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import agent from "../../agent/agent";
import moment from "moment/moment";
import {withRouter} from "react-router-dom";
import {DialogSelectType} from "../../pages/auth/Welcome/components/Pricing/Pricing";
import {Notification, notificationTypes} from "../../common/Notification";

class DialogWarningEndRate extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			isOpen: false,
			isBackdrop: false,

			itemBuyTariff: null
		};
	}

	componentDidMount = async () => {
		await this.getItems();
	}

	open = async () => {
		await this.setState({ isOpen: true });
		if (this.state.items.length > 0) {
			return
		}
		await this.getItems();
	}
	close = async () => {
		this.setState({
			isOpen: false
		})
	}

	getItems = async () => {
		const res = await agent.get(`/tariffs?limit=20&offset=0&enabled=true`).then((res) => {
			return (res.data?.tariffs || []).filter((t) => t.type !== 'promo')
		}).catch(() => {
			return []
		});
		this.setState({ items: res })
	}

	buyTariff = async (paymentType) => {
		this.setState({ isBackdrop: true });
		const { itemBuyTariff } = this.state;
		const redirectUrl = await agent.post(`/tariffs/${itemBuyTariff.id}/subscribe`, {
			method: paymentType,
			return_url: window.location.href
		}).then((res) => {
			return res.data?.redirect_url
		}).catch((err) => {
			return {error: err.response?.data?.message}
		});
		if (redirectUrl.error) {
			this.setState({ isBackdrop: false });
			Notification({
				message: redirectUrl.error || "Ошибка получения ссылки на оплату, обратитесь к администраторам.",
				type: notificationTypes.error
			})
			return
		}
		this.closeSelectPayment();
		window.location.href = redirectUrl;
		this.setState({ isBackdrop: false });
	}
	clickTariff = (itemBuyTariff) => {
		this.setState({ itemBuyTariff });
	}
	closeSelectPayment = () => {
		this.setState({ itemBuyTariff: null });
	}
	buyItem = async (item, typePayment) => {
		if (!typePayment) {
			return
		}

		console.log('item: ', item);
		return

		this.close();
		window.location.href = `/tariff`;
	}

	render() {
		const {
			items,
			isOpen,
			isBackdrop,
			itemBuyTariff
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<>
				<Dialog
					open={isOpen}
					fullWidth
					maxWidth="md"
				>
					<DialogTitle className={classes.dialogTitle}>
						<Typography variant="h3">Предупреждение</Typography>
					</DialogTitle>
					<DialogContent>
						<Typography className={classes.message}>
							У вас закончились доступные запросы. Чтобы продолжить использовать нашу платформу и выполнить вашу задачу,
							вы можете приобрести один из наших тарифов, описанных ниже:
						</Typography>
						<Grid container spacing={2}>
							{items.map((item) => (
								<Grid item xs={12}>
									<TariffCard
										item={item}
										onBuy={this.clickTariff.bind(this, item)}
									/>
								</Grid>
							))}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Box py={2}>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Button variant="outlined" onClick={this.close}>Закрыть</Button>
								</Grid>
							</Grid>
						</Box>
					</DialogActions>
				</Dialog>


				{Boolean(itemBuyTariff) && (
					<DialogSelectType
						onSelect={this.buyTariff}
						onClose={this.closeSelectPayment}
					/>
				)}

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		)
	}
}
const TariffCard = React.memo((props) => {
	const { item, onBuy } = props;
	const classes = useStyles();
	const price = (item?.prices?.[0]?.cost || '');

	const _period = () => {
		const duration = (item?.promo_duration / 1000000000);
		const _val = moment().add(duration, 's');
		if (_val.month() - moment().month() > 0) {
			return 'За месяц'
		}
		if (_val.day() - moment().day() > 0) {
			return 'За день'
		}
		return _val.format("DD.MM.YYYY");
	}

	return (
		<Box className={classes.tariffCard}>
			<Typography
				className={classes.tariffCardTitle}
				dangerouslySetInnerHTML={{ __html: item.name }}
			/>
			<Typography
				className={classes.tariffCardMessage}
				dangerouslySetInnerHTML={{ __html: item.description }}
			/>
			<Typography className={classes.tariffCardPrice}>
				Стоимость {price} ₽ <span>({_period()})</span>
			</Typography>
			<Button
				variant="contained"
				className={classes.tariffCardButton}
				onClick={onBuy}
			>
				Приобрести
			</Button>
		</Box>
	)
})

const useStyles = makeStyles(() => ({
	tariffCard: {
		borderRadius: "20px",
		background: "#FFF",
		boxShadow: "-1px 5px 12px 0px rgba(15, 68, 138, 0.05), -3px 21px 22px 0px rgba(15, 68, 138, 0.04), -7px 48px 29px 0px rgba(15, 68, 138, 0.03), -13px 85px 35px 0px rgba(15, 68, 138, 0.01), -20px 134px 38px 0px rgba(15, 68, 138, 0.00)",
		backdropFilter: "blur(40px)",
		padding: 20
	},
	tariffCardTitle: {
		color: "#254164",
		fontSize: "16px",
		fontWeight: "500",
		lineHeight: "130%",
		letterSpacing: "-0.48px",
	},
	tariffCardMessage: {
		marginTop: 10,

		color: "#71869F",
		fontSize: "14px",
		lineHeight: "130%",
		letterSpacing: "-0.3px",

		"& p": {
			borderBottom: "1px solid #C5D0DE",
			marginBottom: "8px",
			paddingBottom: "8px",
			"&:last-child": {
				marginBottom: "0px",
				paddingBottom: "0px",
				borderBottom: "none"
			}
		}
	},
	tariffCardPrice: {
		marginTop: 15,

		color: "#126DDE",
		fontSize: "15px",
		fontWeight: "500",
		letterSpacing: "-0.3px",

		"& span": {
			color: "#71869F"
		}
	},
	tariffCardButton: {
		marginTop: 5,
		padding: "5px 15px",
		borderRadius: "5px",
		fontSize: "14px",
		lineHeight: "16px",
	}
}));

const style = {
	dialogTitle: {
		padding: "16px 20px",
		"& .MuiTypography-root": {
			textAlign: "left"
		}
	},
	message: {
		marginBottom: 20,

		color: "#254164",
		fontSize: "16px",
		lineHeight: "130%",
		letterSpacing: "-0.42px",
	}
};
DialogWarningEndRate = withStyles(style)(DialogWarningEndRate);

export default DialogWarningEndRate
