import React from "react";
import {
  Box,
  Grid,
  Select,
  Button,
  Divider,
  MenuItem,
  Typography,
  FormControl,
  FormHelperText, useMediaQuery,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import variants from "./variants.json";
import modelSize from "./modelSize.json";
import {compose} from "redux";
import {connect} from "react-redux";
import agent from "../../agent/agent";
import ElementInput from "./ElementInput";
import ElementSelect from "./ElementSelect";
import SettingsPc from "./SettingsPc";
import SettingsMobile from "./SettingsMobile";
import {Notification, notificationTypes} from "../../common/Notification";
import queryString from "query-string";
import {caseWords} from "../../helpers/caseWords";
import TabsTypeScenarios from "./TabsTypeScenarios";
import clsx from "clsx";

const initState = {
  language: "",
  tone: "",
  scenario: "",
  number_of_options: "",
  creativity: "",
  size: "",
  ai_type_id: ""
};
const initValidationScheme = {
  language: Yup.string().required('Обязательно к заполнению'),
  tone: Yup.string().required('Обязательно к заполнению'),
  scenario: Yup.string().required('Обязательно к заполнению'),
  number_of_options: Yup.string().required('Обязательно к заполнению'),
  creativity: Yup.string().required('Обязательно к заполнению'),
  // size: Yup.string().required('Обязательно к заполнению'),
};

class ScenariosForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initState},
      aiModels: [],
      scenario: null,
      validationScheme: Yup.object().shape({...initValidationScheme})
    };
    this.refFormik = React.createRef();
  }

  componentDidMount = async () => {
    if (Object.keys(this.props.initTask || {}).length > 0) {
      await this.initFormFromTask();
      return
    }
    await this.initForm();
  }

  changeScenario = async ({ target }) => {
    const { value } = target;
    const res = await agent.get(`/scenarios/${ value }`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const aiModels = res?.ai_models || [];

    let form = this.refFormik.current.values;
    let validationScheme = {...initValidationScheme};
    (res?.widget || []).map((widget) => {
      form[widget.slug] = form[widget.slug] || "";
      validationScheme[widget.slug] = this._validationSchemeWidget(widget);
    });
    if (aiModels.length > 0) {
      const modelDefault = (aiModels || []).find((t) => Boolean(t.default));
      const modelFree = (aiModels || []).find((t) => (t?.ai_type?.model || '').includes('gpt-3.5'));

      const lastUseGpt4 = Boolean(localStorage.getItem('last-use-gpt-4'));
      let ai_type_id = modelDefault?.ai_type?.id || modelFree?.ai_type?.id || aiModels?.[0]?.ai_type?.id;
      if (lastUseGpt4) {
        ai_type_id = (aiModels || []).find((t) => (t?.ai_type?.model || '').includes('gpt-4'))?.ai_type?.id || ai_type_id;
      }
      form.ai_type_id = ai_type_id;
      validationScheme.ai_type_id = Yup.string().required('Обязательно к заполнению')
    }
    validationScheme = Yup.object().shape(validationScheme);

    await this.changeForm({ target });
    await this.setState({
      scenario: res,
      aiModels: aiModels,
      validationScheme
    });

    let newUrl = [
      window.location.pathname,
      `scenario-id=${ value }`
    ].join("?");
    window.history.replaceState(null, null, newUrl);

    this.refFormik.current.setValues(form);
  }

  initFormFromTask = async () => {
    const {
      initTask,
      tones,
      languages,
      scenarios,
      creativity
    } = this.props;
    const widgets = initTask?.widgets || [];
    const toneValue = (tones || []).find((t) => t.value === widgets.find((t) => t.slug === "tone")?.answer)?.id;
    const languageValue = (languages || []).find((t) => t.name === widgets.find((t) => t.slug === "language")?.answer)?.code;
    const creativityValue = (creativity || []).find((t) => t.value === widgets.find((t) => t.slug === "creativity")?.answer)?.id;

    let form = {...initState};
    form.tone = toneValue || tones?.[0]?.id;
    form.language = languageValue || languages?.[0]?.code;
    form.creativity = creativityValue || creativity?.[0]?.id;
    form.scenario = (scenarios || []).find((t) => t.id === initTask.scenario_id)?.id || scenarios?.[0]?.id;
    form.number_of_options = (initTask?.response || []).length || initTask.number_of_options;

    [...widgets]
      .filter((t) => !['language', 'tone', 'creativity'].includes(t.slug))
      .map((t) => {
        form[t.slug] = t.answer;
      });

    this.refFormik.current.setValues(form);
    await this.changeScenario({target: {value: form.scenario}})
  }
  initForm = async () => {
    const {
      tones,
      languages,
      scenarios,
      creativity
    } = this.props;

    let form = {...initState};
    form.tone = (tones || []).find((t) => t.default === true)?.id || tones?.[0]?.id;
    form.creativity = (creativity || []).find((t) => t.default === true)?.id || creativity?.[0]?.id;
    form.language = (languages || []).find((t) => t.default === true)?.code || languages?.[0]?.code;
    form.number_of_options = (variants?.options || []).find((t) => t.default === true)?.value || variants?.options?.[0]?.value;

    const searchString = window.location.search;
    const searchObject = queryString.parse(searchString);
    form.scenario = searchObject?.['scenario-id'] || (scenarios || []).find((t) => t.default === true)?.id || scenarios?.[0]?.id;

    this.refFormik.current.setValues(form);
    await this.changeScenario({target: {value: form.scenario}})
  }
  changeForm = ({ target }) => {
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  submitTask = (form) => {
    const { scenario, aiModels } = this.state;
    let formSubmit = {};

    // formSubmit.max_tokens = Number(0);
    formSubmit.number_of_options = Number(form.number_of_options);
    formSubmit.widgets = [];
    (scenario?.widget || []).map((widget) => {
      formSubmit.widgets.push({
        slug: widget.slug,
        answer: form[widget.slug]
      })
    });

    if (!!form.language) {
      formSubmit.widgets.push({
        slug: "language",
        answer: (this.props?.languages || []).find((t) => t.code === form.language)?.name
      })
    }
    if (!!form.tone) {
      formSubmit.widgets.push({
        slug: "tone",
        answer: (this.props?.tones || []).find((t) => t.id === form.tone)?.value
      })
    }
    if (!!form.creativity) {
      formSubmit.widgets.push({
        slug: "creativity",
        answer: (this.props?.creativity || []).find((t) => t.id === form.creativity)?.value
      })
    }
    if (!!form.ai_type_id) {
      formSubmit.ai_type_id = form.ai_type_id;
    }

    const useAiModels = (aiModels || []).find((t) => t?.ai_type?.id === form.ai_type_id);
    const isUseAiModels = Boolean((useAiModels?.ai_type?.model || '') === 'GPT-4o');
    if (isUseAiModels) {
      localStorage.setItem('last-use-gpt-4', 1);
    } else {
      localStorage.removeItem('last-use-gpt-4');
    }

    this.props.onSubmit(scenario.id, formSubmit, scenario);
  }

  _renderElementWidget = (widget, value, error, helperText) => {
    if (widget.type === "question") {
      return <ElementInput
        value={value}
        {...widget}
        error={error}
        helperText={helperText}
        onChange={this.changeForm}
      />
    }
    if (widget.type === "select") {
      return <ElementSelect
        value={value}
        {...widget}
        error={error}
        helperText={helperText}
        onChange={this.changeForm}
      />
    }
    return (
      <Typography>Неопознаный элемент</Typography>
    )
  }
  _validationSchemeWidget = (widget) => {
    let val = Yup.string();
    if (widget.required) {
      val = val.required("Обязательно к заполнению");
    }
    return val
  }
  _messageNumberOfOptions = (values) => {
    const { aiModels } = this.state;
    const { subscriptions } = this.props;

    const aiModel = aiModels.find((t) => t?.ai_type?.id === values.ai_type_id);
    const isUseGpt4 = Boolean((aiModel?.ai_type?.model || '').includes('gpt-4-'));
    const _subscriptions = [...subscriptions].filter((t) => {
      if (isUseGpt4 && t.can_gpt4) {
        return true
      }
      if (!isUseGpt4) {
        return true
      }
      return false
    });

    const initRequestsCu = aiModel?.requests_cu || 1;
    const requestsCu = initRequestsCu * values.number_of_options;
    const totalCount = _subscriptions.reduce((val, item) => {
      return val + (item.limits.limit.scenario - item.limits.used.scenario)
    }, 0);

    let remainder = totalCount - requestsCu;
    if (remainder <= 0) {
      remainder = 0;
    }

    if (isUseGpt4) {
      const totalCountGpt4 = Math.floor(totalCount / initRequestsCu) - values.number_of_options;

      const totalWord = caseWords(totalCountGpt4, ['запрос', 'запроса', 'запросов']);
      const requestsCuWord = caseWords(values.number_of_options, ['запрос', 'запроса', 'запросов']);
      return `Спишем ${values.number_of_options} ${requestsCuWord}.<br/>Останется  ${ totalCountGpt4 } ${totalWord}`
    }

    const totalWord = caseWords(remainder, ['запрос', 'запроса', 'запросов']);
    const requestsCuWord = caseWords(requestsCu, ['запрос', 'запроса', 'запросов']);
    return `Спишем ${requestsCu} ${requestsCuWord}.<br/>Останется  ${ remainder } ${totalWord}`
  }

  render () {
    const {
      classes,
      subscriptions,
      labelButtonSubmit
    } = this.props;
    const {
      form,
      scenario,
      aiModels,
      validationScheme
    } = this.state;

    return (
      <Box className={clsx([classes.root, 'scenarios-form-root'])}>
        <Formik
          innerRef={this.refFormik}
          initialValues={form}
          validationSchema={validationScheme}
          onSubmit={this.submitTask}
        >{(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit
          } = props;

          return (
            <>
              <TabsTypeScenarios value="single"/>

              <Grid container className="scenarios-form-select-scenario">
                <Grid item xs={12}>
                  <Typography variant="formLabel" mb="8px">Выберите варианты использования</Typography>
                  <FormControl fullWidth error={Boolean(touched.scenario && errors.scenario)}>
                    <Select
                      value={values.scenario}
                      name="scenario"
                      onChange={this.changeScenario}
                    >
                      {this.props.scenarios.map((scenario) => (
                        <MenuItem key={`scenarios-${scenario.id}`} value={scenario.id}>
                          {scenario.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(touched.scenario && errors.scenario) && (
                    <FormHelperText error={true}>{touched.scenario && errors.scenario}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Divider className={classes.divider}/>
              <Box>
                <Grid container spacing={{ md: "25px", xs: "10px" }}>
                  {(scenario?.widget || []).map((widget) => (
                    <Grid item xs={12}>
                      {this._renderElementWidget(widget, values[widget.slug], Boolean(touched[widget.slug] && errors[widget.slug]), touched[widget.slug] && errors[widget.slug])}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Divider className={classes.divider}/>

              <Box className="scenarios-form-spacing-component"/>

              <VisibleContent visible={useMediaQuery('(min-width: 640px)')}>
                <SettingsPc
                  values={values}
                  errors={errors}
                  touched={touched}
                  aiModels={aiModels}
                  tones={this.props.tones}
                  subscriptions={subscriptions}
                  languages={this.props.languages}
                  creativity={this.props.creativity}
                  onChange={this.changeForm}
                  onMessageNumberOfOptions={this._messageNumberOfOptions(values)}
                />
              </VisibleContent>
              <VisibleContent visible={useMediaQuery('(max-width: 639px)')}>
                <SettingsMobile
                  values={values}
                  errors={errors}
                  touched={touched}
                  aiModels={aiModels}
                  tones={this.props.tones}
                  subscriptions={subscriptions}
                  languages={this.props.languages}
                  creativity={this.props.creativity}
                  onChange={this.changeForm}
                  onMessageNumberOfOptions={this._messageNumberOfOptions(values)}
                />
              </VisibleContent>

              <Button
                variant="contained"
                sx={{marginTop: "24px"}}
                fullWidth
                onClick={handleSubmit}
              >
                {labelButtonSubmit || 'Создать'}
              </Button>
            </>
          )
        }}</Formik>
      </Box>
    )
  }
}
const VisibleContent = React.memo(({ visible, children }) => {
  if (!visible) {
    return null
  }
  return children
})

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: "24px 20px",

    "@media(max-width: 1199px)": {
      padding: "20px 16px",
    }
  },
  divider: {
    margin: "20px -20px",
    "@media(max-width: 1199px)": {
      margin: "10px -16px",
    }
  }
};
ScenariosForm = withStyles(styles)(ScenariosForm);

export default compose(
  connect(
    state => ({
      subscriptions: state.global.subscriptions || [],

      tones: state.directory.tones,
      scenarios: (state.directory.scenarios || []).filter((t) => t.settings.available_single),
      languages: state.directory.languages,
      creativity: state.directory.creativity,
    })
  ),
)(ScenariosForm);
