import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Rating,
  Button,
  TextField,
  Typography,

  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import agent from "../../agent/agent";

class Review extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        interfaceRating: null,
        interfaceMessage: null,

        textRating: null,
        textMessage: null,
      },

      isBackdrop: false,
      isOpenDialog: false
    }
  }

  openDialog = () => {
    this.setState({ isOpenDialog: true })
  }
  closeDialog = () => {
    this.setState({
      isOpenDialog: false,
      form: {
        interfaceRating: null,
        interfaceMessage: null,

        textRating: null,
        textMessage: null,
      },
    })
  }

  changeForm = (name, val) => {
    let form = {...this.state.form};
    form[name] = val;
    this.setState({ form })
  }
  submitForm = async () => {
    const { form } = this.state;
    const { item } = this.props;

    if ((!form?.interfaceRating && !form.interfaceMessage) && (!form?.textRating && !form.textMessage)) {
      return Notification({
        type: notificationTypes.error,
        message: "Заполните данные"
      })
    }

    this.setState({ isBackdrop: true });

    let messages = [];
    if (form?.textRating || form.textMessage) {
      const formDataText = {};
      formDataText.score = form.textRating;
      formDataText.message = form.textMessage;
      formDataText.source = "interface";
      formDataText.source_id = item.id;
      const resText = await agent.post('/feedback', formDataText).then((res) => {
        return res.data
      }).catch((err) => {
        return {error: err.response?.data}
      });
      if (resText.error?.message) {
        messages.push(`Ошибка при оставлении отзыва об тексте: ${resText.error?.message}`);
      }
      else {
        messages.push('Отзыв об тексте успешно отправлен')
      }
    }
    if (form?.interfaceRating || form.interfaceMessage) {
      const formDataInterface = {};
      formDataInterface.score = form.interfaceRating;
      formDataInterface.message = form.interfaceMessage;
      formDataInterface.source = "scenario";
      formDataInterface.source_id = item.id;
      const resInterface = await agent.post('/feedback', formDataInterface).then((res) => {
        return res.data
      }).catch((err) => {
        return {error: err.response?.data}
      });
      if (resInterface.error?.message) {
        messages.push(`Ошибка при оставлении отзыва об интерфейсе: ${resInterface.error?.message}`);
      }
      else {
        messages.push('Отзыв об интерфейсе успешно отправлен')
      }
    }

    this.closeDialog();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.info,
      message: messages.join(";<br/>"),
      duration: 12000
    })
  }

  render () {
    const {
      form,
      isBackdrop,
      isOpenDialog
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <>

        <Button variant="outlined" onClick={this.openDialog}>
          Оставить отзыв
        </Button>

        <Dialog
          open={isOpenDialog}
          fullWidth
          maxWidth="md"
          onClose={this.closeDialog}
        >
          <DialogTitle>
            <Typography variant="h3">Оставить отзыв</Typography>
          </DialogTitle>
          <DialogContent>
            <ReviewSection
              ratingTitle="Оцените удобства интерфейса"
              ratingValue={form.interfaceRating}
              messageValue={form.interfaceMessage}

              classes={classes}

              onChangeRating={this.changeForm.bind(this, 'interfaceRating')}
              onChangeMessage={this.changeForm.bind(this, 'interfaceMessage')}
            />

            <Box mt={3}/>

            <ReviewSection
              ratingTitle="Оцените полученный текст"
              ratingValue={form.textRating}
              messageValue={form.textMessage}

              classes={classes}

              onChangeRating={this.changeForm.bind(this, 'textRating')}
              onChangeMessage={this.changeForm.bind(this, 'textMessage')}
            />

            <Box mt={3}/>

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" onClick={this.closeDialog}>
                  Отменить
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={this.submitForm}>
                  Отправить отзыв
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </>
    )
  }
}
const ReviewSection = React.memo((props) => {
  const {
    ratingTitle,
    ratingValue,
    messageValue,

    classes,

    onChangeRating,
    onChangeMessage
  } = props;

  return (
    <Box className={classes.reviewSection}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography className={classes.reviewSectionTitle}>{ ratingTitle }</Typography>
        </Grid>
        <Grid item>
          <Rating
            value={ratingValue}
            onChange={(e, v) => onChangeRating(v)}
          />
        </Grid>
      </Grid>
      <Box mt={1}>
        <TextField
          value={messageValue}
          rows={3}
          fullWidth
          multiline
          placeholder="Опишите Ваши пожелания"
          onChange={({target}) => onChangeMessage(target.value)}
        />
      </Box>
    </Box>
  )
});

const styles = {
  reviewSection: {},
  reviewSectionTitle: {
    color: '#2E8BFF',
    fontSize: '18px',
    fontWeight: '500px',
    letterSpacing: '-0.28px',
  }
};
Review = withStyles(styles)(Review);

export default Review
