// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import RegistrationConfirm from './RegistrationConfirm';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(RegistrationConfirm);
