import React, {useContext} from "react";
import {
	Grid,
	Button,
	Select,
	Tooltip,
	MenuItem,
	Typography,
	IconButton,
	FormControl,
	FormHelperText,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Checkbox,
	FormControlLabel
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Close as CloseIcon,
	Help as HelpIcon
} from "@mui/icons-material";
import {connect, useSelector} from "react-redux";
import {
	TariffCard,
	DialogSelectType
} from "../../pages/auth/Welcome/components/Pricing/Pricing";
import agent from "../../agent/agent";
import {Notification, notificationTypes} from "../../common/Notification";
import {compose} from "recompose";

class SelectAiModel extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			tariffsGpt4: [],
			errorCodeGpt4: '',

			saveEvent: null,
			isOpenConfirm: false
		};
	}

	componentDidMount = async () => {
		await this.checkInitAiModel();
	}
	componentDidUpdate = async (prevProps) => {
		if (JSON.stringify(prevProps?.aiModels) !== JSON.stringify(this.props?.aiModels)) {
			await this.checkInitAiModel();
		}
	}

	checkInitAiModel = async () => {
		const { value, aiModels } = this.props;
		const aiModel = (aiModels || []).find((t) => t?.ai_type?.id === value);
		if (!(aiModel?.ai_type?.model || '').includes('gpt-4-')) {
			return
		}

		const errorCode = this.getErrorCodeGpt4();
		if (!errorCode) {
			return
		}

		await this.errorGpt4(errorCode);
	}

	onChange = (event, isConfirm) => {
		const { value } = event.target;
		const { aiModels } = this.props;

		const aiModel = (aiModels || []).find((t) => t.ai_type?.id === value);
		const saveConfirmUseGpt4 = Boolean(localStorage.getItem("save_confirm_use_gpt4") || "");
		if ((aiModel?.ai_type?.model || '').includes('gpt-4') && !isConfirm && !saveConfirmUseGpt4) {
			const errorCode = this.getErrorCodeGpt4(value);
			if (!!errorCode) {
				(async () => {
					await this.errorGpt4(errorCode);
				})();
				return;
			}

			this.setState({
				saveEvent: event,
				isOpenConfirm: true
			});
			return
		}
		if ((aiModel?.ai_type?.model || '').includes('gpt-4') && !isConfirm && saveConfirmUseGpt4) {
			const errorCode = this.getErrorCodeGpt4();
			if (!!errorCode) {
				(async () => {
					await this.errorGpt4(errorCode);
				})();
				return;
			}
		}

		this.props.onChange(event);
	}

	errorGpt4 = async (errorCode) => {
		let tariffs = await agent.get(`/tariffs?limit=20&offset=0&enabled=true`).then((res) => {
			return (res.data?.tariffs || []).filter((t) => t.type !== 'promo')
		}).catch(() => {
			return []
		});
		tariffs = tariffs.filter((t) => Boolean(t.can_gpt4));
		this.setState({
			tariffsGpt4: tariffs,
			errorCodeGpt4: errorCode,
			saveEvent: null,
			isOpenConfirm: false,
		});
	}
	closeErrorGpt4 = () => {
		this.setState({
			errorCodeGpt4: "",
		});

		const { aiModels } = this.props;
		const aiModel = (aiModels || []).find((t) => !(t?.ai_type?.model || '').includes('gpt-4-'));
		if (!aiModel) {
			return
		}

		const event = {
			target: {
				name: 'ai_type_id',
				value: aiModel?.ai_type?.id
			}
		};
		this.props.onChange(event);
		localStorage.removeItem('last-use-gpt-4');
	}
	getErrorCodeGpt4 = (initValue) => {
		const { aiModels, value: propsValue, subscriptions } = this.props;
		const value = initValue || propsValue;
		const selectedAiModel = aiModels.find((t) => Boolean(String(t?.ai_type?.id) === String(value)));
		if (!(selectedAiModel?.ai_type?.model || '').includes('gpt-4-')) {
			return
		}

		const countAllSubscriptions = (subscriptions || []).length;
		const countGPT4Subscriptions = (subscriptions || []).filter((t) => Boolean(t?.can_gpt4)).length;

		// Ошибки если нет тарифов
		if (countAllSubscriptions <= 0 && countGPT4Subscriptions <= 0) {
			return 'not-found'
		}
		if (countAllSubscriptions > 0 && countGPT4Subscriptions <= 0) {
			return 'no-gpt4-tariff'
		}

		// Ошибка если не хватает лимита
		const requestsCu = selectedAiModel?.requests_cu || 0;
		const totalCountRequests = (subscriptions || []).filter((t) => Boolean(t?.can_gpt4)).reduce((val, subscription) => {
			return val + (subscription?.limits?.limit?.scenario || 0) - (subscription?.limits?.used?.scenario || 0)
		}, 0);
		if (requestsCu > totalCountRequests) {
			return 'no-limits'
		}

		return ''
	}

	closeConfirm = () => {
		this.setState({
			saveEvent: null,
			isOpenConfirm: false
		})
	}
	successConfirm = () => {
		this.onChange(this.state.saveEvent, true);
		this.setState({
			saveEvent: null,
			isOpenConfirm: false,
		})
	}


	render () {
		const {
			value,
			error,
			touched,
			aiModels
		} = this.props;
		const {
			saveEvent,
			isOpenConfirm,

			tariffsGpt4,
			errorCodeGpt4
		} = this.state;

		return (
			<>
				<Typography variant="formLabel" mb="8px">
					<span>AI модель</span>
					<span style={{position: "relative"}}>
							<Tooltip title={
								<React.Fragment>
									<div dangerouslySetInnerHTML={{__html: "GPT4-o заменила ранее используемую в нашем сервисе GPT-4 Turbo. GPT-4o — самая новая модель, которая обладает таким же высоким интеллектом, как GPT-4 Turbo но создаёт текст в 2 раза быстрее, И также как GPT 4 Turbo стоит 50 запросов.<br/>GPT4-o Mini - это облегчённая и более дешёвая модель по сравнению с GPT4-o, которая позволяет решать простые задачи с текстами.  GPT4-o Mini теперь в нашем сервисе вместо модели GPT 3.5. Turbo. Стоимость осталась как и у GPT 3.5 Turbo."}}></div>
								</React.Fragment>
							} arrow>
								<HelpIcon
									color="primary"
									sx={{marginLeft: "16px", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", width: 20, height: 20}}
								/>
							</Tooltip>
						</span>
				</Typography>
				<FormControl fullWidth error={Boolean(touched && error)}>
					<Select
						value={value}
						name="ai_type_id"
						onChange={(val) => this.onChange(val, false)}
					>
						{aiModels.map((model) => (
							<MenuItem key={`model-ai-${model.ai_type.id}`} value={model.ai_type.id}>
								{model.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{Boolean(touched && error) && (
					<FormHelperText error={true}>{touched && error}</FormHelperText>
				)}

				<VisibleContent visible={isOpenConfirm}>
					<DialogConfirm
						aiModels={aiModels}
						saveEvent={saveEvent}
						onClose={this.closeConfirm}
						onSuccess={this.successConfirm}
						onErrorGpt4={this.errorGpt4}
					/>
				</VisibleContent>
				<VisibleContent visible={errorCodeGpt4}>
					<DialogErrorGpt4
						tariffs={tariffsGpt4}
						errorCode={errorCodeGpt4}
						onClose={this.closeErrorGpt4}
					/>
				</VisibleContent>
			</>
		)
	}
};
const DialogConfirm = React.memo((props) => {
	const {
		aiModels,
		saveEvent,
		onClose,
		onSuccess
	} = props;
	const classes = useStyles();
	const subscriptions = useSelector((state) => state.global.subscriptions);
	const [saveResult, setSaveResult] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [aiModelRequests, setAiModelRequests] = React.useState(() => {
		const selectedAiModel = saveEvent.target.value;
		return aiModels.find((t) => t?.ai_type?.id === selectedAiModel)?.requests_cu || 0;
	});
	const [remainder, setRemainder] = React.useState(() => {
		const totalCountRequest = (subscriptions || []).filter((t) => t.can_gpt4).reduce((val, subscription) => {
			return val + (subscription?.limits?.limit?.scenario || 0) - (subscription?.limits?.used?.scenario || 0)
		}, 0);
		const selectedAiModelRequests = (aiModels || []).find((t) => Boolean(String(t?.ai_type?.id) === String(saveEvent?.target?.value)))?.requests_cu || 1;

		return Math.floor(totalCountRequest / selectedAiModelRequests) - 1
	});

	const _onCancel = () => {
		onClose();
	}
	const _onSuccess = () => {
		if (saveResult) {
			localStorage.setItem("save_confirm_use_gpt4", "1");
		}
		onSuccess();
	}

	return (
		<Dialog
			open={true}
			fullWidth
			maxWidth="md"
			onClose={_onCancel}
		>
			<DialogTitle>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>
						<Typography variant="h3">{"Подтверждение"}</Typography>
					</Grid>
					<Grid item>
						<IconButton onClick={_onCancel}>
							<CloseIcon/>
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<VisibleContent visible={errorMessage}>
					<Typography className={classes.dialogMessage}>
						{ errorMessage }
					</Typography>
				</VisibleContent>
				<VisibleContent visible={!errorMessage}>
					<Typography className={classes.dialogMessage}>
						Вы переключаетесь на модель GPT4-o, что улучшит качество полученного текста и позволит обрабатывать большие тексты, но стоимость одного запроса в данной модели составит за 1 запрос GPT3-o -
						{aiModelRequests} запросов GPT4-o mini и в данной модели вы сможете выполнить ещё {remainder} запросов.
					</Typography>
					<FormControlLabel
						value={saveResult}
						checked={saveResult}
						control={<Checkbox />}
						label="Больше не спрашивать"
						className={classes.dialogConfirm}
						onChange={(e, v) => setSaveResult(v)}
					/>
				</VisibleContent>
			</DialogContent>
			<DialogActions>
				<Grid container spacing={1} justifyContent="flex-end" px={2}>
					<VisibleContent visible={!errorMessage}>
						<Grid item>
							<Button className={classes.dialogButton} variant="outlined" size="small" onClick={_onCancel}>Нет</Button>
						</Grid>
						<Grid item>
							<Button className={classes.dialogButton} variant="contained" size="small" onClick={_onSuccess}>Да</Button>
						</Grid>
					</VisibleContent>
					<VisibleContent visible={errorMessage}>
						<Grid item>
							<Button className={classes.dialogButton} variant="outlined" size="small" onClick={_onCancel}>Закрыть</Button>
						</Grid>
					</VisibleContent>
				</Grid>
			</DialogActions>
		</Dialog>
	)
});
const DialogErrorGpt4 = React.memo((props) => {
	const {
		tariffs,
		errorCode,
		onClose
	} = props;
	const classes = useStyles();
	const [isBackdrop, setBackdrop] = React.useState(false);
	const [isDialogSelectType, setDialogSelectType] = React.useState(null);

	const handleBuyTariff = async (tariff, type) => {
		if (!type) {
			setDialogSelectType(tariff);
			return
		}

		setBackdrop(true);
		setDialogSelectType(null);
		const redirectUrl = await agent.post(`/tariffs/${tariff.id}/subscribe`, {
			method: type,
			return_url: window.location.href
		}).then((res) => {
			return res.data?.redirect_url
		}).catch((err) => {
			return {error: err.response?.data?.message}
		});
		if (redirectUrl.error) {
			setBackdrop(false);
			Notification({
				message: redirectUrl.error || "Ошибка получения ссылки на оплату, обратитесь к администраторам.",
				type: notificationTypes.error
			})
			return
		}
		window.location.href = redirectUrl;
	}
	const _errorMessage = () => {
		if (errorCode === "not-found") {
			return "Для работы с моделью GPT4-o необходимо купить один из тарифов ниже."
		}
		if (errorCode === "no-gpt4-tariff") {
			return "Для работы с моделью GPT4-o необходимо купить один из тарифов ниже без потери существующих запросов."
		}
		if (errorCode === "no-limits") {
			return "У вас не осталось запросов в данном типе моделей, чтобы продолжить, Вы можете купить один из тарифов ниже. Все имеющиеся запросы GPT4-o mini и Midjourney будут сохранены и добавлены к оплаченному тарифу:"
		}
	}

	return (
		<>

			<Dialog
				open={true}
				fullWidth={true}
				maxWidth="lg"
			>
				<DialogTitle>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="h3">Уведомления</Typography>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}>
								<CloseIcon/>
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Typography
						mb={2}
						className={classes.dialogMessage}
						dangerouslySetInnerHTML={{ __html: _errorMessage() }}
					/>
					<Grid container spacing={2}>
						{tariffs.map((tariff) => (
							<Grid item xs={4} className={classes.tariffCard}>
								<TariffCard
									data={tariff}
									onBuy={handleBuyTariff}
								/>
							</Grid>
						))}
					</Grid>
				</DialogContent>
			</Dialog>

			<VisibleContent visible={Boolean(isDialogSelectType)}>
				<DialogSelectType
					onSelect={handleBuyTariff.bind(this, isDialogSelectType)}
					onClose={() => setDialogSelectType(null)}
				/>
			</VisibleContent>

		</>
	)
});

const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
});

const useStyles = makeStyles(() => ({
	dialogMessage: {
		fontSize: 16,
		lineHeight: "180%",
		color: "black"
	},
	dialogConfirm: {
		marginTop: 10,
		"& .MuiTypography-root": {
			textDecoration: "none",
			userSelect: "none"
		}
	},
	dialogButton: {
		padding: "4px 20px",
		minWidth: "120px"
	},

	tariffCard: {
		"& > *": {
			margin: 0,
			width: "100%"
		}
	}
}));

export default compose(
	connect(
		state => ({
			subscriptions: state?.global?.subscriptions || []
		}),
		dispatch => ({}),
	),
)(SelectAiModel);
