import React from "react";
import {
	Box,
	Grid,
	Button,
	Divider,

	Tabs,
	Tab
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {useHistory} from "react-router-dom";

const TabsTypeScenarios = (props) => {
	const { value } = props;
	const classes = useStyles();
	const history = useHistory();

	const handleRoutePage = (event, value) => {
		if (value === 'multi') {
			history.push('/task-batch');
			return
		}
		if (value === 'single') {
			history.push('/task');
			return;
		}
	}

	return (
		<>
			<Box className={classes.root}>
				<Grid container spacing={1}>
					<Grid item>
						<Button
							variant={Boolean(value === 'single') ? 'contained' : 'outlined'}
							size="small"
							className={classes.buttonType}
							onClick={handleRoutePage.bind(this, null, 'single')}
						>
							Одиночная
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant={Boolean(value === 'multi') ? 'contained' : 'outlined'}
							size="small"
							className={classes.buttonType}
							onClick={handleRoutePage.bind(this, null, 'multi')}
						>
							Пакетная
						</Button>
					</Grid>
				</Grid>
			</Box>
			<Divider className={classes.divider}/>
		</>
	)
}

const useStyles = makeStyles(() => ({
	divider: {
		margin: "20px -20px",
		"@media(max-width: 1199px)": {
			margin: "10px -16px",
		}
	},

	buttonType: {
		padding: "8px 20px 6px",
		fontSize: "16px",
		lineHeight: "100%",
	}
}));

export default React.memo(TabsTypeScenarios)
