import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	Typography,
	CircularProgress,
} from "@mui/material";
import {palette} from "../../../theme/common";
import {withStyles} from "@mui/styles";
import {Formik} from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import agent from "../../../agent/agent";
import {Notification, notificationTypes} from "../../../common/Notification";

class AccountPasswordReset extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				password: "",
				repeatPassword: "",
			},
			isBackdrop: false
		};
		this.refFormik = React.createRef();
	}

	componentDidMount = () => {
	}

	changeForm = ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}
	submit = async (form) => {
		this.setState({ isBackdrop: true });
		const searchString = window.location.search;
		const searchObject = queryString.parse(searchString);
		const body = {
			code: searchObject.code,
			new_password: form.password,
		};
		const res = await agent.post('/accounts/password/reset', body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			this.setState({ isBackdrop: false });
			Notification({
				message: res?.error?.data?.message || "Ошибка сервера",
				type: notificationTypes.error
			})
			return
		}
		this.setState({ isBackdrop: false });
		Notification({
			title: "Пароль успешно изменен, авторизуйтесь.",
			type: notificationTypes.success
		});
		this.props.history.push('/authorization');
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			form,
			isBackdrop
		} = this.state;

		return (
			<Box className={classes.root}>
				<Box className={classes.logo}>
					<img src={require('../../../assets/image/logo/logo-text-shadow.svg').default} alt="logo"/>
				</Box>
				<Formik
					innerRef={this.refFormik}
					initialValues={form}
					validationSchema={validationSchema}
					onSubmit={this.submit}
				>{(props) => {
					const {
						values,
						touched,
						errors,
						handleSubmit
					} = props;

					return (
						<Box className={classes.form}>
							<Typography className={classes.formTitle}>Сброс пароля</Typography>
							<Grid container spacing="28px">
								<Grid item xs={12}>
									<FormElement
										value={values.password}
										name="password"
										title="Ваш пароль"
										placeholder="Ваш пароль"
										helpMessage_="Забыли пароль?"
										type="password"
										error={Boolean(touched.password && errors.password)}
										helperText={touched.password && errors.password}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormElement
										value={values.repeatPassword}
										name="repeatPassword"
										title="Повторите пароль"
										placeholder="Повторите пароль"
										helpMessage_="Забыли пароль?"
										type="password"
										error={Boolean(touched.repeatPassword && errors.repeatPassword)}
										helperText={touched.repeatPassword && errors.repeatPassword}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button variant="contained" fullWidth onClick={handleSubmit}>
										Сбросить
									</Button>
								</Grid>
							</Grid>
						</Box>
					)
				}}</Formik>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Box>
		);
	}
}
const FormElement = React.memo((props) => {
	const {
		title,
		placeholder,
		helpMessage,

		...otherProps
	} = props;

	return (
		<Box>
			<Grid container justifyContent="space-between">
				<Grid item>
					<Typography variant="formLabel" mb="8px">{ title }</Typography>
				</Grid>
				{Boolean(helpMessage) && (
					<Grid item>
						<Typography variant="formLabel" mb="8px" style={{color: palette.text.lightGray}}>{ helpMessage }</Typography>
					</Grid>
				)}
			</Grid>
			<TextField
				placeholder={placeholder}
				fullWidth
				{...otherProps}
			/>
		</Box>
	)
});

const style = {
	root: {
		overflow: "auto",
		padding: "50px 0",
		boxSizing: "border-box"
	},
	logo: {
		width: "115px",
		height: "115px",
		position: "relative",
		margin: "0 auto",
		marginBottom: "48px",

		"& img": {
			maxWidth: "100%",
			position: "absolute",
			top: "0", left: "50%",
			transform: "translateX(-50%)"
		}
	},

	form: {
		margin: "0 auto",
		width: "100%",
		maxWidth: 400,
		padding: "28px",
		boxSizing: "border-box",
		borderRadius: 16,
		border: '0.5px solid rgba(0, 0, 0, 0.10)',
		background: '#FFF',
		boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
	},
	formTitle: {
		marginBottom: "24px",

		fontSize: 24,
		lineHeight: "30px",
		textAlign: "center",
		color: palette.primary.text
	},
	formElement: {},

	authButton: {
		width: 48,
		height: 48,

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "contain"
		}
	}
};
AccountPasswordReset = withStyles(style)(AccountPasswordReset);

const validationSchema = Yup.object().shape({
	password: Yup
		.string()
		.min(8, "Минимальная длина 8 символов")
		.required('Заполните поле'),
	repeatPassword: Yup
		.string()
		.min(8, "Минимальная длина 8 символов")
		.oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
		.required('Заполните поле'),
});

export default AccountPasswordReset
