import React from 'react';
import s from './feedback.module.css';
import {Container} from "@mui/material";

const Feedback = () => {
    return (
        <div className={s.feedback}>
            <Container>
                <div className={s.feedbackForm}>
                    <div className={s.coverWhite}></div>
                    <div className={`${s.feedbackCover} ${s.coverYellow}`}></div>
                    <div className={`${s.feedbackCover} ${s.coverViolet}`}></div>
                    <div className={`${s.feedbackCover} ${s.coverGreen}`}></div>
                    <h3>Свяжитесь с нами</h3>
                    <div className={s.feedbackInputs}>
                        <div className={s.inputGroup}>
                            <span>Ваше имя</span>
                            <input type="text" placeholder='Имя и фамилия'/>
                        </div>

                        <div className={s.inputGroup}>
                            <span>Ваша почта</span>
                            <input type="text" placeholder='Ваша почта или телефон'/>
                        </div>
                    </div>
                    <div className={s.inputGroup}>
                        <span>Ваше сообщение</span>
                        <textarea placeholder='Мы будем рады вам помочь'></textarea>
                    </div>
                    <div className={s.btnWrapper}>
                        <button className='welcome-btn'>Отправить</button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Feedback;