// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import AccountConfirm from './AccountConfirm';
import {setUserProfile} from "../../../states/global";

export default compose(
  connect(
    state => ({
      user: state?.global?.userProfile || null
    }),
    dispatch => ({
      setUserProfile: (val) => dispatch(setUserProfile(val))
    }),
  ),
)(AccountConfirm);
