// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Authorization from './Authorization';
import {setUserProfile} from "../../../states/global";
import {initDirectory} from "../../../states/directory";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      setUserProfile: (profile) => dispatch(setUserProfile(profile)),
      initDirectory: () => dispatch(initDirectory())
    }),
  ),
)(Authorization);
