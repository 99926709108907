// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import History from './History';

export default compose(
  connect(
    state => ({
      scenarios: state.directory.scenarios,
    }),
    dispatch => ({}),
  ),
)(History);
