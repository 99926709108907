import agent from "../agent/agent";

const SET_USER_PROFILE = "global/SET_USER_PROFILE";
const SET_ACCOUNT_PROFILE = "global/SET_ACCOUNT_PROFILE";
const SET_ACCOUNT_SUBSCRIPTIONS = "global/SET_ACCOUNT_SUBSCRIPTIONS";
const FULL_UPDATE = "global/FULL_UPDATE";
const UPDATE_NEWS = "global/UPDATE_NEWS";

const initialState = {
  userProfile: null,
  accountProfile: null,
  subscriptions: [],
  news: [],
};

export function setUserProfile(userProfile) {
  return async function (dispatch) {
    dispatch({
      type: SET_USER_PROFILE,
      userProfile
    });

    let subscriptions = [];
    let accountFull = null;
    if (userProfile?.user_id) {
      accountFull = await agent.get(`/accounts/current`).then((res) => {
        return res.data
      }).catch(() => {
        return null
      });
      subscriptions = await agent.get(`/subscriptions?active=true`).then((res) => {
        return res.data
      }).catch(() => {
        return []
      })
    }
    dispatch({
      type: SET_ACCOUNT_PROFILE,
      accountProfile: accountFull
    })
    dispatch({
      type: SET_ACCOUNT_SUBSCRIPTIONS,
      subscriptions: subscriptions
    })
  }
}
export function updateUserProfiles () {
  return async function (dispatch) {
    const profile = await agent.get('/accounts/profile').then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const account = await agent.get(`/accounts/current`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });

    dispatch({
      type: FULL_UPDATE,
      profile,
      account,
    })
  }
}
export function updateNews (news) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_NEWS,
      news
    });
  }
}
export function updateSubscriptions () {
  return async function (dispatch) {
    const subscriptions = await agent.get(`/subscriptions?active=true`).then((res) => {
      return res.data
    }).catch(() => {
      return []
    })
    dispatch({
      type: SET_ACCOUNT_SUBSCRIPTIONS,
      subscriptions: subscriptions
    })
  }
}

export default function AppState(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.userProfile
      }
    }
    case SET_ACCOUNT_PROFILE: {
      return {
        ...state,
        accountProfile: action.accountProfile
      }
    }
    case FULL_UPDATE: {
      return {
        ...state,
        profile: action.profile,
        account: action.account,
      }
    }
    case SET_ACCOUNT_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: action.subscriptions,
      }
    }
    case UPDATE_NEWS: {
      return {
        ...state,
        news: action.news,
      }
    }

    default:
      return state;
  }
}
