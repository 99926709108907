import React from "react";
import {
  Box,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";

const TariffCard = (props) => {
  const {
    data
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.cardBody}>
        <Typography
          className={classes.cardTitle}
          dangerouslySetInnerHTML={{__html: "Текст-день"}}
        />
        <Typography className={classes.card}></Typography>
      </Box>
      <Box className={classes.cardFooter}></Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  card: {},
  cardBody: {
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: '20px',
    background: '#FFF',
    boxShadow: '-1px 5px 12px 0px rgba(15, 68, 138, 0.05), -3px 21px 22px 0px rgba(15, 68, 138, 0.04), -7px 48px 29px 0px rgba(15, 68, 138, 0.03), -13px 85px 35px 0px rgba(15, 68, 138, 0.01), -20px 134px 38px 0px rgba(15, 68, 138, 0.00)',
    backdropFilter: 'blur(40px)',
  },
  cardFooter: {},
  cardTitle: {
    fontSize: 24,
    lineHeight: "32px",
    color: "#254164",
    letterSpacing: "-0.48px",
    fontWeight: "500"
  }
}));

export default React.memo(TariffCard)
