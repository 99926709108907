// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import TaskBatchView from './TaskBatchView';
import wsMessages from "../../../../states/ws-messages";

export default compose(
  connect(
    state => ({
      wsMessages: state.wsMessages.wsMessages || []
    }),
    dispatch => ({}),
  ),
)(TaskBatchView);
