import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Chip,
	Button,
	Divider,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {palette} from "../../../../../../theme/common";
import labelsScenario from "../../../../../../constants/labels-scenario.json";

class DialogInfoTask extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			task: null,
			isOpen: false
		}
	}

	open = ({ task }) => {
		this.setState({
			task,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			task: null,
			isOpen: false
		})
	}

	render () {
		const {
			task,
			isOpen
		} = this.state;
		const {
			scenario
		} = this.props;

		if (!isOpen) {
			return null
		}
		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="md"
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h1" textAlign="left" sx={{color: '#2C4058'}}>Детализация задачи №{task.taskIndex + 1}</Typography>
				</DialogTitle>
				<DialogContent>

					<MainSectionInfo
						task={task}
						scenario={scenario}
					/>

					<Box mt={3}/>
					<Divider/>
					<Box mt={3}/>

					<SecondarySectionInfo
						task={task}
					/>

				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Button size="small" variant="contained" onClick={this.close}>
								Закрыть
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
};
const MainSectionInfo = React.memo((props) => {
	const {
		task,
		scenario,
	} = props;
	const classes = useStyles();

	return (
		<Grid container spacing={3}>
			{(scenario?.widget || []).map((_widget, index) => (
				<Grid item xs={12}>
					<Typography className={classes.mainInfoTitle}>{ _widget?.settings?.label }</Typography>
					<Typography className={classes.mainInfoMessage}>
						{ (task?.widgets || []).find((t) => t.slug === _widget?.slug)?.answer }
					</Typography>
				</Grid>
			))}
		</Grid>
	)
});
const SecondarySectionInfo = React.memo((props) => {
	const {
		task
	} = props;
	const classes = useStyles();
	const [widgets, setWidgets] = React.useState(() => {
		let _task = (task?.widgets || []).filter((t) => ['language', 'tone', 'creativity'].includes(t.slug));
		_task = _task.map((_item) => {
			return {
				..._item,
				label: labelsScenario[_item.slug]
			}
		})

		return _task
	});

	return (
		<Grid container spacing={1}>
			{widgets.map((widget) => (
				<Grid item>
					<Chip
						label={`${ widget.label }: ${ widget.answer }`}
						className={classes.chip}
					/>
				</Grid>
			))}
		</Grid>
	)
})

const useStyles = makeStyles(() => ({
	mainInfoTitle: {
		fontWeight: "500",
		fontSize: "16px",
		lineHeight: "130%",
		leadingTrim: "both",
		textEdge: "cap",
		letterSpacing: "-0.02em",
		color: "#254164",
	},
	mainInfoMessage: {
		marginTop: 6,
		// fontWeight: "500",
		fontSize: "14px",
		lineHeight: "130%",
		leadingTrim: "both",
		textEdge: "cap",
		letterSpacing: "-0.02em",
		color: "#71869F",
},

	chip: {
		height: "auto",
		padding: "8px",
		position: "relative",
		zIndex: 0,
		overflow: "hidden",

		"& span": {
			fontWeight: "500",
			fontSize: "14px",
			lineHeight: "100%",
			letterSpacing: "-0.02em",
			background: "linear-gradient(90.03deg, #FFFFFF 5.51%, #7BFFDF 100.48%)",
			'-webkit-background-clip': "text",
			'-webkit-text-fill-color': "transparent",
			backgroundClip: "text",
			textFillColor: "transparent",
		},
		"&:after": {
			content: "''",
			position: "absolute",
			top: 0, left: 0,
			width: "100%", height: "100%",
			background: palette.primary.main,
			zIndex: -1,
		}
	}
}));

export default DialogInfoTask
