import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon
} from "@mui/icons-material";
import {
  Link, useRouteMatch
} from "react-router-dom";
import {palette} from "../../../../theme/common";
import clsx from "clsx";

const mainMenuItems = [
  {label: "Работа<br/>с текстом", path: "/task/create", paths: ['/task/create', '/task/:id']},
  {label: "Работа<br/>с картинками", path: "/midjourney/create", paths: ['/midjourney/create', '/midjourney/:id']},
  {label: "Тарифы", path: "/tariff", paths: ['/tariff']},
];
const HeaderMobile = (props) => {
  const {
    profile,
    onLogout
  } = props;
  const classes = useStyles();
  const routeMatch = useRouteMatch();
  const [isDropdown, setDropdown] = React.useState();
  const DropDownIcon = Boolean(isDropdown) ? CloseIcon : MenuIcon;
  const profileName = [profile?.first_name, profile?.last_name].filter((t) => !!t).join(" ");

  const handleChangeDropdown = () => {
    setDropdown(!isDropdown);
  }

  return (
    <Container>
      <Box className={clsx({
        [classes.root]: true,
        "open": isDropdown
      })}>
        <Box className={clsx([classes.rootMain, '--main'])}>
          <a href="https://aisynergy.ru" className={classes.logo}>
            <img src={require('../../../../assets/image/logo/logo-round.svg').default}/>
          </a>
          <Grid container className={classes.mainMenu} wrap="nowrap" justifyContent="space-around">
            {mainMenuItems.map((item) => (
              <Grid item>
                <Link
                  to={item.path}
                  className={clsx({
                    [classes.mainMenuElement]: true,
                    'active': Boolean(item.paths.includes(routeMatch.path))
                  })}
                  dangerouslySetInnerHTML={{__html: item.label}}
                />
              </Grid>
            ))}
          </Grid>
          <Box className={classes.buttonDropdown} onClick={handleChangeDropdown}>
            <DropDownIcon color="primary"/>
          </Box>
        </Box>
        <VisibleContent visible={isDropdown}>
          <Box className={clsx([classes.rootAdditional, '--additional'])}>
            <Link to="/profile" className={classes.profileInfo}>
              <img className={classes.profileInfoIcon} src={require('../../../../assets/image/logo/logo-people.png')}/>
              <Typography className={classes.profileInfoName}>
                {profileName}
              </Typography>
            </Link>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item>
                <Link to="/history" className={classes.additionalMenuItem}>История</Link>
              </Grid>
              <Grid item>
                <Link to="/saved" className={classes.additionalMenuItem}>Сохранённое</Link>
              </Grid>
            </Grid>
            <Box className={classes.buttonExit} onClick={onLogout}>
              Выйти
              <ExitToAppOutlinedIcon/>
            </Box>
          </Box>
        </VisibleContent>
      </Box>
    </Container>
  )
}
const VisibleContent = React.memo(({ visible, children }) => {
  if (!visible) {
    return null
  }
  return children
})

const useStyles = makeStyles(() => ({
  root: {
    height: 64,
    borderRadius: "100px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "rgba(255, 255, 255, 0.60)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    backdropFilter: "blur(40px)",
    position: "relative",
    zIndex: 100,

    "&.open": {
      borderRadius: 32,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: "none",
      background: "white",

      "& .--additional": {
        background: "white",
        borderTop: "1px solid rgba(0,0,0,0.1)",
      }
    }
  },
  rootMain: {
    display: "flex",
    alignItems: "center",
    padding: 4,
    paddingRight: 12,
    boxSizing: "border-box",
  },
  rootAdditional: {
    position: "absolute",
    left: -1, right: -1,
    top: "100%",
    borderRadius: "0 0 32px 32px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    borderTop: "none",
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.10), 0px 13px 13px 0px rgba(0, 0, 0, 0.09), 0px 29px 17px 0px rgba(0, 0, 0, 0.05), 0px 52px 21px 0px rgba(0, 0, 0, 0.01), 0px 81px 23px 0px rgba(0, 0, 0, 0.00)",
    paddingTop: 24,
    boxSizing: "border-box",
  },

  logo: {
    display: "flex",
    width: 56,
    height: 56,
    borderRadius: "100%",
    position: "relative",
    overflow: "hidden",

    "& img": {
      position: "absolute",
      width: "100%", height: "100%",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)"
    }
  },
  mainMenu: {
    flex: 1,
    margin: "-4px 24px",
    "@media(max-width: 459px)": {
      margin: "-4px 8px",
    }
  },
  mainMenuElement: {
    position: "relative",
    height: 62,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-4px",

    color: "#2C4058",
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: "-0.24px",
    fontFamily: "Suisse Intl",

    "&.active": {
      color: palette.primary.main,
      fontWeight: "500",

      "&:after": {
        content: "''",
        position: "absolute",
        left: 0, right: 0, bottom: -2,
        height: 3,
        borderRadius: "100px",
        backgroundColor: palette.primary.main
      }
    }
  },
  buttonDropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 38,
    height: 38,
    borderRadius: "77px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "rgba(255, 255, 255, 0.60)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    backdropFilter: "blur(40px)",
    padding: "8px",
    boxSizing: "border-box",

    "& svg": {
      width: "100%",
      height: "100%"
    }
  },

  profileInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20
  },
  profileInfoIcon: {
    width: 48,
    height: 48,
    marginBottom: 4,
    borderRadius: "100%",
    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.15)",
    objectFit: "cover",
    backgroundColor: palette.primary.main
  },
  profileInfoName: {
    color: "#2C4058",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  },

  additionalMenuItem: {
    color: "#2C4058",
    fontFamily: "Suisse Intl",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  },

  buttonExit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0",
    boxSizing: "border-box",
    borderTop: "1px solid rgba(0,0,0,0.1)",
    marginTop: 20,
    height: 46,

    color: "#99ACC3",
    fontFamily: "Suisse Intl",
    fontSize: "12px",
    letterSpacing: "-0.24px",

    "& svg": {
      marginLeft: 4,
      fontSize: 20
    }
  }
}));

export default React.memo(HeaderMobile)
