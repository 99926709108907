import React from "react";
import {
  Box,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";

const NoResult = (props) => {
  const {
    loading,
    hideMessage
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img src={require("../../../../../../assets/image/logo/logo-small.png")} className={classes.icon}/>
      {!Boolean(hideMessage || loading) && (
        <Typography className={classes.text}>ПОЛЕ РЕЗУЛЬТАТА</Typography>
      )}
      {Boolean(loading) && (
        <Typography className={classes.text}>ЗАГРУЗКА РЕЗУЛЬТАТА</Typography>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,

    width: "100%",
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

    backgroundImage: "radial-gradient(rgb(0 0 0 / 10%) 20%, transparent 20%), radial-gradient(#fafafa 20%, transparent 20%)",
    backgroundColor: "#ffffff",
    backgroundPosition: "0 0, 150px 150px",
    backgroundSize: "5px 5px",
  },
  icon: {},
  text: {
    color: "#99ACC3",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "300",
    letterSpacing: "1px",
    textTransform: "uppercase",
  }
}));
NoResult.defaultProps = {
  hideMessage: false
}

export default React.memo(NoResult)
