import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Authorization as AuthorizationLayout,
  Cabinet as CabinetLayout,
  Landing as LandingLayout
} from "../layouts";
import {
  Profile as ProfilePage,
  NewTask as NewTaskPage,
  TaskView as TaskViewPage,
  History as HistoryPage,
  TaskBatch as TaskBatchPage,
  TaskBatchView as TaskBatchViewPage,

  MidjourneView as MidjourneyViewPage,
  MidjourneCreate as MidjourneyCreatePage,

  ChatAi as ChatAiPage,

  Welcome as WelcomePage,
  Saved as SavedPage,
  AccountConfirm as AccountConfirmPage,
  PersonalData as PersonalDataPage,
  UserAgreement as UserAgreementPage,
  PrivacyPolicy as PrivacyPolicyPage,

  Tariff as TariffPage, PersonalData,
} from "../pages";

const pages = [
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/personal-data',
    component: PersonalDataPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
  {
    path: '/user-agreement',
    component: UserAgreementPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },

  {
    path: '/',
    component: WelcomePage,
    layout: LandingLayout,
    rules: [],
    exact: true
  },
  {
    path: '/task/create',
    component: NewTaskPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/profile',
    component: ProfilePage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/task/:id',
    component: TaskViewPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/history',
    component: HistoryPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/task-batch',
    component: TaskBatchPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/task-batch/:id',
    component: TaskBatchViewPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },

  {
    path: '/midjourney/create',
    component: MidjourneyCreatePage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },
  {
    path: '/midjourney/:id',
    component: MidjourneyViewPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },

  {
    path: '/chat-ai',
    component: ChatAiPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },

  {
    path: '/saved',
    component: SavedPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },

  {
    path: '/tariff',
    component: TariffPage,
    layout: CabinetLayout,
    rules: [],
    exact: true
  },



  {
    path: '/account/confirm',
    component: AccountConfirmPage,
    layout: AuthorizationLayout,
    rules: [],
    exact: true
  },
];

const AuthRoute = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/task/create"/>
    </Switch>
  );
};

export default AuthRoute;
