// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Profile from './Profile';
import {updateUserProfiles} from "../../../../states/global";

export default compose(
  connect(
    state => ({
      account: state?.global?.accountProfile
    }),
    dispatch => ({
      updateUserProfiles: () => dispatch(updateUserProfiles())
    }),
  ),
)(Profile);
