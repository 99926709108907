import React from "react";
import {
	Tooltip,
	FormControl, FormHelperText,
	Grid, MenuItem, Select, Typography
} from "@mui/material";
import {
	Help as HelpIcon
} from "@mui/icons-material";
import SelectAiModel from "./SelectAiModel";
import variants from "./variants.json";
import {palette} from "../../theme/common";
import {caseWords} from "../../helpers/caseWords";

const SettingsPc = (props) => {
	const {
		tones,
		values,
		errors,
		touched,
		aiModels,
		languages,
		creativity,
		onChange,
		onMessageNumberOfOptions
	} = props;
	const isAiModels = Boolean(aiModels.length > 0);

	const _messageNumberOfOptions = () => {
		return onMessageNumberOfOptions
	}

	return (
		<Grid container columnSpacing="12px" rowSpacing={{xs: "8px", md: "18px"}} mb="auto">
			<Grid item xs={12} md={6}>
				<Typography variant="formLabel" mb="8px">Выберите язык</Typography>
				<FormControl fullWidth error={Boolean(touched.language && errors.language)}>
					<Select
						value={values.language}
						name="language"
						onChange={onChange}
					>
						{languages.map((lang) => (
							<MenuItem key={`languages-${lang.code}`} value={lang.code}>
								{lang.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{Boolean(touched.language && errors.language) && (
					<FormHelperText error={true}>{touched.language && errors.language}</FormHelperText>
				)}
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography variant="formLabel" mb="8px">Выберите тон</Typography>
				<FormControl fullWidth error={Boolean(touched.tone && errors.tone)}>
					<Select
						value={values.tone}
						name="tone"
						onChange={onChange}
					>
						{tones.map((tone) => (
							<MenuItem key={`tone-${tone.id}`} value={tone.id}>
								{tone.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{Boolean(touched.tone && errors.tone) && (
					<FormHelperText error={true}>{touched.tone && errors.tone}</FormHelperText>
				)}
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography variant="formLabel" mb="8px">Уровень креативности</Typography>
				<FormControl fullWidth error={Boolean(touched.creativity && errors.creativity)}>
					<Select
						value={values.creativity}
						name="creativity"
						onChange={onChange}
					>
						{creativity.map((creativity) => (
							<MenuItem key={`creativity-${creativity.id}`} value={creativity.id}>
								{creativity.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{Boolean(touched.creativity && errors.creativity) && (
					<FormHelperText error={true}>{touched.creativity && errors.creativity}</FormHelperText>
				)}
			</Grid>
			<VisibleContent visible={isAiModels}>
				<Grid item xs={12} md={6} className="select-ai-model">
					<SelectAiModel
						value={values.ai_type_id}
						error={errors.ai_type_id}
						aiModels={aiModels}
						touched={touched.ai_type_id}
						onChange={onChange}
					/>
				</Grid>
			</VisibleContent>
			<Grid item xs={12} md={isAiModels ? 12 : 6} className="select-number-options">
				<Typography variant="formLabel" mb="8px">Количество вариантов</Typography>
				<FormControl fullWidth error={Boolean(touched.number_of_options && errors.number_of_options)}>
					<Select
						value={values.number_of_options}
						name="number_of_options"
						onChange={onChange}
					>
						{variants.options.map((option) => (
							<MenuItem
								key={`variant-${option.value}`}
								disabled={Boolean(!option.value)}
								value={option.value}
							>{option.label}</MenuItem>
						))}
					</Select>
				</FormControl>
				{Boolean(touched.number_of_options && errors.number_of_options) && (
					<FormHelperText error={true}>{touched.number_of_options && errors.number_of_options}</FormHelperText>
				)}
				<FormHelperText
					sx={{color: palette.text.lightGray}}
					dangerouslySetInnerHTML={{ __html: _messageNumberOfOptions() }}
				/>
			</Grid>
		</Grid>
	)
}
const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
})

export default SettingsPc
