import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  TextField,
  Typography,
  IconButton,
  CircularProgress
} from "@mui/material";
import {
  makeStyles,
  withStyles
} from "@mui/styles";
import {
  Formik
} from "formik";
import {
  palette
} from "../../../theme/common";
import {
  Link
} from "react-router-dom";
import {
  Notification,
  notificationTypes
} from "../../../common/Notification";
import * as Yup from "yup";
import agent from "../../../agent/agent";

const initForm = {
  email: "",
  password: "",
};

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBackdrop: false
    };
    this.refFormik = React.createRef();
  }

  componentDidMount = () => {
  }

  checkUserConfirm = async () => {
    return true

    const res = await agent.get(`/accounts/current`).then((res) => {
      return res.data
    }).catch((err) => {
      return null
    });
    if (!res) {
      return true
    }
    if (res.email_verification) {
      return true
    }

    agent.defaults.headers['Authorization'] = '';
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    this.props.setUserProfile(null);
    this.props.history.push(`/registration/confirm`);
    this.setState({ isLoading: false });

    return false
  }

  changeForm = ({ target }) => {
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  loginSubmit = async (form) => {
    this.setState({ isBackdrop: true });

    const res = await agent.post('/auth/login', form).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    })
    if (res?.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: res?.error?.data?.message || "Ошибка сервера",
        type: notificationTypes.error
      })
      return
    }

    agent.defaults.headers['Authorization'] = `Bearer ${res.access_token}`;

    const isEmailConfirm = await this.checkUserConfirm();
    if (!isEmailConfirm) {
      return
    }

    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('refresh_token', res.refresh_token);

    await this.props.initDirectory();
    const profile = await agent.get('/accounts/profile').then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!profile) {
      this.setState({ isBackdrop: false });

      Notification({
        message: "Профиль не найден",
        type: notificationTypes.error
      })

      agent.defaults.headers['Authorization'] = '';
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      return
    }

    await this.props.setUserProfile(profile);
    this.setState({ isBackdrop: false });
  }

  authGoogle = async (provider) => {
    this.setState({ isBackdrop: true });

    const res = await agent.get(`/auth/o/${provider}`).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });
      return
    }
    this.setState({ isBackdrop: false });
    window.location.href = res?.redirect_url;
  }

  resetPassword = async () => {}

  render () {
    const {
      classes
    } = this.props;
    const {
      isBackdrop
    } = this.state;

    return (
      <Box className={classes.root}>
        <Box className={classes.logo}>
          <img src={require('../../../assets/image/logo/logo-text-shadow.svg').default} alt="logo"/>
        </Box>
        <Formik
          innerRef={this.refFormik}
          initialValues={initForm}
          validationSchema={validationSchema}
          onSubmit={this.loginSubmit}
        >{(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit
          } = props;

          return (
            <Box className={classes.form}>
              <Typography className={classes.formTitle}>Войти в сервис</Typography>
              <Grid container spacing="28px">
                <Grid item xs={12}>
                  <FormElement
                    value={values.email}
                    name="email"
                    title="Ваш логин/почта"
                    placeholder="Ваш логин/почта"
                    helpMessage=""
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormElement
                    value={values.password}
                    name="password"
                    title="Ваш пароль"
                    placeholder="Ваш пароль"
                    helpMessage_="Забыли пароль?"
                    type="password"
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={handleSubmit}>
                    Войти
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" textAlign="center">
                    Нет аккаунта? <Link to="/registration">Зарегистрироваться</Link>
                  </Typography>
                  <Typography variant="subtitle1" textAlign="center" mt={1}>
                    Забыли пароль? <Link to="/restore-password">Восстановить</Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center" mt={1}>
                <Grid item>
                  <ButtonAuth
                    icon={require('../../../assets/image/auth/google.svg').default}
                    classes={classes}
                    onClick={this.authGoogle.bind(this, 'google')}
                  />
                </Grid>
                <Grid item>
                  <ButtonAuth
                    icon={require('../../../assets/image/auth/yandex.svg').default}
                    classes={classes}
                    onClick={this.authGoogle.bind(this, 'yandex')}
                  />
                </Grid>
              </Grid>
            </Box>
          )
        }}</Formik>


        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Box>
    );
  }
}
const ButtonAuth = React.memo((props) => {
  const {
    icon,
    classes,
    onClick
  } = props;

  return (
    <IconButton
      className={classes.authButton}
      onClick={onClick}
    >
      <img src={icon}/>
    </IconButton>
  )
})
const FormElement = React.memo((props) => {
  const {
    title,
    placeholder,
    helpMessage,

    ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="formLabel" mb="8px">{ title }</Typography>
        </Grid>
        {Boolean(helpMessage) && (
          <Grid item>
            <Typography variant="formLabel" mb="8px" className={classes.buttonHelp} style={{color: palette.text.lightGray}}>{ helpMessage }</Typography>
          </Grid>
        )}
      </Grid>
      <TextField
        placeholder={placeholder}
        fullWidth
        {...otherProps}
      />
    </Box>
  )
});

const useStyles = makeStyles(() => ({
  buttonHelp: {}
}));
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
  password: Yup.string().required('Заполните поле'),
});

const style = {
  root: {
    padding: "50px 0",
    boxSizing: "border-box",
    overflow: "auto"
  },
  logo: {
    width: "115px",
    height: "115px",
    position: "relative",
    margin: "0 auto",
    marginBottom: "48px",

    "& img": {
      maxWidth: "100%",
      position: "absolute",
      top: "0", left: "50%",
      transform: "translateX(-50%)"
    }
  },

  form: {
    margin: "0 auto",
    width: "100%",
    maxWidth: 400,
    padding: "28px",
    boxSizing: "border-box",
    borderRadius: 16,
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
  },
  formTitle: {
    marginBottom: "24px",

    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    color: palette.primary.text
  },
  formElement: {},

  authButton: {
    width: 48,
    height: 48,

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  }
};
Authorization = withStyles(style)(Authorization);

export { FormElement };
export default Authorization
