import React from "react";
import {
	Box,
	Button,
	Select,
	Divider,
	Backdrop,
	MenuItem,
	Typography,
	FormControl,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Notification,
	notificationTypes
} from "../../../../../../common/Notification";
import {compose} from "recompose";
import {connect} from "react-redux";
import {downloadFile} from "../../../../../../common/Files";
import TabsTypeScenarios from "../../../../../../components/ScenariosForm/TabsTypeScenarios";
import agent from "../../../../../../agent/agent";
import {withRouter} from "react-router-dom";

class ActionContent extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			scenarioId: null,
			uploadFile: null,

			activeStage: 1,

			isBackdrop: false,
			isFullInit: false,
		};
	}

	componentDidMount = async () => {
		await this.initScenarioId();
		await this.checkStatusTasks();

		this.setState({ isFullInit: true })
	}

	checkStatusTasks = async () => {
		const { batchTask } = this.props;
		if (!batchTask) {
			return null
		}

		const isAllTaskDone = Boolean((batchTask?.tasks || []).filter((t) => t.status === 'success').length === (batchTask?.tasks || []).length);

		this.setState({
			uploadFile: {name: batchTask?.filename},
			activeStage: isAllTaskDone ? 5 : 4
		})
	}

	initScenarioId = async () => {
		const { scenarios, initScenarioId } = this.props;

		if (initScenarioId) {
			await this.setState({ scenarioId: initScenarioId })
			return
		}

		const defaultScenario = scenarios.find((t) => t.default);
		if (!!defaultScenario) {
			await this.setState({ scenarioId: defaultScenario.id })
			return
		}

		await this.setState({ scenarioId: scenarios[0]?.id });
	}
	changeScenarioId = ({ target }) => {
		const { value } = target;
		this.setState({
			scenarioId: value,
			activeStage: 1,
			uploadFile: null
		});
	}

	downloadReferenceFile = async () => {
		if (this.state.activeStage > 1) {
			return null
		}

		this.setState({ isBackdrop: true });
		const { scenarioId } = this.state;
		const resFile = await downloadFile(`/batch/template/scenarios/${ scenarioId }`);
		if (resFile?.error) {
			this.setState({ isBackdrop: false });
			Notification({
				type: notificationTypes.error,
				message: resFile.error?.message || "Ошибка сервера"
			})
			return
		}

		this.setState({ isBackdrop: false });
		Notification({
			message: "Эталонный файл скачан",
			type: notificationTypes.success
		});

		this.changeActiveStage(2);
	}
	changeReadyReferenceFile = (event) => {
		const file = event.target.files[0];
		if (!file) {
			return
		}

		this.setState({ uploadFile: file });
		event.target.value = null;

		this.changeActiveStage(3);
	}
	uploadReadyReferenceFile = async () => {
		this.setState({ isBackdrop: true });

		const { uploadFile } = this.state;
		const formData = new FormData();
		formData.append('file', uploadFile);
		const res = await agent.post('/batch/scenarios', formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res?.error) {
			this.setState({ isBackdrop: false });
			Notification({
				type: notificationTypes.error,
				message: res?.error?.data?.message || 'Ошибка сервера'
			})
			return
		}

		this.setState({ isBackdrop: false });
		this.props.history.push(`/task-batch/${ res.batch_id }`);
	}

	startAllTask = async () => {
		this.setState({ isBackdrop: true });

		const { batchTask } = this.props;
		const tasks = batchTask?.tasks || [];

		let errors = {};
		let index = 0;
		for (const task of tasks) {
			index = index + 1;
			const res = await agent.post(`/batch/scenarios/${ batchTask.id }/tasks/${ task.id }/start`).then((res) => {
				return res.data
			}).catch((err) => {
				return {error: err.response}
			});
			if (res?.error) {
				errors[task.id] = {
					title: `Задача №${ index }`,
					errorMessage: res?.error?.data?.message || 'Ошибка сервера'
				}
			}
		}
		if (Object.keys(errors || {}).length > 0) {
			let message = [];
			Object.keys(errors).map((errorKey) => {
				const _info = errors[errorKey];
				message.push(`${ _info?.title }: ${ _info?.errorMessage }`);
			});

			Notification({
				title: "В процессе генерации возникли ошибки по задачам",
				message: message.join(';</br>'),
				type: notificationTypes.error,
				duration: 9999
			});
			this.setState({
				isBackdrop: false,
				activeStage: 5
			});

			return
		}

		this.setState({
			isBackdrop: false,
			activeStage: 5
		});
		Notification({
			message: "Задачи поставлены в очередь генерации",
			type: notificationTypes.success
		})
	}

	downloadReadyFile = async () => {
		this.setState({ isBackdrop: true });

		const { batchTask } = this.props;
		const resFile = await downloadFile(`/batch/scenarios/${ batchTask.id }/result`);

		this.setState({ isBackdrop: false });
	}

	changeActiveStage = (activeStage) => {
		this.setState({ activeStage });
	}
	_stage4Message = () => {
		const { batchTask } = this.props;

		const tasks = batchTask?.tasks || [];
		const createdCountTask = tasks.filter((t) => t.status === 'created').length;

		if (createdCountTask > 0) {
			return 'Идет процесс генерации текстов'
		}
		return 'Текст успешно сгенерирован, вы можете их скачать по кнопке ниже.'
	}
	_visibleButtonDownloadReady = () => {
		const { batchTask } = this.props;
		const tasks = batchTask?.tasks || [];
		const success = tasks.filter((t) => t.status === 'success').length;
		return Boolean(tasks.length === success)
	}

	render () {
		const {
			classes,
			scenarios
		} = this.props;
		const {
			scenarioId,
			uploadFile,
			activeStage,
			isFullInit,
			isBackdrop
		} = this.state;

		if (!isFullInit) {
			return null
		}
		return (
			<Box className={classes.root}>

				<TabsTypeScenarios value="multi"/>

				<SelectScenarioId
					value={scenarioId}
					options={scenarios}
					onChange={this.changeScenarioId}
				/>

				<Divider className={classes.divider}/>

				{/* START STAGE 1 */}
				<VisibleContent visible={Boolean(activeStage >= 1)}>
					<Typography className={classes.stageNumber}>Этап 1</Typography>
					<Typography className={classes.stageTitle} onClick={this.downloadReferenceFile}>
						Скачайте эталонный <span className="--link">файл Excel</span>
					</Typography>
					<Typography className={classes.stageMessage} sx={{marginTop: "6px"}}>
						Заполните колонки в xls/csv-файле вашей информацией
					</Typography>
					<Box mt="8px">
						<Button disabled={Boolean(activeStage >= 2)} className={classes.stageButtonSmall} onClick={this.changeActiveStage.bind(this, 2)}>
							Уже скачал
						</Button>
					</Box>
				</VisibleContent>
				{/* END STAGE 1 */}

				{/* START STAGE 2 */}
				<VisibleContent visible={Boolean(activeStage >= 2)}>
					<Box mt={6}/>
					<Typography className={classes.stageNumber}>Этап 2</Typography>
					<Box mt={1}/>
					<VisibleContent visible={!Boolean(uploadFile)}>
						<label>
							<Button component="span" variant="contained" className={classes.stageButtonMedium}>
								Загрузить файл с вашими данными
							</Button>
							<input accept=".xlsx" type="file" onChange={this.changeReadyReferenceFile} hidden/>
						</label>
					</VisibleContent>
					<VisibleContent visible={Boolean(uploadFile)}>
						<Typography className={classes.stageTitle}>
							Вы загрузили файл <span className="--blue">{uploadFile?.name}</span>
						</Typography>
						<Box mt={1}/>
						<label>
							<Button component="span" className={classes.stageButtonSmall}>
								Загрузить другой файл
							</Button>
							<input accept=".xlsx" type="file" onChange={this.changeReadyReferenceFile} hidden/>
						</label>
					</VisibleContent>
				</VisibleContent>
				{/* END STAGE 2 */}

				{/* START STAGE 3 */}
				<VisibleContent visible={Boolean(activeStage >= 3)}>
					<Box mt={6}/>
					<Typography className={classes.stageNumber}>Этап 3</Typography>
					<Box mt={1}/>
					<VisibleContent visible={Boolean(Boolean(activeStage === 3))}>
						<Button
							variant="contained"
							className={classes.stageButtonMedium}
							onClick={this.uploadReadyReferenceFile}
						>
							Обработать файл «{ uploadFile?.name }»
						</Button>
					</VisibleContent>
					<VisibleContent visible={Boolean(Boolean(activeStage > 3))}>
						<Typography className={classes.infoProcessedFile}>
							Обработанный файл «{uploadFile?.name}»
						</Typography>
					</VisibleContent>
				</VisibleContent>
				{/* END STAGE 3 */}

				{/* START STAGE 4 */}
				<VisibleContent visible={Boolean(activeStage >= 4)}>
					<Box mt={6}/>
					<Typography className={classes.stageNumber}>Этап 4</Typography>
					<VisibleContent visible={Boolean(activeStage === 4)}>
						<Box mt={1}>
							<Button variant="contained" className={classes.stageButtonMedium} onClick={this.startAllTask}>
								Сгенерировать описание
							</Button>
						</Box>
					</VisibleContent>
					<VisibleContent visible={Boolean(activeStage > 4)}>
						<Typography className={classes.stageTitle}>
							{this._stage4Message()}
						</Typography>
					</VisibleContent>
				</VisibleContent>
				{/* END STAGE 4 */}

				<VisibleContent visible={Boolean(activeStage >= 5 && this._visibleButtonDownloadReady())}>
					<Box mt={6}/>
					<Button variant="contained" fullWidth onClick={this.downloadReadyFile}>
						Скачать обработанный файл XLS
					</Button>
				</VisibleContent>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Box>
		)
	}
}
const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
});
const SelectScenarioId = React.memo((props) => {
	const {
		value,
		options,
		onChange
	} = props;

	return (
		<>
			<Typography variant="formLabel" mb="8px">Выберите варианты использования</Typography>
			<FormControl fullWidth>
				<Select
					value={value}
					name="scenario"
					onChange={onChange}
				>
					{options.map((scenario) => (
						<MenuItem key={`scenarios-${scenario.id}`} value={scenario.id}>
							{scenario.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
});

const styles = {
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		flex: 1,
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "#FFF",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
		padding: "24px 20px",

		"@media(max-width: 1199px)": {
			padding: "20px 16px",
		}
	},
	divider: {
		margin: "20px -20px",
		"@media(max-width: 1199px)": {
			margin: "10px -16px",
		}
	},

	stageNumber: {
		fontWeight: "700",
		fontSize: "16px",
		lineHeight: "135%",
		leadingTrim: "both",
		textEdge: "cap",
		letterSpacing: "-0.02em",
		color: "#126DDE",
	},
	stageTitle: {
		marginTop: 16,

		fontSize: "16px",
		fontWeight: "400",
		lineHeight: "22px",
		letterSpacing: "-0.02em",
		color: "#2C4058",

		"& .--link": {
			cursor: "pointer",
			color: "#126DDE",
			textDecoration: "underline",
			"&:hover": {
				textDecorationColor: "transparent"
			}
		},
		"& .--blue": {
			color: "#126DDE",
			wordBreak: "break-all",
		},
	},
	stageMessage: {
		fontSize: "12px",
		lineHeight: "16px",
		letterSpacing: "-0.02em",
		color: "#2C4058"
	},
	stageButtonSmall: {
		padding: "5px 16px",
		fontSize: "12px",
		lineHeight: "14px",
		background: "#e8f1fc",
		color: "#126DDE",
		border: "none",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
	},
	stageButtonMedium: {
		padding: "8px 24px",
		fontSize: "14px",
		lineHeight: "16px",
		wordBreak: "break-all",
	},

	infoProcessedFile: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "12px 24px",
		border: "1px solid #126DDE",
		filter: "drop-shadow(0px 1px 2.5px rgba(0, 0, 0, 0.1))",
		borderRadius: "81px",
		wordBreak: "break-all",

		fontWeight: "500",
		fontSize: "14px",
		lineHeight: "16px",
		textAlign: "center",
		letterSpacing: "-0.02em",
		color: "#126DDE",
	}
};
ActionContent = withStyles(styles)(ActionContent);
ActionContent = withRouter(ActionContent);

export default compose(
	connect(
		state => ({
			scenarios: (state.directory?.scenarios || []).filter((t) => Boolean(t?.settings?.available_group)),
		}),
		dispatch => ({}),
	),
)(ActionContent);
