import React from 'react';
import s from './consumers.module.css';
import studentIcon from '../../../../../assets/image/welcome/student.svg';
import lampIcon from '../../../../../assets/image/welcome/lamp.svg';
import hrIcon from '../../../../../assets/image/welcome/hr.svg';
import coderIcon from '../../../../../assets/image/welcome/coder.svg';
import designIcon from '../../../../../assets/image/welcome/design.svg';
import copyrightIcon from '../../../../../assets/image/welcome/copyright.svg';
import {Container} from "@mui/material";

const Consumers = () => {
    return (
        <div id='consumers' className={s.consumers}>
            <Container maxWidth='xl'>
                <div className="welcome-title">Для кого будет полезно</div>
            </Container>
            <div className={s.consumersInner}>
                <div className={`${s.consumersItem} ${s.blue} ${s.consumersItemSm} ${s.item1}`}>
                    <img src={hrIcon} alt="coder"/>
                        <h3>HR специалисты</h3>
                        <span>Описание вакансий,создание тестовых заданий</span>
                </div>

                <div className={`${s.consumersItem} ${s.aqua} ${s.item2}`}>
                    <img src={coderIcon} alt="coder"/>
                    <h3>Программисты</h3>
                    <span>Написать часть кода, найти оптимальное решение</span>
                </div>

                <div className={`${s.consumersItem} ${s.consumersItemMd} ${s.green} ${s.item3}`}>
                    <img src={designIcon} alt="coder"/>
                    <h3>Дизайнеры</h3>
                    <span>Идею для логотипа и дизайна, баннеры</span>
                </div>

                <div className={`${s.consumersItem} ${s.lightBlue} ${s.item4}`}>
                    <img src={copyrightIcon} alt="coder"/>
                    <h3>Копирайтеры</h3>
                    <span>Статьи, посты, идеи для промо акций</span>
                </div>

                <div className={`${s.consumersItem} ${s.consumersItemSm} ${s.green} ${s.item5}`}>
                    <img src={designIcon} alt="coder"/>
                    <h3>Дизайнеры</h3>
                    <span>Идею для логотипа и дизайна, баннеры</span>
                </div>

                <div className={`${s.consumersItem} ${s.violet} ${s.item6}`}>
                    <img src={studentIcon} alt="coder"/>
                    <h3>Студенты</h3>
                    <span>Создание рефератов, поиск ответов на любые вопросы</span>
                </div>

                <div className={`${s.consumersItem} ${s.lightAqua} ${s.item7}`}>
                    <img src={lampIcon} alt="coder"/>
                    <h3>Маркетологи</h3>
                    <span>Придумать УТП, рекламные заголовки</span>
                </div>

                <div className={`${s.consumersItem} ${s.blue} ${s.consumersItemMd} ${s.item8}`}>
                    <img src={hrIcon} alt="coder"/>
                    <h3>HR специалисты</h3>
                    <span>Описание вакансий,создание тестовых заданий</span>
                </div>

                <div className={`${s.consumersItem} ${s.violet} ${s.item9}`}>
                    <img src={studentIcon} alt="coder"/>
                    <h3>Студенты</h3>
                    <span>Создание рефератов, поиск ответов на любые вопросы</span>
                </div>

                <div className={`${s.consumersItem} ${s.blue} ${s.item10}`}>
                    <img src={hrIcon} alt="coder"/>
                    <h3>HR специалисты</h3>
                    <span>Описание вакансий,создание тестовых заданий</span>
                </div>

                <div className={`${s.consumersItem} ${s.blue} ${s.item11}`}>
                    <img src={hrIcon} alt="coder"/>
                    <h3>HR специалисты</h3>
                    <span>Описание вакансий,создание тестовых заданий</span>
                </div>

                <div className={`${s.consumersItem} ${s.green} ${s.item12}`}>
                    <img src={designIcon} alt="coder"/>
                    <h3>Дизайнеры</h3>
                    <span>Идею для логотипа и дизайна, баннеры</span>
                </div>

                <div className={`${s.consumersItem} ${s.lightBlue} ${s.item13}`}>
                    <img src={copyrightIcon} alt="coder"/>
                    <h3>Копирайтеры</h3>
                    <span>Статьи, посты, идеи для промо акций</span>
                </div>

                <div className={`${s.consumersItem} ${s.consumersItemMd} ${s.aqua} ${s.item14}`}>
                    <img src={coderIcon} alt="coder"/>
                    <h3>Программисты</h3>
                    <span>Написать часть кода, найти оптимальное решение</span>
                </div>
            </div>
            <Container maxWidth='xl'>
                <div className={s.subtitle}>
                    <div className={s.title}>Используй современные технологии искусственного интеллекта для своих целей <span>БЕСПЛАТНО</span></div>
                </div>
            </Container>
        </div>
    );
};

export default Consumers;
