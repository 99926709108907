import React, {Component} from "react";
import {
	Box,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";

class ChatAi extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount = () => {
	}

	render() {
		const {
			classes
		} = this.props;

		return (
			<Box className={classes.root}>
				<Typography className={classes.title}>В разработке.</Typography>
				<Typography className={classes.message}>
					Для начала диалога с ИИ вы можете воспользоваться нашим ботом в Телеграм по ссылке или отсканировать QR<br/>
					<a href="https://t.me/AISynergy_GPTBot?start=aisyweb"
					   target="_blank">https://t.me/AISynergy_GPTBot?start=aisyweb</a><br/>
				</Typography>
				<Box className={classes.qrCode}>
					<img src={require("../../../../assets/image/logo/qr-code.jpg")}/>
				</Box>
			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "#FFF",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
		padding: 64,
		boxSizing: "border-box",

    "@media(max-width: 859px)": {
      padding: 20
    }
	},
	title: {
		marginBottom: 32,

		fontSize: "32px",
		lineHeight: "36px",
		color: "#22252D",
		fontWeight: "500",

    "@media(max-width: 859px)": {
      marginBottom: 16,

      fontSize: "20px",
      lineHeight: "24px",
    }
	},
	message: {
		maxWidth: 640,

		fontSize: 20,
		lineHeight: "32px",
		color: "#3C4555",
		textAlign: "center",

    "@media(max-width: 859px)": {
      fontSize: "16px",
      lineHeight: "20px",
    }
	},
	qrCode: {
		borderRadius: 16,
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "#FFF",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
		position: "relative",
		overflow: "hidden",
		width: 320,
		height: 320,
		padding: 10,
		boxSizing: "border-box",
		marginTop: 20,

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "contain"
		}
	},
};
ChatAi = withStyles(styles)(ChatAi);

export default ChatAi
