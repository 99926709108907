import React, {Component} from "react";
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import Tasks from "./components/Tasks/Tasks";
import Consumers from "./components/Consumers/Consumers";
import Registration from "./components/Registration/Registration";
import Cases from "./components/Cases/Cases";
import Pricing from "./components/Pricing/Pricing";
import Answers from "./components/Answers/Answers";
import Feedback from "./components/Feedback/Feedback";
import Footer from "./components/Footer/Footer";
import Usage from "./components/Usage/Usage";

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    await new Promise(resolve => setTimeout(resolve, 500))
    this.initScrollSection();
  }

  initScrollSection = () => {
    const hash = window?.location?.hash;
    if (!hash) {
      return
    }

    const section = document.getElementById(hash.replace('#', ''));
    if (!section) {
      return;
    }

    const topPosition = section.offsetTop;
    window.scrollTo({
      top: topPosition
    })
  }

  render() {
    return (
      <>
        <Banner/>
        <Tasks/>
        <Consumers/>
        <Registration/>
        <Cases/>
        <Pricing/>
        <Answers/>
        <Feedback/>
        <Usage/>
        <Footer/>
      </>
    );
  }
}

export default Welcome
