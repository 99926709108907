import React, { Component } from "react";
import {} from "@mui/material";

class HomeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {}

    render() {
        return (
            <>

            </>
        );
    }
}

export default HomeScreen
