import React, {Component} from "react";
import {
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Filter as FilterComponent,
  HistoryText as HistoryTextComponent,
  HistoryBatch as HistoryBatchComponent,
  HistoryMidjourney as HistoryMidjourneyComponent
} from "./components";
import agent from "../../../../agent/agent";

const countOffset = 20;
class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyText: [],
      historyBatch: [],
      historyMidjourney: [],

      filterText: {
        page: 1
      },
      filterBatch: {
        page: 1
      },
      filterMidjourney: {
        page: 1
      },

      visibleContent: "text",

      totalPagesText: 1,
      totalPagesBatch: 1,
      totalPagesMidjourney: 1,

      isLoadText: true,
      isLoadBatch: true,
      isLoadMidjourney: true,
    };
  }

  componentDidMount = async () =>  {
    await this.getHistoryText();
    await this.getHistoryBatch();
    await this.getHistoryMidjourney();
  }

  getHistoryText = async () => {
    this.setState({isLoadText: true});
    const scrollBody = document.getElementById("scrollBody");
    scrollBody.scroll(0, 0);
    const filter = this._getFilter(this.state.filterText);
    const res = await agent.get(`/history/scenarios?${ filter }`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    await this.setState({
      historyText: res?.user_scenarios || [],
      totalPagesText: Math.ceil((res?.total || 0) / countOffset),
      isLoadText: false
    });
  }
  getHistoryBatch = async () => {
    this.setState({isLoadBatch: true});
    const scrollBody = document.getElementById("scrollBody");
    scrollBody.scroll(0, 0);
    const filter = this._getFilter(this.state.filterBatch);
    const res = await agent.get(`/batch/scenarios?${ filter }`).then((res) => {
      return res?.data || {}
    }).catch(() => {
      return {}
    });
    await this.setState({
      historyBatch: res?.user_scenario_batch || [],
      totalPagesBatch: Math.ceil((res?.total || 0) / countOffset),
      isLoadBatch: false
    });
  }
  getHistoryMidjourney = async () => {
    this.setState({isLoadMidjourney: true});
    const scrollBody = document.getElementById("scrollBody");
    scrollBody.scroll(0, 0);
    const filter = this._getFilter(this.state.filterMidjourney);
    const res = await agent.get(`/history/midjourney?${ filter }`).then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    const historyMidjourney = res?.tasks || [];

    await this.setState({
      historyMidjourney: historyMidjourney,
      totalPagesMidjourney: Math.ceil((res?.total || 0) / countOffset),
      isLoadMidjourney: false
    });
  }

  changeFilterText = async (filter) => {
    await this.setState({filterText: filter});
    await this.getHistoryText();
  }
  changeFilterBatch = async (filter) => {
    await this.setState({filterBatch: filter});
    await this.getHistoryBatch();
  }
  changeFilterMidjourney = async (filter) => {
    await this.setState({filterMidjourney: filter});
    await this.getHistoryMidjourney();
  }

  changeVisibleContent = (event, visibleContent) => {
    this.setState({visibleContent})
  }

  _getFilter = (filterObject) => {
    let str = [
      `limit=${countOffset}`,
      `offset=${countOffset * (filterObject.page - 1)}`
    ];
    return str.join("&")
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      historyText,
      filterText,
      totalPagesText,
      isLoadText,

      historyBatch,
      filterBatch,
      totalPagesBatch,
      isLoadBatch,

      historyMidjourney,
      filterMidjourney,
      totalPagesMidjourney,
      isLoadMidjourney,

      visibleContent
    } = this.state;
    const {
      scenarios
    } = this.props;

    return (
      <>

        <Grid container spacing={2} alignItems="flex-end" mb="12px">
          <Grid item>
            <Typography variant="h1">История запросов</Typography>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={visibleContent}
              exclusive
              className={classes.toggleButtonGroup}
              onChange={this.changeVisibleContent}
            >
              <ToggleButton value="text">Генерация текстов</ToggleButton>
              <ToggleButton value="midjourney">Генерация изображений</ToggleButton>
              <ToggleButton value="batch">Пакетная генерация текстов</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        <VisibleContent visible={Boolean(visibleContent === "text")}>
          <HistoryTextComponent
            data={historyText}
            filter={filterText}
            total={totalPagesText}
            isLoad={isLoadText}

            onChangeFilter={this.changeFilterText}
          />
        </VisibleContent>
        <VisibleContent visible={Boolean(visibleContent === "batch")}>
          <HistoryBatchComponent
            data={historyBatch}
            filter={filterBatch}
            total={totalPagesBatch}
            isLoad={isLoadBatch}
            scenarios={scenarios}

            onChangeFilter={this.changeFilterBatch}
          />
        </VisibleContent>
        <VisibleContent visible={Boolean(visibleContent === "midjourney")}>
          <HistoryMidjourneyComponent
            data={historyMidjourney}
            filter={filterMidjourney}
            total={totalPagesMidjourney}
            isLoad={isLoadMidjourney}

            onChangeFilter={this.changeFilterMidjourney}
          />
        </VisibleContent>

      </>
    );
  }
}
const VisibleContent = React.memo(({children, visible}) => {
  if (!visible) {
    return null
  }
  return children
})

const styles = {
  toggleButtonGroup: {
    "& .MuiButtonBase-root": {
      padding: "5px 10px",
      backgroundColor: "rgba(255, 255, 255, 0.40)",
      backdropFilter: "blur(20px)",

      fontSize: 12,
      lineHeight: "14px",
      textTransform: "initial",
      color: "#126DDE"
    },
    "& .Mui-selected": {
      backgroundColor: "#126DDE",
      borderColor: "#126DDE",
      color: "white",
    }
  }
};
History = withStyles(styles)(History);

export default History
