const downloadFile = async (fileLink) => {
	const urlAction = [process.env.REACT_APP_HOST_API, fileLink].join('');

	const response = await fetch(urlAction, {
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
		}
	});
	if (!response.ok) {
		return {error: await response.json()};
	}

	const disposition = response.headers.get('Content-Disposition');
	const fileName = _getFileName(disposition);

	const blob = await response.blob();
	const blobUrl = window.URL.createObjectURL(blob);

	const anchorElement = document.createElement('a');
	anchorElement.href = blobUrl;
	anchorElement.download = fileName;

	document.body.appendChild(anchorElement);
	anchorElement.click();
	window.URL.revokeObjectURL(blobUrl);
}
const _getFileName = (disposition) => {
	let fileName = ((disposition.split(";")?.[1]) || '').split('=')?.[1] || '';
	fileName = fileName.replace('UTF-8\'\'', '');
	fileName = decodeURI(fileName);
	return fileName
}

export {
	downloadFile
}
