import React from "react";
import {
  Box,
  Container,
  Typography
} from "@mui/material";
import {
  withStyles,
  makeStyles
} from "@mui/styles";
import {
  Header,
  HeaderMobile
} from "./components";
import clsx from "clsx";
import agent from "../../agent/agent";
import {compose} from "recompose";
import {connect} from "react-redux";
import {setUserProfile} from "../../states/global";
import {Link, withRouter} from "react-router-dom";
import {DialogNews} from "../../components";

const pathAvaibledNoVerification = [
  '/profile'
];

class Cabinet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  onLogout = async () => {
    await agent.get('/auth/logout');
    this.props.setUserProfile(null);
    agent.defaults.headers['Authorization'] = "";
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  render () {
    const {
      classes,
      global
    } = this.props;
    const matchPath = this.props?.match?.path;
    const isMailVerification = Boolean(global?.accountProfile?.email_verification);

    return (
      <Box
        className={clsx({
          [classes.root]: true,
          'background-pages': true,
        })}
      >
        <Box className={classes.header}>
          <VisibleContent visible={Boolean(window.innerWidth > 1023)}>
            <Header
              profile={global?.account?.profile || global?.accountProfile?.profile}
              onLogout={this.onLogout}
            />
          </VisibleContent>
          <VisibleContent visible={Boolean(window.innerWidth <= 1023)}>
            <HeaderMobile
              profile={global?.account?.profile || global?.accountProfile?.profile}
              onLogout={this.onLogout}
            />
          </VisibleContent>
        </Box>

        <Box id="scrollBody" className={clsx({
          [classes.body]: true,
          'scrollbar-body': true
        })}>
          <Container maxWidth="lg" sx={{flex: 1, display: "flex", flexDirection: "column"}}>
            {
              isMailVerification ?
                this.props.children :
                Boolean(pathAvaibledNoVerification.includes(matchPath)) ? this.props.children : <DisabledContentVerification/>
            }
          </Container>
        </Box>

        {Boolean(this.props.news.length > 0) && (
          <DialogNews
            news={this.props.news}
          />
        )}
      </Box>
    )
  }
}
const VisibleContent = React.memo(({ visible, children }) => {
  if (!visible) {
    return null
  }
  return children
})
const DisabledContentVerification = React.memo(() => {
  const classes = useStyles();

  return (
    <Box className={classes.contentError}>
      <Typography variant="h3">Аккаунт не подтвержден</Typography>
      <Typography variant="body1" sx={{color: "black"}} mt={1} textAlign="center">
        Для работы нужен подтвержденный аккаунт
      </Typography>
      <Typography color="text.primary" textAlign="center" mt={2}>
        <Link to="/profile">Подтвердить</Link>
      </Typography>
    </Box>
  )
})

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 5px 0 10px",
    boxSizing: "border-box",

    "@media(max-width: 859px)": {
      padding: "10px 0"
    }
  },
  header: {
    paddingRight: "10px",
    "@media(max-width: 859px)": {
      paddingRight: "0"
    }
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
    marginTop: 10,
    paddingBottom: "24px"
  }
};
Cabinet = withStyles(styles)(Cabinet);
Cabinet = withRouter(Cabinet);

const useStyles = makeStyles(() => ({
  contentError: {
    width: "100%",
    maxWidth: 640,
    padding: 24,
    margin: "0 auto",
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
  }
}));

export default compose(
  connect(
    state => ({
      global: state.global,
      news: state.global.news,
      account: state.global.accountProfile
    }),
    dispatch => ({
      setUserProfile: (val) => dispatch(setUserProfile(val))
    }),
  ),
)(Cabinet);
