import React from 'react';
import s from './footer.module.css';
import {Container} from "@mui/material";
import logo from '../../../../../assets/image/welcome/logo.svg';
import layer from '../../../../../assets/image/welcome/footer-img.jpg';

const Footer = () => {
    return (
        <div className={s.footer}>
            <img className={s.imgLayer} src={layer} alt="layer"/>
            <Container>
                <div className={s.footerInner}>
                    <div className={s.logo}>
                        <img src={logo} alt="logo"/>
                    </div>
                    <div className={s.title}>Быстрое решение повседневных задач в ChatGPT и MidJourney</div>
                   <Container maxWidth='md'>
                       <div className={s.footerNav}>
                           <a href="#tasks" className={s.footerNavLink}>Что мы можем</a>
                           <a href="#consumers" className={s.footerNavLink}>Для кого</a>
                           <a href="#cases" className={s.footerNavLink}>Кейсы</a>
                           <a href="#pricing" className={s.footerNavLink}>Тарифы</a>
                           <a href="#answers" className={s.footerNavLink}>Вопросы</a>
                           <a href="#usage" className={s.footerNavLink}>Как использовать</a>
                       </div>
                   </Container>

                    <Container maxWidth='sm'>
                        <div className={`${s.footerNav} ${s.footerNavExtra}`}>
                          <a className={s.footerNavLinkExtra} target="_blank" href="https://gpt.aisynergy.ru/">О нас</a>
                          <a className={s.footerNavLinkExtra} target="_blank" href="/privacy-policy">Политика конфиденциальности</a>
                          <a className={s.footerNavLinkExtra} target="_blank" href="/user-agreement">Пользовательское соглашение</a>
                        </div>
                    </Container>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
