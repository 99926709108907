import React, {Component} from "react";
import {
	Box,
	Grid,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	FormCreatePromt
} from "./components";
import {
	NoResult
} from "../../task/TaskCreate/components";
import {
	Notification,
	notificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import {DialogWarningEndRate} from "../../../../components";

class MidjourneCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isBackdrop: false
		};
		this.refDialogWarningEndRate = React.createRef();
	}

	componentDidMount = () => {
	}

	submitPrompt = async (prompt) => {
		this.setState({isBackdrop: true});

		const res = await agent.post('/midjourney/generate', {
			prompt
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response || true}
		});
		if (res.error) {
			this.setState({isBackdrop: false});
			if (res?.error?.data?.code === 4318) {
				this.refDialogWarningEndRate.current.open();
				return
			}
			Notification({
				type: notificationTypes.error,
				message: res?.error?.data?.message || "Ошибка сервера"
			})
			return
		}

		this.setState({isBackdrop: false});
		this.props.history.push(`/midjourney/${res?.id}`);
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			isBackdrop
		} = this.state;

		return (
			<Box className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={5}>
						<FormCreatePromt
							onSubmit={this.submitPrompt}
						/>
					</Grid>
					<Grid item xs={12} md={7} sx={{display: "flex"}}>
						<NoResult
							hideMessage={true}
						/>
					</Grid>
				</Grid>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
				<DialogWarningEndRate ref={this.refDialogWarningEndRate}/>
			</Box>
		);
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		overflow: "hidden"
	}
};
MidjourneCreate = withStyles(styles)(MidjourneCreate);

export default MidjourneCreate
