import React, {Component} from "react";
import {
  Box,
  Container,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
  }

  render () {
    const {
      classes
    } = this.props;

    return (
      <Box sx={{padding: "24px 0", overflow: "auto", height: "100lvh"}}>
        <Container maxWidth="lg">
          <Box className={classes.section}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<p align="center"><span style=""><span style=""><span style=""><strong>Согласие пользователя сайта</strong></span></span></span></p>
<p align="center"><span style=""><span style=""><span style=""><strong>на обработку персональных данных</strong></span></span></span></p>
<p align="justify">&nbsp;</p>
<p align="justify"><span style=""><span style=""><span style="">Настоящим в соответствии с Федеральным законом № 152-ФЗ &laquo;О персональных данных&raquo; от 27.07.2006, </span></span></span><span style=""><span style=""><span style=""><strong>Я, субъект персональных данных, именуемый в дальнейшем &laquo;Пользователь&raquo;,</strong></span></span></span><span style=""><span style=""><span style=""> во время использования мною веб-сайтов </span></span></span><span style="color: #0563c1;"><u><a href="https://vgserv.ru/"><span style=""><span style=""><strong>https://vgserv.ru</strong></span></span></a></u></span><span style=""><span style=""><span style=""><strong>, </strong></span></span></span><span style="color: #0563c1;"><u><a href="https://gpt.aisynergy.ru/"><span style=""><span style=""><strong>https://gpt.aisynergy.ru/</strong></span></span></a></u></span><span style=""><span style=""><span style=""><strong>, </strong></span></span></span><span style="color: #0563c1;"><u><a href="https://aisynergy.ru/"><span style=""><span style=""><strong>https://aisynergy.ru/</strong></span></span></a></u></span> <span style=""><span style=""><span style="">(далее - Сайт, Сервис), свободно, своей волей и в своем интересе выражаю полное, безоговорочное и однозначное согласие на обработку моих персональных данных (далее - Согласие) на следующих условиях:</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">1.</span></span></span><span style=""><span style=""><span style=""><strong> Согласие выдано Обществу с ограниченной ответственностью &laquo;Очень Хорошие Сервисы&raquo; (ООО &laquo;ОХС&raquo;)</strong></span></span></span><span style=""><span style=""><span style="">, ИНН 6670497950/ОГРН 1206600069125, </span></span></span><span style=""><span style=""><span style="">расположенному по адресу: 620075, Свердловская область, город Екатеринбург, улица Первомайская, 15, офис 301 (далее - Оператор).</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">2. </span></span></span><span style=""><span style=""><span style=""><strong>Согласие выдано на обработку следующих категорий персональных данных</strong></span></span></span><span style=""><span style=""><span style="">:</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- данных, указанных в специальных формах, расположенных на Сайте, путем заполнения соответствующих текстовых полей и/или прикрепленных к формам файлов, а именно:&nbsp;фамилия, имя, отчество, адрес электронной почты (e-mail), номер телефона, адрес места жительства, адрес места нахождения (пребывания), открытые для общего доступа профайлы в социальной сети, мои идентификаторы в интернет-мессенджерах; контактные данные моих представителей, другая аналогичная сообщенная информация, на основании которой возможна идентификация субъекта персональных данных;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- название и реквизиты документа, удостоверяющего личность (серия и номер, дата выдачи и название органа, выдавшего документ);</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- платёжные реквизиты: номер платёжной карты, номер электронного средства платежа, реквизиты банковского счёта;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- данных, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта (cookie-файлы), а именно: дата и время доступа, адрес посещаемой страницы, источник входа, реферер (адрес предыдущей страницы), информация о поведении (включая количество и наименование просмотренных страниц);</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- данные о моем оборудовании (данные о технических средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сайтом и его сервисами (в т.ч. вид операционной системы Пользователя, тип браузера, географическое положение, данные о провайдере и иное), об активности Пользователя при использовании Сайта, об информации об ошибках, выдаваемых Пользователю, о скачанных файлах, инструментах, а также иные данные, получаемые установленными настоящим согласием способами;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- IP адрес и статистика о IP-адресах.</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">3.</span></span></span><span style=""><span style=""><span style=""><strong> Согласие выдано на обработку персональных данных в целях:</strong></span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- предоставления доступа к Сайту, его содержанию, к функционалу сервиса, для администрирования Сайта;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- идентификации при регистрации на Сайте и/или при использовании Сайта;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- предоставления услуг и сервисов, доступных на Сайте, обработка запросов и заявок;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- установления обратной связи, включая направление уведомлений и запросов;&nbsp;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- подтверждения полноты предоставленных персональных данных;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- заключения договоров, осуществления взаиморасчетов;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- сбора Оператором статистики;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- улучшения качества работы Сайта и его сервисов, удобства их использования и разработки новых сервисов и услуг;</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">- проведения маркетинговых (рекламных) мероприятий, направления Оператором предложений, информационной и рекламной рассылки и получения их Пользователем для продвижения на рынке услуг Оператора, в том числе, путем осуществления прямых контактов;</span></span></span></p>
<p align="justify"><a name="_Hlk167098627"></a> <span style=""><span style="">- предоставления эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</span></span></p>
<p align="justify"><span style=""><span style=""><span style="">4.</span></span></span> <span style=""><span style=""><span style="">Согласие выдано на обработку персональных данных смешанным (автоматизированным и неавтоматизированным) способом</span></span></span> <span style=""><span style=""><span style="">с соблюдением мер, обеспечивающих их защиту от несанкционированного доступа.</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style=""><strong>В процессе обработки персональных данных Оператор вправе осуществлять:</strong></span></span></span><span style=""><span style=""><span style=""> сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных Пользователя</span></span></span>.</p>
<p align="justify"><span style=""><span style=""><span style="">5. Настоящим, Пользователь подтверждает, что:</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">5.1. Ознакомился </span></span></span><span style=""><span style="">с Политикой конфиденциальности, размещенной на Сайте/Сервисе Оператора.</span></span></p>
<p align="justify"><span style=""><span style=""><span style="">5.2. Ознакомлен и согласен с тем, что передача персональных данных Пользователя может осуществляться Оператором в объеме, </span></span></span><span style=""><span style=""><span style="">необходимом для получения Пользователем доступа к Сайту, его содержанию и его сервисам, третьим лицам, в случаях, когда предоставление таких данных является обязательным в соответствии с федеральным законом.</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">5.3. Проинформирован о возможности отзыва согласия </span></span></span><span style=""><span style=""><span style="">на основании положений Федерального закона от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo; путем личного обращения или направления письменного обращения в свободной форме заказным письмом с уведомлением о вручении в адрес Оператора или на следующий адрес электронной почты: </span></span></span><span style=""><span style="">info@aisynergy.ru</span></span><span style=""><span style=""><span style="">.</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">5.4. Дает согласие на получение рекламно-информационных материалов </span></span></span><span style=""><span style=""><span style="">и ознакомлен Оператором о возможности и порядке совершения отказа от таковой.</span></span></span></p>
<p align="justify"><a name="_GoBack"></a> <span style=""><span style=""><span style="">6. </span></span></span><span style=""><span style=""><span style=""><strong>Настоящее Согласие действует в течение 15 (пятнадцати) лет с даты его предоставления</strong></span></span></span> <span style=""><span style=""><span style=""><strong>Оператору</strong></span></span></span><span style=""><span style=""><span style=""> и может быть отозвано в любое время путем подачи Оператору заявления в порядке, указанном в п. 5.3. настоящего Согласия.</span></span></span></p>
<p align="justify"><span style=""><span style=""><span style="">7. Датой и временем формирования, подтверждения и предоставления Согласия</span></span></span><span style=""><span style=""><span style=""> прошу считать момент маркировки соответствующего поля и нажатия на соответствующую кнопку &laquo;ОТПРАВИТЬ&raquo; в Форме сбора персональных данных и/или нажатие на кнопку &laquo;ПРИНЯТЬ&raquo; в баннере о сборе cookie-файлов на любой странице Сайта.</span></span></span></p>
<p><br /> </p>`
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}

const styles = {
  section: {
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: "24px 20px",
    boxSizing: "border-box"
  }
};
PrivacyPolicy = withStyles(styles)(PrivacyPolicy);

export default PrivacyPolicy
