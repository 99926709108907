import React, {Component} from "react";
import {
	Box,
	Backdrop,
	CircularProgress, Typography, Grid, Button, TextField
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import {
	palette
} from "../../../theme/common";
import {Formik} from "formik";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import * as Yup from "yup";
import agent from "../../../agent/agent";

class RestorePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				email: "",
			},
			sendEmail: "",
			isBackdrop: false,
			isSuccessSend: false,
		};
		this.refFormik = React.createRef();
	}

	componentDidMount = () => {
	}

	changeForm = async ({ target }) => {
		const { name, value } = target;
		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}
	submit = async (body) => {
		this.setState({ isBackdrop: true });
		const res = await agent.post(`/accounts/password/reset/send`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (res.error) {
			this.setState({ isBackdrop: false });
			Notification({
				message: res?.error?.data?.message || "Ошибка сервера",
				type: notificationTypes.error
			})
			return
		}
		this.setState({
			isBackdrop: false,
			isSuccessSend: true,
			sendEmail: body.email
		});
		Notification({
			message: "Сообщение со сбросом пароля отправлено на Вашу почту.",
			type: notificationTypes.success
		})
	}

	render () {
		const {
			form,
			sendEmail,
			isBackdrop,
			isSuccessSend
		} = this.state;
		const {
			classes
		} = this.props;
		return (
			<Box className={classes.root}>
				<Box className={classes.logo}>
					<img src={require('../../../assets/image/logo/logo-text-shadow.svg').default} alt="logo"/>
				</Box>
				<VisibleContent visible={!isSuccessSend}>
					<Formik
						innerRef={this.refFormik}
						initialValues={form}
						validationSchema={validationSchema}
						onSubmit={this.submit}
					>{(props) => {
						const {
							values,
							touched,
							errors,
							handleSubmit
						} = props;

						return (
							<Box className={classes.form}>
								<Typography className={classes.formTitle}>Сброс пароля</Typography>
								<Grid container spacing="28px">
									<Grid item xs={12}>
										<FormElement
											value={values.email}
											name="email"
											title="Ваш логин/почта"
											placeholder="Ваш логин/почта"
											helpMessage=""
											error={Boolean(touched.email && errors.email)}
											helperText={touched.email && errors.email}
											onChange={this.changeForm}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button variant="contained" fullWidth onClick={handleSubmit}>
											Сбросить
										</Button>
									</Grid>
								</Grid>
							</Box>
						)
					}}</Formik>
				</VisibleContent>
				<VisibleContent visible={isSuccessSend}>
					<SuccessSendForm email={sendEmail}/>
				</VisibleContent>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</Box>
		);
	}
}
const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
})
const FormElement = React.memo((props) => {
	const {
		title,
		placeholder,
		helpMessage,

		...otherProps
	} = props;

	return (
		<Box>
			<Grid container justifyContent="space-between">
				<Grid item>
					<Typography variant="formLabel" mb="8px">{ title }</Typography>
				</Grid>
				{Boolean(helpMessage) && (
					<Grid item>
						<Typography variant="formLabel" mb="8px" style={{color: palette.text.lightGray}}>{ helpMessage }</Typography>
					</Grid>
				)}
			</Grid>
			<TextField
				placeholder={placeholder}
				fullWidth
				{...otherProps}
			/>
		</Box>
	)
});
const SuccessSendForm = React.memo((props) => {
	const { email } = props;
	const classes = useStyles();
	return (
		<Box className={classes.successSendForm}>
			<img
				className={classes.successSendFormImage}
				src={require('../../../assets/image/logo/logo-people.png')}
			/>
			<Typography className={classes.successSendFormMessage}>
				Для завершения сброса пароля перейдите по ссылке в письме которое мы отправили на <span>{email}</span>
			</Typography>
		</Box>
	)
});

const useStyles = makeStyles(() => ({
	successSendForm: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		width: "100%",
		margin: "0 auto",
		maxWidth: "400px",
		padding: 30,
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		background: "#FFF",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
	},
	successSendFormImage: {
		maxWidth: "60%",
		margin: "0 auto",
	},
	successSendFormMessage: {
		color: "#2C4058",
		textAlign: "center",
		fontSize: "20px",
		lineHeight: "130%",
		letterSpacing: "-0.4px",

		"& span": {
			color: "#126DDE"
		}
	}
}));

const styles = {
	root: {
		overflow: "auto",
		padding: "50px 0",
		boxSizing: "border-box"
	},
	logo: {
		width: "115px",
		height: "115px",
		position: "relative",
		margin: "0 auto",
		marginBottom: "48px",

		"& img": {
			maxWidth: "100%",
			position: "absolute",
			top: "0", left: "50%",
			transform: "translateX(-50%)"
		}
	},

	form: {
		margin: "0 auto",
		width: "100%",
		maxWidth: 400,
		padding: "28px",
		boxSizing: "border-box",
		borderRadius: 16,
		border: '0.5px solid rgba(0, 0, 0, 0.10)',
		background: '#FFF',
		boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
	},
	formTitle: {
		marginBottom: "24px",

		fontSize: 24,
		lineHeight: "30px",
		textAlign: "center",
		color: palette.primary.text
	},
	formElement: {},
};
RestorePassword = withStyles(styles)(RestorePassword);

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Некоретно введен Email').required('Заполните поле')
});

export default RestorePassword
