// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Registration from './Registration';
import {setUserProfile} from "../../../states/global";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      setUserProfile: (profile) => dispatch(setUserProfile(profile))
    }),
  ),
)(Registration);
