import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  makeStyles,
  withStyles
} from "@mui/styles";
import {
  palette
} from "../../../theme/common";

class RegistrationConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
  }

  render () {
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Box className={classes.logo}>
          <img src={require('../../../assets/image/logo/logo-text-shadow.svg').default} alt="logo"/>
        </Box>
        <Box className={classes.form}>
          <img src={require("../../../assets/image/logo/logo-people.png")} className={classes.logoPeople}/>
          <Typography variant="h1" textAlign="center">
            Для завершения регистрации<br/>перейдите по ссылке<br/>в письме, которое<br/>мы отправили на почту
          </Typography>
        </Box>
      </Box>
    );
  }
}

const style = {
  root: {
    padding: "50px 0",
    boxSizing: "border-box",
    overflow: "auto"
  },
  logo: {
    width: "115px",
    height: "115px",
    position: "relative",
    margin: "0 auto",
    marginBottom: "48px",

    "& img": {
      maxWidth: "100%",
      position: "absolute",
      top: "0", left: "50%",
      transform: "translateX(-50%)"
    }
  },
  logoPeople: {
    width: 320,
    height: 320,
    objectFit: "contain",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: "100%",
    maxWidth: 400,
    padding: "28px",
    boxSizing: "border-box",
    borderRadius: 16,
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
  },
  formTitle: {
    marginBottom: "24px",

    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    color: palette.primary.text
  },
  formElement: {}
};
RegistrationConfirm = withStyles(style)(RegistrationConfirm);

export default RegistrationConfirm
