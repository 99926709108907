import React, {Component} from "react";
import {
  Box,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";

class Saved extends Component {
  render () {
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Typography className={classes.title}>В разработке.</Typography>
      </Box>
    )
  }
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: 64,
    boxSizing: "border-box",
  },
  title: {
    fontSize: "32px",
    lineHeight: "36px",
    color: "#22252D",
    fontWeight: "500"
  }
};
Saved = withStyles(styles)(Saved);

export default Saved
