import agent from "../agent/agent";

const GET_DIRECTORY = "directory/GET_DIRECTORY";
const GET_DIRECTORY_TYPES = "directory/GET_DIRECTORY_TYPES";
const GET_DIRECTORY_TONES = "directory/GET_DIRECTORY_TONES";
const GET_DIRECTORY_LANGUAGE = "directory/GET_DIRECTORY_LANGUAGE";
const GET_DIRECTORY_SCENARIOS = "directory/GET_DIRECTORY_SCENARIOS";
const GET_DIRECTORY_CREATIVITY = "directory/GET_DIRECTORY_CREATIVITY";

const initialState = {
  tones: [],
  scenarios: [],
  languages: [],
  creativity: [],
};

export function initDirectory () {
  return async function (dispatch) {
    const tones = await getDirectoryTones();
    const languages = await getDirectoryLanguage();
    const scenarios = await getDirectoryScenarios();
    const creativity = await getDirectoryCreativity();
    dispatch({
      type: GET_DIRECTORY,
      tones,
      languages,
      scenarios,
      creativity,
    })
  }
}
async function getDirectoryTones () {
  return await agent.get('/catalogs/tones').then((res) => {
    return res.data
  }).catch(() => {
    return []
  });
}
async function getDirectoryLanguage () {
  return await agent.get('/catalogs/languages').then((res) => {
    return res.data
  }).catch(() => {
    return []
  });
}
async function getDirectoryScenarios () {
  return await agent.get('/scenarios').then((res) => {
    return res.data
  }).catch(() => {
    return []
  });
}
async function getDirectoryCreativity () {
  return await agent.get('/catalogs/creativity').then((res) => {
    return res.data
  }).catch(() => {
    return []
  });
}

export default function DirectoryState (state = initialState, action = {}) {
  switch (action.type) {
    case GET_DIRECTORY: {
      return {
        ...state,
        tones: action.tones || [],
        languages: action.languages || [],
        scenarios: action.scenarios || [],
        creativity: action.creativity || [],
      }
    }
    case GET_DIRECTORY_TYPES: {}
    case GET_DIRECTORY_TONES: {
      return {
        ...state,
        tones: action.value || []
      }
    }
    case GET_DIRECTORY_LANGUAGE: {
      return {
        ...state,
        languages: action.value || []
      }
    }
    case GET_DIRECTORY_SCENARIOS: {
      return {
        ...state,
        scenarios: action.value || []
      }
    }
    case GET_DIRECTORY_CREATIVITY: {
      return {
        ...state,
        creativity: action.value || []
      }
    }
    default:
      return state;
  }
}
