import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Checkbox,
  Backdrop,
  TextField,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import {
  makeStyles,
  withStyles
} from "@mui/styles";
import {
  Formik
} from "formik";
import {
  palette
} from "../../../theme/common";
import {
  Link
} from "react-router-dom";
import {
  Notification,
  notificationTypes
} from "../../../common/Notification";
import * as Yup from "yup";
import agent from "../../../agent/agent";

const initForm = {
  email: "",
  password: "",
  repeatPassword: "",
};

class Registration extends Component {
  constructor(props) {
    super(props);

    const search = new URLSearchParams(props?.location?.search || "");

    this.state = {
      form: {
        ...initForm,
        email: search.get('email') || "",
        password: search.get('password') || "",
        repeatPassword: search.get('password') || "",
      },
      isAccept: true,
      isBackdrop: false,
    };
    this.refFormik = React.createRef();
  }

  componentDidMount = () => {
  }

  changeForm = ({ target }) => {
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  loginSubmit = async (form) => {
    this.setState({ isBackdrop: true });

    let body = {...form};
    delete body.repeatPassword;

    const utmSourceSearch = localStorage.getItem('utm-source-search');
    if (!!utmSourceSearch) {
      body.adv_source = utmSourceSearch;
      localStorage.removeItem('utm-source-search');
    }

    const referral = localStorage.getItem('referral');
    if (!!referral) {
      body.referral = referral;
      localStorage.removeItem('referral')
    }

    const resRegister = await agent.post('/register', body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    })
    if (resRegister?.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: resRegister?.error?.data?.message || "Ошибка сервера",
        type: notificationTypes.error
      })
      return
    }

    this.props.history.push('/registration/confirm');
    this.setState({ isBackdrop: false });

    const res = await agent.post('/auth/login', form).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    })
    if (res?.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: res?.error?.data?.message || "Ошибка сервера",
        type: notificationTypes.error
      })
      return
    }

    agent.defaults.headers['Authorization'] = `Bearer ${res.access_token}`;
    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('refresh_token', res.refresh_token);

    const profile = await agent.get('/accounts/profile').then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!profile) {
      this.setState({ isBackdrop: false });

      Notification({
        message: "Профиль не найден",
        type: notificationTypes.error
      })

      agent.defaults.headers['Authorization'] = '';
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      return
    }

    this.props.setUserProfile(profile);
    this.setState({ isBackdrop: false });
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      form,
      isAccept,
      isBackdrop,
    } = this.state;

    return (
      <Box className={classes.root}>
        <Box className={classes.logo}>
          <img src={require('../../../assets/image/logo/logo-text-shadow.svg').default} alt="logo"/>
        </Box>
        <Formik
          innerRef={this.refFormik}
          initialValues={form}
          validationSchema={validationSchema}
          onSubmit={this.loginSubmit}
        >{(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit
          } = props;

          return (
            <Box className={classes.form}>
              <Typography className={classes.formTitle}>Регистрация</Typography>
              <Grid container spacing="28px">
                <Grid item xs={12}>
                  <FormElement
                    value={values.email}
                    name="email"
                    title="Ваш логин/почта"
                    placeholder="Ваш логин/почта"
                    helpMessage=""
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormElement
                    value={values.password}
                    name="password"
                    title="Придумайте пароль"
                    placeholder="Ваш пароль не менее 8 символов"
                    type="password"
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormElement
                    value={values.repeatPassword}
                    name="repeatPassword"
                    title="Повторите пароль"
                    placeholder="Ваш пароль не менее 8 символов"
                    type="password"
                    error={Boolean(touched.repeatPassword && errors.repeatPassword)}
                    helperText={touched.repeatPassword && errors.repeatPassword}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    disabled={!isAccept}
                    onClick={handleSubmit}
                  >
                    Зарегистрироваться
                  </Button>
                  <Box mt={1}/>
                  <FormControlLabel
                    value={isAccept}
                    checked={isAccept}
                    label={<React.Fragment>
                      Я ознакомлен с <a href="https://lk.aisynergy.ru/privacy-policy" target="_blank">политикой конфиденциальности</a> и даю согласие на <a
                      href="https://lk.aisynergy.ru/personal-data" target="_blank">обработку своих персональных данных</a>
                    </React.Fragment>}
                    className={classes.formAccept}
                    control={<Checkbox defaultChecked />}
                    onChange={(e, v) => this.setState({ isAccept: v })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" textAlign="center">
                    Уже зарегистрированы? <Link to="/authorization">Войти</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )
        }}</Formik>


        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Box>
    );
  }
}
const FormElement = React.memo((props) => {
  const {
    title,
    placeholder,
    helpMessage,

    ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="formLabel" mb="8px">{ title }</Typography>
        </Grid>
        {Boolean(helpMessage) && (
          <Grid item>
            <Typography variant="formLabel" mb="8px" className={classes.buttonHelp} style={{color: palette.text.lightGray}}>{ helpMessage }</Typography>
          </Grid>
        )}
      </Grid>
      <TextField
        placeholder={placeholder}
        fullWidth
        {...otherProps}
      />
    </Box>
  )
});

const useStyles = makeStyles(() => ({
  buttonHelp: {}
}));
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Некоретно введен Email').required('Заполните поле'),
  password: Yup
    .string()
    .min(8, "Минимальная длина 8 символов")
    .required('Заполните поле'),
  repeatPassword: Yup
    .string()
    .min(8, "Минимальная длина 8 символов")
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required('Заполните поле'),
});

const style = {
  root: {
    padding: "50px 0",
    boxSizing: "border-box"
  },
  logo: {
    width: "115px",
    height: "115px",
    position: "relative",
    margin: "0 auto",
    marginBottom: "48px",

    "& img": {
      maxWidth: "100%",
      position: "absolute",
      top: "0", left: "50%",
      transform: "translateX(-50%)"
    }
  },

  form: {
    margin: "0 auto",
    width: "100%",
    maxWidth: 400,
    padding: "28px",
    boxSizing: "border-box",
    borderRadius: 16,
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)',
  },
  formTitle: {
    marginBottom: "24px",

    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    color: palette.primary.text
  },
  formElement: {},
  formAccept: {
    "& .MuiTypography-root": {
      fontSize: 12,
      color: "#254164",
      fontWeight: "400",
      lineHeight: "120%",
      textDecoration: "none",

      "& a": {
        textDecoration: "underline"
      }
    }
  }
};
Registration = withStyles(style)(Registration);

export default Registration
