import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import {
  LoadingButton
} from "@mui/lab";
import {
  makeStyles
} from "@mui/styles";
import {
  palette
} from "../../../../../../theme/common";
import {
  copyPicture, downloadPictureMidjourney
} from "../../../../../../helpers/clipboard";
import {
  Notification,
  notificationTypes
} from "../../../../../../common/Notification";
import Lightbox from 'react-image-lightbox';
import clsx from "clsx";

const HistoryContent = (props) => {
  const {
    history,
    onChangeUpscale,
    onChangeVariation
  } = props;

  return (
    <Grid container spacing={2}>
      {history.map((item) => (
        <Grid item xs={12}>
          <HistoryCard
            data={item}
            hideSettings={Boolean(item?.process === "upscale")}
            cancelControls
            onChangeUpscale={onChangeUpscale}
            onChangeVariation={onChangeVariation}
          />
        </Grid>
      ))}
    </Grid>
  )
}
const HistoryCard = React.memo((props) => {
  const {
    data,
    hideSettings,
    cancelControls,
    onChangeUpscale,
    onChangeVariation
  } = props;
  const classes = useStyles();
  const isLoading = Boolean(data.status === "processing" || data.status === "on_queue");
  const [isCopyImage, setCopyImage] = React.useState(false);
  const [isSavingImage, setSavingImage] = React.useState(false);
  const [isOpeLightbox, setOpenLightbox] = React.useState(false);

  const handleOpenImage = () => {
    setOpenLightbox(true);
  }
  const handleCopyImage = async () => {
    setCopyImage(true);
    const imageUrl = data?.image?.url;
    await copyPicture(imageUrl);
    Notification({
      message: "Изображение скопировано",
      type: notificationTypes.success
    })
    setCopyImage(false);
  }
  const handleDownloadImage = async () => {
    setSavingImage(true);
    await downloadPictureMidjourney(data?.image);
    setSavingImage(false);
  }

  return (
    <Box className={classes.historyCard}>
      <Box className={classes.historyCardBody}>
        <div className="--left">
          <Box className={clsx({
            [classes.historyCardImage]: true,
            [classes.historyCardImageLoading]: isLoading,
          })}>
            <img
              src={`${data?.image?.url}?format=webp&size=640`}
              onClick={handleOpenImage}
            />
          </Box>
        </div>
        <div className="--right">
          <Grid item>
            {Boolean(data?.prompt) && (
              <Typography variant="h4" sx={{color: palette.text.primary}} className={classes.historyCardPrompt}>
                Результат по запросу: <span
                style={{color: palette.primary.main}}
                dangerouslySetInnerHTML={{
                  __html: data?.prompt
                }}
              />
              </Typography>
            )}
            {Boolean(isLoading) && (
              <Typography className={classes.historyCardMessageLoading}>
                Генерация началась и может продлиться до двух минут просьба не выходить из данного экрана до появления чёткого изображения.
              </Typography>
            )}
          </Grid>
        </div>
      </Box>
      <Grid container spacing={1} mt="20px">
        {Boolean(!hideSettings) && (
          <Grid item xs={12} md="auto">
            <ControlsElement
              data={data}
              cancelControls={cancelControls}
              onChangeUpscale={onChangeUpscale}
              onChangeVariation={onChangeVariation}
            />
          </Grid>
        )}
        <Grid item xs={12} md="auto">
          <Grid container spacing={1} flexDirection={Boolean(!hideSettings) ? "column" : "row"}>
            <Grid item>
              <LoadingButton
                variant="contained"
                disabled={isLoading || isCopyImage}
                fullWidth
                className={clsx([classes.controlsButton, classes.copyButton])}
                onClick={handleCopyImage}
              >
                {isCopyImage ? "Копирование..." : "Копировать"}
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                disabled={isLoading || isSavingImage}
                fullWidth
                className={clsx([classes.controlsButton, classes.copyButton])}
                onClick={handleDownloadImage}
              >
                {isSavingImage ? "Сохранение..." : "Сохранить"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {Boolean(isOpeLightbox) && (
        <Lightbox
          mainSrc={data?.image?.url}
          onCloseRequest={() => setOpenLightbox(false)}
        />
      )}
    </Box>
  )
});
const ControlsElement = React.memo((props) => {
  const {
    data,
    cancelControls,
    onChangeUpscale,
    onChangeVariation
  } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.controls} container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        {[1, 2, 3, 4].map((t) => (
          <Grid item xs={3} md="auto">
            <Button
              className={classes.controlsButton}
              fullWidth
              variant={(data.imageUpscale || []).includes(t) ? "contained" : "outlined"}
              disabled={(data.imageUpscale || []).includes(t)}
              onClick={onChangeUpscale.bind(this, t, data.id)}
            >U{t}</Button>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {[1, 2, 3, 4].map((t) => (
          <Grid item xs={3} md="auto">
            <Button
              variant={(data.imageIndexs || []).includes(t) ? "contained" : "outlined"}
              disabled={(data.imageIndexs || []).includes(t)}
              className={classes.controlsButton}
              fullWidth
              onClick={onChangeVariation.bind(this, t, data.id)}
            >V{t}</Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
})

const useStyles = makeStyles(() => ({
  historyCard: {
    padding: "20px 24px",
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

    "@media(max-width: 859px)": {
      padding: "16px",
      position: "relative",
      overflow: "hidden"
    }
  },
  historyCardBody: {
    display: "flex",
    "& .--left": {
      marginRight: 8
    },
    "@media(max-width: 859px)": {
      flexDirection: "column",
      "& .--left": {
        marginRight: 0,
        marginBottom: 8
      }
    }
  },
  historyCardName: {
    fontSize: "24px",
    color: "#334A67"
  },
  historyCardImage: {
    width: 448,
    height: 448,
    position: "relative",
    borderRadius: 10,
    overflow: "hidden",
    cursor: "pointer",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      transition: "all 0.2s"
    },
    "&:hover img": {
      transform: "scale(1.1)"
    },

    "@media(max-width: 859px)": {
      display: "flex",
      margin: "-18px",
      marginBottom: 0,
      width: "calc(100% + 36px)",
      height: "initial",
      overflow: "hidden",
      borderRadius: 0,
      "&:after": {
        content: "''",
        float: "left",
        paddingTop: "100%"
      }
    }
  },
  historyCardImageLoading: {
    "&:before": {
      content: "''",
      backgroundImage: "url(/images/logo-small.png)",
      position: 'absolute',
      top: "50%",
      left: "50%",
      width: "100%",
      height: "100%",
      zIndex: "2",
      transform: "translate(-50%, -50%)",
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
    }
  },
  historyCardControls: {},
  historyCardPrompt: {
    fontSize: '14px',
    lineHeight: '140%',
  },
  historyCardMessageLoading: {
    fontSize: '14px',
    lineHeight: '140%',
    color: "#99ACC3",
    marginTop: 8
  },

  controls: {
    display: "grid",
    width: "auto"
  },
  controlsButton: {
    padding: "5px",
    borderRadius: 5,
    minWidth: "64px",
    borderColor: `${palette.primary.main}!important`,

    "&.MuiButton-contained": {
      backgroundColor: `${palette.primary.main}!important`,
      color: "white!important"
    },
    // "@media(max-width: 859px)": {
    //   minWidth: "calc(100% / 4)"
    // }
  },

  copyButton: {
    minWidth: 160
  }
}));

export default React.memo(HistoryContent)
