import React from "react";
import {
	FormControl, FormHelperText,
	Grid, MenuItem, Select, Typography,
	ToggleButtonGroup, ToggleButton,
	Collapse, Button, Box
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon
} from "@mui/icons-material";
import variants from "./variants.json";
import {palette} from "../../theme/common";
import {caseWords} from "../../helpers/caseWords";
import SelectAiModel from "./SelectAiModel";
import clsx from "clsx";

const SettingsPc = (props) => {
	const {
		tones,
		values,
		errors,
		touched,
		aiModels,
		languages,
		creativity,
		onChange,
		onMessageNumberOfOptions
	} = props;
	const classes = useStyles();
	const [isOpen, setOpen] = React.useState(false);
	const CollapseIcon = Boolean(isOpen) ? ArrowDropUpIcon : ArrowDropDownIcon;
	const isAiModels = Boolean(aiModels.length > 0);

	const handleChangeToggleButtonGroup = (name, event, value) => {
		onChange({
			target: {
				name,
				value
			}
		});
	}
	const _messageNumberOfOptions = () => {
		return onMessageNumberOfOptions
	}

	return (
		<>
			<Button
				fullWidth
				className={classes.buttonOpenSettings}
				onClick={() => setOpen(!isOpen)}
				endIcon={<CollapseIcon/>}
			>
				Настройки
			</Button>
			<Collapse in={isOpen}>
				<Box mt={2}/>
				<Grid container spacing="16px" mb="auto">
					<Grid item xs={12} md={6}>
						<Typography variant="formLabel" mb="8px">Выберите тон</Typography>
						<FormControl fullWidth error={Boolean(touched.tone && errors.tone)} className={classes.formControl}>
							<Select
								value={values.tone}
								name="tone"
								onChange={onChange}
							>
								{tones.map((tone) => (
									<MenuItem key={`tone-${tone.id}`} value={tone.id}>
										{tone.value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{Boolean(touched.tone && errors.tone) && (
							<FormHelperText error={true}>{touched.tone && errors.tone}</FormHelperText>
						)}
					</Grid>
					<VisibleContent visible={isAiModels}>
						<Grid item xs={12} md={6} className={clsx([classes.formControl, 'select-ai-model'])}>
							<SelectAiModel
								value={values.ai_type_id}
								error={errors.ai_type_id}
								aiModels={aiModels}
								touched={touched.ai_type_id}
								onChange={onChange}
							/>
						</Grid>
					</VisibleContent>
					<Grid item>
						<Typography variant="formLabel" mb="4px">Язык</Typography>
						<ToggleButtonGroup
							exclusive
							value={values.language}
							className={classes.toggleButtonGroup}
							onChange={handleChangeToggleButtonGroup.bind(this, 'language')}
						>
							{languages.map((lang) => (
								<ToggleButton
									value={lang.code}
								>{lang.name}</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Grid>
					<Grid item className="select-number-options">
						<Typography variant="formLabel" mb="4px">Количество вариантов</Typography>
						<ToggleButtonGroup
							exclusive
							value={values.number_of_options}
							className={classes.toggleButtonGroup}
							onChange={handleChangeToggleButtonGroup.bind(this, 'number_of_options')}
						>
							{(variants.options || []).filter((t) => t.value).map((option) => (
								<ToggleButton
									value={option.value}
								>{option.label}</ToggleButton>
							))}
						</ToggleButtonGroup>
						<FormHelperText
							sx={{color: palette.text.lightGray}}
							dangerouslySetInnerHTML={{ __html: _messageNumberOfOptions() }}
						/>
					</Grid>
					<Grid item>
						<Typography variant="formLabel" mb="4px">Уровень креативности</Typography>
						<ToggleButtonGroup
							exclusive
							value={values.creativity}
							className={classes.toggleButtonGroup}
							onChange={handleChangeToggleButtonGroup.bind(this, 'creativity')}
						>
							{(creativity || []).filter((t) => t.value).map((option) => (
								<ToggleButton
									value={option.id}
								>{option.value}</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Grid>
				</Grid>
			</Collapse>
		</>
	)
}
const VisibleContent = React.memo(({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
})

const useStyles = makeStyles(() => ({
	buttonOpenSettings: {
		borderRadius: 4,
		padding: "4px",

		fontSize: 14,
		lineHeight: "100%",
	},
	toggleButtonGroup: {
		display: "flex",
		flexWrap: "wrap",
		marginTop: "-2px!important",
		marginLeft: "-2px!important",

		"& .MuiButtonBase-root": {
			padding: "6px 10px",
			borderRadius: "100px!important",
			marginTop: "2px!important",
			marginLeft: "2px!important",
			border: "1px solid rgba(18, 109, 222, 0.20)!important",

			color: "#126DDE",
			textAlign: "center",
			fontSize: "14px",
			lineHeight: "14px",
			fontWeight: "500",
			letterSpacing: "-0.36px",
			textTransform: "initial",

			"&.Mui-selected": {
				backgroundColor: palette.primary.main,
				color: palette.primary.contrastText,
				borderColor: `${palette.primary.main}!important`
			}
		}
	},
	formControl: {
		"& .MuiSelect-select": {
			padding: "7px 10px 1px",
			borderRadius: "4px!important"
		}
	}
}));

export default SettingsPc
