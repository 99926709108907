import React, {Component} from "react";
import {
	Box
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import Pricing from "../../../auth/Welcome/components/Pricing/Pricing";

class Tariff extends Component {
	constructor(props) {
		super(props);

		this.state = {

		};
	}

	componentDidMount = () => {
	}

	render() {
		const {
			classes
		} = this.props;
		return (
			<Box className={classes.root}>
				<Pricing/>
			</Box>
		);
	}
}

const styles = {};
Tariff = withStyles(styles)(Tariff);

export default Tariff
