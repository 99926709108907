import React from "react";
import {
  Box
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";

const Filter = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}></Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "16px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    padding: "20px",
    boxSizing: "border-box"
  }
}));

export default Filter
