import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Notification,
  notificationTypes
} from "../../../../../../common/Notification";

const FormCreatePromt = (props) => {
  const {
    onSubmit
  } = props;
  const [prompt, setPrompt] = React.useState("");
  const classes = useStyles();

  const handleGenerate = () => {
    if (!prompt) {
      Notification({
        message: "Введите запрос для генерации изображения",
        type: notificationTypes.error
      })
      return
    }
    onSubmit(prompt);
  }
  const handleChangePrompt = ({ target }) => {
    const { value } = target;
    setPrompt(value);
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{flex: 1}}>
          <TextField
            value={prompt}
            placeholder="Введите запрос для генерации изображения"
            rows={12}
            fullWidth
            multiline
            onChange={handleChangePrompt}
          />
        </Grid>
        {Boolean(false) && (
          <Grid item xs={12}>
            <Box className={classes.helpMessage}>
              Тут будет сообщение с какой либо подсказкой
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            className={classes.button}
            onClick={handleGenerate}
          >
            Сгенерировать
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  helpMessage: {
    padding: "12px 18px",
    boxSizing: "border-box",
    borderRadius: "10px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    background: "rgba(255,255,255,0.8)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    backdropFilter: "blur(20px)",

    color: "#126DDE",
    fontFamily: "Suisse Intl",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "-0.3px",
  },
  button: {
    borderRadius: 10
  }
}));

export default React.memo(FormCreatePromt)
