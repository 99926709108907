// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import NewTask from './NewTask';
import {updateSubscriptions} from "../../../../states/global";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      updateSubscriptions: () => dispatch(updateSubscriptions())
    }),
  ),
)(NewTask);
