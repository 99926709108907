import React, {Component} from "react";
import {
	Grid,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	ScenariosForm,
	ScenariosLoading,
	DialogWarningEndRate
} from "../../../../components";
import {
	Notification,
	notificationTypes
} from "../../../../common/Notification";
import {
	NoResult
} from "./components";
import agent from "../../../../agent/agent";
import yaCounter from "../../../../helpers/yaCounter";

class NewTask extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isBackdrop: false
		};
		this.refDialogWarningEndRate = React.createRef();
	}

	createTask = async (scenarioId, body, scenario) => {
		await this.setState({isBackdrop: true});
		const res = await agent.post(`/scenarios/${scenarioId}/execute/async`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response || true}
		});
		if (res?.error) {
			this.setState({isBackdrop: false});
			if (res?.error?.data?.code === 4318) {
				this.refDialogWarningEndRate.current.open();
				return
			}
			Notification({
				message: res?.error?.data?.message || "Ошибка сервера",
				type: notificationTypes.error
			})
			return
		}
		yaCounter('create_text_task', {
			'scenario': `${scenario?.title} (ID: ${scenarioId})`
		});
		await this.props.updateSubscriptions();
		this.setState({isBackdrop: false});
		this.props.history.push(`/task/${res?.id}`);
	}

	render() {
		const {
			isBackdrop
		} = this.state;

		return (
			<Grid container spacing={2} sx={{flex: 1}}>
				<Grid item xs={12} md={5} sx={{flex: 1, display: "flex", flexDirection: "column"}}>
					<ScenariosForm
						onSubmit={this.createTask}
					/>
				</Grid>
				<Grid item xs={12} md={7} sx={{display: "flex"}}>
					<NoResult
						hideMessage={true}
					/>
				</Grid>

				<ScenariosLoading open={isBackdrop}/>
				<DialogWarningEndRate ref={this.refDialogWarningEndRate}/>
			</Grid>
		);
	}
}

export default NewTask
