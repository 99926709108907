const SET_WS_MESSAGE = "ws-messages/SET_WS_MESSAGE";

const initialState = {
  wsMessages: [],
};

export function setWsMessage(item) {
  return {
    type: SET_WS_MESSAGE,
    item
  }
}

export default function WsMessagesState(state = initialState, action = {}) {
  switch (action.type) {
    case SET_WS_MESSAGE: {
      return {
        ...state,
        wsMessages: [...state.wsMessages, action.item]
      }
    }

    default:
      return state;
  }
}
