import React from "react";
import {
  Box
} from "@mui/material";
import {

} from "@mui/styles";

const LoadingContent = () => {
  return (
    <Box className="background-pages background-authorization">

    </Box>
  )
}

export default React.memo(LoadingContent)
