import React, {useContext, useState} from 'react';
import logo from '../../../../../assets/image/welcome/logo.svg';
import tgIcon from '../../../../../assets/image/welcome/tg.svg';
import nextIcon from '../../../../../assets/image/welcome/next.svg';
import s from './header.module.css';
import {Container} from "@mui/material";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const Header = () => {
    const userProfile = useSelector((state) => state.global.userProfile);
    const [activeBurger, setActiveBurger] = useState(false);

    const onCloseBurger = () => {
        setActiveBurger(false);
    }
    return (
        <div className={s.header}>
           <Container maxWidth='xl'>
               <div className={s.headerInner}>
                   <img className={s.headerLogo} src={logo} alt="logo"/>
                   <div className={s.headerNav}>
                       <a href="#tasks" className={s.headerNavLink}>Что мы можем</a>
                       <a href="#consumers" className={s.headerNavLink}>Для кого</a>
                       <a href="#cases" className={s.headerNavLink}>Кейсы</a>
                       <a href="#pricing" className={s.headerNavLink}>Тарифы</a>
                       <a href="#answers" className={s.headerNavLink}>Вопросы</a>
                       <a href="#usage" className={s.headerNavLink}>Как использовать</a>
                   </div>

                   <a className={s.headerBtnTg} href="https://t.me/Ygoriachiy" target='_blank'>
                       Поддержка
                       <img src={tgIcon} alt="tg"/>
                   </a>
                   <Link className={s.headerBtn} to={Boolean(userProfile) ? "/task/create" : "/authorization"}>
                     {Boolean(userProfile) ? "Профиль" : "Вход и регистрация"}
                       <div className={s.headerBtnIcon}>
                           <img src={nextIcon} alt="next"/>
                       </div>
                   </Link>
               </div>
               <div className={s.headerMobile}>
                   <img className={s.headerLogo} src={logo} alt="logo"/>

                   <a className={s.headerBtnTg} href="https://t.me/Ygoriachiy" target='_blank'>
                       Поддержка
                       <img src={tgIcon} alt="tg"/>
                   </a>
                   <Link className={s.headerBtn} to="/registration">
                       Вход и регистрация
                       <div className={s.headerBtnIcon}>
                           <img src={nextIcon} alt="next"/>
                       </div>
                   </Link>

                   <Link className={`${s.headerBtn} ${s.btnMobile}`} to="/authorization">
                       Вход
                       <div className={s.headerBtnIcon}>
                           <img src={nextIcon} alt="next"/>
                       </div>
                   </Link>
                   <div className={s.burger}>
                       <div onClick={() => setActiveBurger(!activeBurger)} className={`${s.burgerItem} ${activeBurger ? s.activeBurger : ''}`}></div>
                   </div>

                   {activeBurger ? (
                     <>
                         <div className={s.mobileNav}>
                             <a onClick={onCloseBurger} href="#tasks" className={s.headerMobileNavLink}>Что мы можем</a>
                             <a onClick={onCloseBurger} href="#consumers" className={s.headerMobileNavLink}>Для кого</a>
                             <a onClick={onCloseBurger} href="#cases" className={s.headerMobileNavLink}>Кейсы</a>
                             <a onClick={onCloseBurger} href="#pricing" className={s.headerMobileNavLink}>Тарифы</a>
                             <a onClick={onCloseBurger} href="#answers" className={s.headerMobileNavLink}>Вопросы</a>
                             <a onClick={onCloseBurger} href="#usage" className={s.headerMobileNavLink}>Как использовать</a>
                         </div>
                         <div className={s.mobileNavClose} onClick={onCloseBurger}/>
                     </>
                   ) : null}
               </div>
           </Container>
        </div>
    );
};

export default Header;
