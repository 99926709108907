import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Edit as EditIcon,
  Image as ImageIcon,
  ContentCopy as ContentCopyIcon
} from "@mui/icons-material";
import {
  Notification,
  notificationTypes
} from "../../../../../../common/Notification";
import {
  Review
} from "../../../../../../components";
import {
  palette
} from "../../../../../../theme/common";

import clsx from "clsx";
import copyToClipboard from "../../../../../../helpers/copyToClipboard";
import {textParseBack} from "../../../../../../helpers/textParse";
import {useHistory} from "react-router-dom";

const FormResult = (props) => {
  const {
    task,
    answer,
    isGenerateMidjourney,

    onEditText,
    onGenerateMidjourney
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [activeAnswer, setActiveAnswer] = React.useState(0);

  React.useEffect(() => {
    initActiveAnswer();
  }, []);

  const copyText = () => {
    const copyText = answer[activeAnswer]?.edit || answer[activeAnswer]?.orig;
    const text = textParseBack(copyText);
    copyToClipboard(text);
    Notification({
      message: "Текст скопирован",
      type: notificationTypes.success
    })
  };
  const generateMidjourney = () => {
    const text = answer[activeAnswer]?.edit || answer[activeAnswer]?.orig;
    onGenerateMidjourney(text);
  };

  const initActiveAnswer = () => {
    const search = window.location.search || "";
    if (!search) {
      return
    }

    const index = Number.parseFloat((search.split("&")?.[0] || "").split("?active=")?.[1] || 0);
    setActiveAnswer(index);
  }
  const changeActiveAnswer = (index) => {
    setActiveAnswer(index);

    const pathname = [window.location.pathname, `active=${index}`].join("?");
    window.history.pushState(null, null, pathname);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h1" className={classes.headerTitle}>Результат</Typography>
        <Grid container spacing="32px">
          {answer.map((item, index) => (
            <Grid item>
              <Box
                className={clsx({
                  [classes.headerButton]: true,
                  "active": index === activeAnswer,
                })}
                onClick={changeActiveAnswer.bind(this, index)}
              >
                {index + 1} вариант
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className={classes.body}>
        <div dangerouslySetInnerHTML={{__html: answer[activeAnswer]?.edit || answer[activeAnswer]?.orig}}/>
      </Box>
      <Box className={classes.copyAction}>
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={copyText}>
              Скопировать
              <ContentCopyIcon/>
            </Button>
          </Grid>
          {Boolean(isGenerateMidjourney) && (
            <Grid item>
              <Button variant="outlined" onClick={generateMidjourney}>
                Создать изображение
                <ImageIcon/>
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" onClick={onEditText.bind(this, activeAnswer, null)}>
              Изменить текст
              <EditIcon/>
            </Button>
          </Grid>
          <Grid item>
            <Review item={task}/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",

    width: "100%",
    borderRadius: "16px",
    padding: "32px",
    boxSizing: "border-box",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    background: "#FFF",

    "@media(max-width: 1199px)": {
      padding: "16px",
    }
  },

  header: {
    display: "flex",
    alignItems: "center",

    borderBottom: "1px solid rgba(0,0,0,0.1)",
    paddingBottom: "20px",
    boxSizing: "border-box",
    marginBottom: "28px",

    "@media(max-width: 1199px)": {
      alignItems: "flex-start",
      flexDirection: "column",
      paddingBottom: "10px",
      boxSizing: "border-box",
      marginBottom: "10px",
    }
  },
  headerTitle: {
    marginRight: "36px",

    color: "#2C4058",
    fontSize: "28px",
    fontWeight: "450",
    letterSpacing: "-0.56px",

    "@media(max-width: 1199px)": {
      fontSize: 20,
      marginRight: 0,
      marginBottom: 10
    }
  },
  headerButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "-26px 0",
    height: "68px",
    position: "relative",

    color: "#99ACC3",
    fontSize: "16px",
    fontWeight: "450",
    letterSpacing: "-0.32px",
    fontFamily: "Suisse Intl",

    "&.active": {
      color: "#126DDE",
      "&:after": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        height: 3,
        background: "#126DDE",
        borderRadius: "100px"
      }
    },
    "@media(max-width: 1199px)": {
      margin: "-12px 0",
      height: "40px",
    }
  },

  body: {
    color: "#2C4058",
    fontFamily: "Suisse Intl",
    fontSize: "16px",
    lineHeight: "135%",
    letterSpacing: "-0.32px",

    "@media(max-width: 859px)": {
      fontSize: "14px",
      lineHeight: "160%"
    }
  },

  copyAction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,

    "& .MuiButton-root": {
      padding: "8px 15px",
      fontSize: "16px",

      "& .MuiSvgIcon-root": {
        fontSize: 24,
        marginLeft: 5
      }
    },
    "@media(max-width: 859px)": {
      marginTop: 20,
      "& .MuiButton-root": {
        padding: "4px 10px",
        fontSize: 14,
        "& .MuiSvgIcon-root": {
          fontSize: "16px!important",
          marginLeft: 4
        }
      }
    }
  }
}));

export default React.memo(FormResult)
