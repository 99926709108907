import React from 'react';
import s from './usage.module.css';
import {Container} from "@mui/material";
import usageCover from '../../../../../assets/image/welcome/usageCover.svg';
import {Link} from "react-router-dom";

const Usage = () => {
  return (
    <div id='usage' className={s.usage}>
      <Container maxWidth='lg'>
        <div className={`welcome-title ${s.mainTitle}`}>Как использовать</div>
        <div className={s.items}>
          <div className={s.item}>
            <div className={s.imageWrapper}>
              <img src={require('../../../../../assets/image/welcome/usage1_1.jpg')} alt="pic"/>
            </div>
            <div className={s.title}>
              <span>01</span>
              <div>Зарегистрируйтесь и авторизуйтесь</div>
            </div>
          </div>
          <div className={s.item}>
            <div className={s.imageWrapper}>
              <img src={require('../../../../../assets/image/welcome/usage2_2.jpg')} alt="pic"/>
            </div>
            <div className={s.title}>
              <span>02</span>
              <div>Выберите интересующее вас направление генерации</div>
            </div>
          </div>
          <div className={s.item}>
            <div className={s.imageWrapper}>
              <img src={require('../../../../../assets/image/welcome/usage3_3.jpg')} alt="pic"/>
            </div>
            <div className={s.title}>
              <span>03</span>
              <div>Выберите нужные вам язык, тональность и вариант использования</div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.imageWrapper}>
              <img src={require('../../../../../assets/image/welcome/usage4_4.jpg')} alt="pic"/>
            </div>
            <div className={s.title}>
              <span>04</span>
              <div>Подробно опишите вашу задачу</div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.imageWrapper}>
              <img src={require('../../../../../assets/image/welcome/usage3_3.jpg')} alt="pic"/>
            </div>
            <div className={s.title}>
              <span>05</span>
              <div>Выберите количество вариантов и уровень креативности</div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.whiteCover}></div>
            <div className={s.imageCover}>
            </div>
            <div className={s.imageWrapper}>
              <img src={require('../../../../../assets/image/welcome/usage5_5.jpg')} alt="pic"/>
            </div>
            <div className={s.title}>
              <span>06</span>
              <div>Получите ваш результат!</div>
            </div>
          </div>
        </div>
        <div className={s.buttonRegistration}>
          <Link to="/registration" className="welcome-btn">
            Зарегистрироваться
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Usage;
