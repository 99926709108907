import React, {Component} from "react";
import {
	Grid
} from "@mui/material";
import {
	ActionContent
} from "./components";
import {
	NoResult
} from "../../task/TaskCreate/components";

class TaskBatch extends Component {
	render() {
		return (
			<Grid container spacing={2} sx={{flex: 1}}>
				<Grid item xs={12} md={5} sx={{flex: 1, display: "flex", flexDirection: "column"}}>
					<ActionContent />
				</Grid>
				<Grid item xs={12} md={7} sx={{display: "flex", minHeight: 460}}>
					<NoResult/>
				</Grid>
			</Grid>
		);
	}
}

export default TaskBatch
