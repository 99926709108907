import React from 'react';
import {
	Grid,
	Button,
	Container,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import s from './tasks.module.css';
import plusIcon from '../../../../../assets/image/welcome/plus.svg';
import agent from "../../../../../agent/agent";

const Tasks = () => {
	const [tasks, setTasks] = React.useState([]);
	const [openTask, setOpenTask] = React.useState(null);
	React.useEffect(() => {
		(async () => {
			await getTasks();
		})();
	}, []);
	const getTasks = async () => {
		const res = await agent.get('/info/scenarios').then((res) => {
			return res.data
		}).catch(() => {
			return []
		});
		setTasks(res);

		const hash = (window?.location?.hash || "").replace("#", "");
		if (!hash) {
			return
		}

		const findTask = res.find((t) => t.slug === hash);
		if (!findTask) {
			return
		}

		setOpenTask(findTask);
	}

	const onOpenTask = (item) => {
		const slug = (item?.slug || "").replaceAll("/", "");
		window.history.replaceState(null, null, `#${slug}`);
		setOpenTask(item);
	}
	const onCloseTask = () => {
		window.history.replaceState(null, null, '/');
		setOpenTask(null);
	}

	return (
		<div id='tasks' className={s.tasks}>
			<Container maxWidth='xl'>
				<div className={s.tasksTitle}>AI Synergy помогает в решении задач</div>

				<VisibleContent visible={false}>
					<div className={s.tasksItems}>
						{(tasks || []).map((task) => (
							<div className={s.tasksItem} onClick={onOpenTask.bind(this, task)}>
								<img src={task.icon} alt="pic"/>
								<div>{task.title}</div>
							</div>
						))}
					</div>
				</VisibleContent>
				<VisibleContent visible={true}>
					<div className={s.tasksItems}>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-1.jpg')} alt="pic"/>
							<div>Сделать рерайтинг (перефразировать текст)</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-2.jpg')} alt="pic"/>
							<div>Повысить уникальность текста</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-3.jpg')} alt="pic"/>
							<div>Написать текст</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-4.jpg')} alt="pic"/>
							<div>Сократить текст</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-5.jpg')} alt="pic"/>
							<div>Улучшить текст</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-6.jpg')} alt="pic"/>
							<div>Выполнить любую задачу</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-7.jpg')} alt="pic"/>
							<div>Написать статью для сайта/блога/журнала</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-8.jpg')} alt="pic"/>
							<div>Написать статью для VK</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-9.jpg')} alt="pic"/>
							<div>Нарисовать любое изображение</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-10.jpg')} alt="pic"/>
							<div>Создать рекламное объявление</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-11.jpeg')} alt="pic"/>
							<div>Создать описание товара/продукта</div>
						</div>
						<div className={s.tasksItem}>
							<img src={require('../../../../../assets/image/welcome/9-12.jpg')} alt="pic"/>
							<div>Придумать идеи</div>
						</div>
					</div>
				</VisibleContent>
			</Container>

			<VisibleContent visible={Boolean(openTask)}>
				<DialogInfoTask
					task={openTask}
					onClose={onCloseTask}
				/>
			</VisibleContent>
		</div>
	);
};
const DialogInfoTask = React.memo((props) => {
	const {
		task,
		onClose
	} = props;
	const classes = useStyles();

	return (
		<Dialog
			open
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle sx={{alignItems: "flex-start", padding: "16px 20px"}}>
				<Typography variant="h3" textAlign="left">
					{task.title}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography
					className={classes.dialogMessage}
					dangerouslySetInnerHTML={{
						__html: task.message
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="flex-end" px={2} py={1}>
					<Grid item>
						<Button variant="contained" size="small" onClick={onClose}>
							Закрыть
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
})
const VisibleContent = React.memo(({visible, children}) => {
	if (!visible) {
		return null
	}
	return children
})

const useStyles = makeStyles(() => {
	return {
		dialogMessage: {
			color: "#254164",
			fontSize: "16px",
			fontWeight: "400",
			lineHeight: "160%",

			"& img": {
				maxWidth: "100%",
				borderRadius: 8,
				margin: "15px 0"
			},
			"& p": {
				marginBottom: 15
			}
		}
	}
});

export default Tasks;
