import React, {Component} from "react";
import {
	Grid,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	Notification,
	notificationTypes
} from "../../../../common/Notification";
import {
	ContentEdit,
	DialogInfoTask,
	DialogEditTask
} from "./components";
import {
	ActionContent
} from "../TaskBatch/components";
import agent from "../../../../agent/agent";

class TaskBatchView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mainScenario: null,
			batchScenario: null,

			tableCompound: [],

			batchId: props?.match?.params?.id,

			isInit: false,
			isBackdrop: false,
			isStartedGenerate: false
		};
		this.refDialogInfoTask = React.createRef();
		this.refDialogEditTask = React.createRef();
	}

	componentDidMount = async () => {
		await this.initScenario();
	}
	componentDidUpdate = async (prevProps, prevState) => {

		const propsParamsId = this.props?.match?.params?.id;
		const prevPropsParamsId = prevProps?.match?.params?.id;
		if (!!prevPropsParamsId && prevPropsParamsId !== propsParamsId) {
			await this.setState({
				mainScenario: null,
				batchScenario: null,
				tableCompound: [],
				batchId: propsParamsId,
				isInit: false
			});
			await this.initScenario();
		}

		if ((prevProps.wsMessages || []).length !== (this.props.wsMessages || []).length) {
			this.watchWs();
		}
	}

	initScenario = async () => {
		const {batchId} = this.state;
		if (!batchId) {
			Notification({
				type: notificationTypes.error,
				message: "Сценарий не найден"
			})
			return
		}

		await this.setState({ isInit: false });

		// Получение сценария пакетной обратоботки
		const batchScenario = await agent.get(`/batch/scenarios/${batchId}`).then((res) => {
			return res.data
		}).catch((err) => {
			return null
		});
		if (!batchScenario) {
			Notification({
				type: notificationTypes.error,
				message: "Сценарий не найден"
			})
			return
		}

		// Получение родительского сценария
		const mainScenario = await agent.get(`/scenarios/${ batchScenario.scenario_id }`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		});

		// Сборка таблицы для отображения
		let tableCompound = (mainScenario?.widget || []).map((widget) => {
			return {
				title: widget?.settings?.label,
				slug: widget.slug
			}
		});
		tableCompound.push(...[{
			title: "Вариант 1",
			slug: "variant_1"
		},{
			title: "Вариант 2",
			slug: "variant_2"
		},{
			title: "Вариант 3",
			slug: "variant_3"
		}]);

		this.setState({
			tableCompound,
			mainScenario: mainScenario,
			batchScenario: batchScenario,

			isInit: true
		})
	}

	watchWs = () => {
		const lastWsMessage = [...this.props.wsMessages].pop();

		let batchScenario = {...this.state.batchScenario};
		let tasks = [...(batchScenario?.tasks || [])];

		if (lastWsMessage?.event !== 'openai:scenario') {
			return
		}

		const taskIndex = tasks.findIndex((t) => t.id === lastWsMessage?.data?.user_scenario_id);
		if (taskIndex <= -1) {
			return;
		}

		const newStatus = lastWsMessage.status;
		if (newStatus === 'success') {
			tasks[taskIndex]['response'] = new Array(tasks[taskIndex]?.number_of_options || 1).fill('success');
		}

		tasks[taskIndex]['status'] = lastWsMessage.status;
		batchScenario.tasks = tasks;
		this.setState({ batchScenario });
	}

	openDetailTask = (task, taskIndex) => {
		this.refDialogInfoTask.current.open({
			task: {...task, taskIndex}
		})
	}

	openEditTask = (task, taskIndex) => {
		this.refDialogEditTask.current.open({
			task: task,
			taskIndex: taskIndex,

			onSubmit: this.submitEditTask.bind(this, task, taskIndex)
		});
	}
	submitEditTask = async (task, taskIndex, values) => {
		this.setState({ isBackdrop: true });
		const { batchId } = this.state;
		const res = await agent.post(`/batch/scenarios/${ batchId }/tasks/${ task.id }/update`, values).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response || true}
		});
		if (res?.error) {
			this.setState({ isBackdrop: false });
			return null
		}
		await this.initScenario();
		this.refDialogEditTask.current.close();
		this.setState({ isBackdrop: false });
		Notification({
			type: notificationTypes.success,
			message: "Задача успешно изменена"
		})
	}

	render() {
		const {
			isInit,
			isBackdrop,
			tableCompound,
			mainScenario,
			batchScenario,
		} = this.state;

		if (!isInit) {
			return null
		}
		return (
			<>
				<Grid container spacing={2} sx={{flex: 1}}>
					<Grid item xs={12} md={5} sx={{flex: 1, display: "flex", flexDirection: "column"}}>
						<ActionContent
							batchTask={batchScenario}
							initScenarioId={mainScenario.id}
						/>
					</Grid>
					<Grid item xs={12} md={7} sx={{display: "flex"}}>
						<ContentEdit
							info={batchScenario}
							scenario={mainScenario}
							tableCompound={tableCompound}

							onOpenEditTask={this.openEditTask}
							onOpenDetailTask={this.openDetailTask}
						/>
					</Grid>
				</Grid>


				<DialogInfoTask
					ref={this.refDialogInfoTask}
					scenario={mainScenario}
				/>
				<DialogEditTask
					ref={this.refDialogEditTask}
					scenario={mainScenario}
				/>


				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

export default TaskBatchView
