import React from "react";
import {
  Box,
  Grid,
  Divider,
  Typography,
  Pagination
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {Link} from "react-router-dom";
import moment from "moment";

const HistoryText = (props) => {
  const {
    data,
    filter,
    total,
    isLoad,
    onChangeFilter
  } = props;
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    let newFilter = {...filter};
    newFilter.page = newPage;
    onChangeFilter(newFilter);
  }

  if (isLoad) {
    return (
      <Box className={classes.loadingContent}>
        <Typography variant="h3" textAlign="left">Загрузка...</Typography>
      </Box>
    )
  }

  return (
    <>

      <Grid container spacing={1}>
        {data.map((item) => (
          <Grid key={`history-card-${item.id}`} item xs={12}>
            <HistoryCard
              data={item}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container justifyContent="flex-end" mt="20px">
        <Grid item>
          <Pagination
            page={filter.page}
            count={total}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const HistoryCard = React.memo((props) => {
  const {
    data
  } = props;
  const classes = useStyles();

  return (
    <Link
      to={`/task/${data.id}`}
      target="_blank"
      className={classes.historyCard}
    >
      <Typography className={classes.historyCardTitle}>История по запросу</Typography>
      <Typography className={classes.historyCardValue}>
        {data?.response?.[0]}
      </Typography>
      <Divider sx={{marginTop: "10px", marginLeft: "-20px", marginRight: "-20px"}}/>
      <Grid container alignItems="flex-end" columnSpacing={{xs: "10px", md: 4}}>
        <Grid item>
          <Typography className={classes.historyCardResponseCount}>
            Дата и время запроса: <span>{moment(data.created_at).format("DD.MM.YYYY HH:mm")}</span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.historyCardResponseCount}>
            Результатов: <span>{(data?.response || []).length} шт.</span>
          </Typography>
        </Grid>
      </Grid>
    </Link>
  )
})

const useStyles = makeStyles(() => ({
  loadingContent: {
    display: "block",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    padding: "20px",
    borderRadius: "16px",
    backgroundColor: "white",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    textDecoration: "initial!important",
    transition: "all 0.3s",
  },

  historyCard: {
    display: "block",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    padding: "20px",
    borderRadius: "16px",
    backgroundColor: "white",
    boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",
    textDecoration: "initial!important",
    transition: "all 0.3s",
    position: "relative",
    overflow: "hidden",

    "&:hover": {
      boxShadow: "0px 1px 2.5px 1px rgba(0, 0, 0, 0.20)",
    },
    "@media(max-width: 859px)": {
      padding: 16
    }
  },
  historyCardTitle: {
    fontSize: 18,
    lineHeight: "24px",
    color: "#126DDE",
    letterSpacing: "-0.28px",

    "@media(max-width: 859px)": {
      fontSize: 16,
      lineHeight: "20px",
    }
  },
  historyCardValue: {
    marginTop: 10,

    fontSize: 16,
    lineHeight: "20px",
    color: "#2C4058",
    letterSpacing: "-0.48px",

    margin: 0,
    "-webkit-line-clamp": 8,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",

    "@media(max-width: 859px)": {
      fontSize: 14,
      lineHeight: "18px",
    }
  },
  historyCardResponseCount: {
    marginTop: "10px",

    fontSize: 16,
    lineHeight: "22px",
    color: "#2C4058",
    letterSpacing: "-0.28px",

    "& span": {
      color: "#126DDE"
    },
    "@media(max-width: 859px)": {
      fontSize: 14,
      lineHeight: "16px",
    }
  },
}));

export default React.memo(HistoryText)
