// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import TaskBatch from './TaskBatch';

export default compose(
  connect(
    state => ({
      scenarios: state.directory.scenarios,
    }),
    dispatch => ({}),
  ),
)(TaskBatch);
