import agent from "../agent/agent";

const copyPicture = async (imageUrl) => {
  try {
    const file = await agent.get(imageUrl, {
      responseType: "blob"
    }).then((res) => {
      return res
    }).catch(() => {
      return null
    });
    if (!file) {
      return false
    }
    const blob = file.data;

    // const response = await fetch(imageUrl);
    // let blob = await response.blob();
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob
      })
    ]);
  } catch (err) {
    console.error(err.name, err.message);
  }
};
const downloadPicture = async (imageUrl, ) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'todo-1.json';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
const downloadPictureMidjourney = async (file) => {
  const res = await agent.get(file.url, {
    responseType: "blob"
  }).then((res) => {
    return res
  }).catch(() => {
    return null
  });
  if (!res) {
    return false
  }
  const blob = res.data;

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = file.filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

const copyText = async (text) => {
  return await navigator.clipboard.writeText(text).then(function() {
    return true
  }, function(err) {
    return false
  });
}
const pastText = async () => {
  return await navigator.clipboard.readText()
}

export {
  copyText,
  pastText,

  copyPicture,
  downloadPicture,
  downloadPictureMidjourney
}
