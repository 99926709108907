const textParse = (str) => {
  str = (str || "").replace(/\n/g, '<br/>');
  return str
}
const textParseBack = (str) => {
  str = (str || "").replace(/<br\/>/g, '\n');
  return str
}

export {
  textParse,
  textParseBack
}
